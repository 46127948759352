import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(private apiRequestService:ApiRequestService) { }


  // get all coupons list
  getAllCoupon(cartId) {
    return this.apiRequestService.get('getAllCoupon', '?cartId=' + cartId);
  }

  // apply coupon
  applyCoupon(reqBody) {
    return this.apiRequestService.post('applyCoupon', reqBody);
  }
}
