export class MenuItem {
  private outletId: string;
  private itemId: string;
  private name: string;
  private quantity: number;
  private isVeg: boolean;
  private gst: string;
  private price: number;
  private totalAmount: number;
  private addons: Addons[];
  private offerPrice: number;
  private containsEgg: boolean;

  constructor() {
    this.outletId = "";
    this.itemId = "";
    this.name = "";
    this.quantity = 0;
    this.isVeg = false;
    this.gst = "";
    this.price = 0;
    this.totalAmount = 0;
    this.addons = [new Addons()];
    this.offerPrice = 0;
    this.containsEgg = false;
  }

  public get _outletId(): string {
    return this.outletId;
  }

  public set _outletId(outletId:string){
    this.outletId = outletId;
  }
  public get _itemId(): string {
    return this.itemId;
  }

  public set _itemId(itemId: string) {
    this.itemId = itemId;
  }

  public get _name(): string {
    return this.name;
  }

  public set _name(name: string) {
    this.name = name;
  }

  public get _quantity(): number {
    return this.quantity;
  }

  public set _quantity(quantity: number) {
    this.quantity = quantity;
  }

  public get _isVeg(): boolean {
    return this.isVeg;
  }

  public set _isVeg(isVeg: boolean) {
    this.isVeg = isVeg;
  }

  public get _gst(): string {
    return this.gst;
  }

  public set _gst(gst: string) {
    this.gst = gst;
  }

  public get _price(): number {
    return this.price;
  }

  public set _price(price: number) {
    this.price = price;
  }

  public get _totalAmount(): number {
    return this.totalAmount;
  }

  public set _totalAmount(totalAmount: number) {
    this.totalAmount = totalAmount;
  }

  public get _addons(): Addons[] {
    return this.addons;
  }

  public set _addons(addons: Addons[]) {
    this.addons = addons;
  }
  public get _offerPrice(): number {
    return this.offerPrice;
  }

  public set _offerPrice(offerPrice: number) {
    this.offerPrice = offerPrice;
  }

  public get _containsEgg(): boolean {
    return this.containsEgg;
  }

  public set _containsEgg(containsEgg: boolean) {
    this.containsEgg = containsEgg;
  }
}

export class Addons {
  private addonId: string;
  private addonName: string;
  private isVariant: boolean;
  private options: Options[];
  private maxSelection: number;

  private minSelection: number;

  constructor() {
    this.addonId = "";
    this.addonName = "";
    this.isVariant = false;
    this.options = [new Options()];
    this.minSelection = 0;
    this.maxSelection = 0;
  }

  public get _addonId(): string {
    return this.addonId;
  }

  public set _addonId(addonId: string) {
    this.addonId = addonId;
  }

  public get _addonName(): string {
    return this.addonName;
  }

  public set _addonName(addonName: string) {
    this.addonName = addonName;
  }

  public get _isVariant(): boolean {
    return this.isVariant;
  }

  public set _isVariant(isVariant: boolean) {
    this.isVariant = isVariant;
  }

  public get _options(): Options[] {
    return this.options;
  }

  public set _options(options: Options[]) {
    this.options = options;
  }
  public get _maxSelection(): number {
    return this.maxSelection;
  }

  public set _maxSelection(value: number) {
    this.maxSelection = value;
  }

  public get _minSelection(): number {
    return this.minSelection;
  }

  public set _minSelection(value: number) {
    this.minSelection = value;
  }
}

export class Variant {
  private addonId: string;
  private addonName: string;
  private isVariant: boolean;
  private options: Options;

  constructor() {
    this.addonId = "";
    this.addonName = "";
    this.isVariant = false;
    this.options = new Options();
  }

  public get _addonId(): string {
    return this.addonId;
  }

  public set _addonId(addonId: string) {
    this.addonId = addonId;
  }

  public get _addonName(): string {
    return this.addonName;
  }

  public set _addonName(addonName: string) {
    this.addonName = addonName;
  }

  public get _isVariant(): boolean {
    return this.isVariant;
  }

  public set _isVariant(isVariant: boolean) {
    this.isVariant = isVariant;
  }

  public get _options(): Options {
    return this.options;
  }

  public set _options(value: Options) {
    this.options = value;
  }
}
export class Options {
  private optionName: string;
  private cost: number;
  private isVeg: boolean;
  private offerPrice: number;

  constructor() {
    this.optionName = "";
    this.cost = 0;
    this.isVeg = false;
    this.offerPrice = undefined;
  }

  public get _optionName(): string {
    return this.optionName;
  }

  public set _optionName(optionName: string) {
    this.optionName = optionName;
  }

  public get _cost(): number {
    return this.cost;
  }

  public set _cost(cost: number) {
    this.cost = cost;
  }

  public get _isVeg(): boolean {
    return this.isVeg;
  }

  public set _isVeg(isVeg: boolean) {
    this.isVeg = isVeg;
  }

  public get _offerPrice(): number {
    return this.offerPrice;
  }

  public set _offerPrice(value: number) {
    this.offerPrice = value;
  }
}
