import { ErrorMessage } from "src/app/core/constants/validation-msg-constants";
import {
  Component,
  OnInit,
  Input,
  HostListener,
  Renderer2,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Addons, Variant, MenuItem } from "../../../shared/models/menu-item";
import { ToastService } from "src/app/shared/services/toast.service";
import { MenuItemService } from "../../restaurant-details/service/menu-item.service";
import { AppData } from "src/app/shared";
import { getVegNonVegItemImg } from "src/app/shared/utils/menu-cart-item-functions";
// import { CartCalService } from "../../cart/service/cart-cal.service";
// import { MenuAddService } from '../../restaurant-details/service/menu-add.service';

@Component({
  selector: "app-addon-variant",
  templateUrl: "./addon-variant.component.html",
  styleUrls: ["./addon-variant.component.scss"],
})
export class AddonVariantComponent implements OnInit {
  variantForm: FormGroup;
  addonList = [];
  variantList = [];
  selectedAddons = [];
  menuItemModel = new MenuItem();
  addonModel = new Addons();
  variantModel = new Variant();
  selectedVariant = {};
  menuItems: any;
  offerPrice = 0;
  totalCost = 0;
  totalVariantCost = 0;
  showQty = 1;

  @Input() public modalObj;
  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
    private toasterService: ToastService,
    private activeModal: NgbActiveModal,
    private menuItemService: MenuItemService // private menuAddService:MenuAddService, // private cartCalService: CartCalService
  ) {
    window.scroll(0, 0);
  }

  ngOnInit() {
    console.log(this.modalObj);
    if (this.modalObj) {
      this.menuItems = this.modalObj.item;
      console.log(this.modalObj);
      console.log("cart", this.modalObj.cart);

      this.addonList = this.modalObj.addons.filter(
        (item) => item.isVariant === false
      );
      this.variantList = this.modalObj.addons.filter(
        (item) => item.isVariant === true
      );
      console.log("------->Variant List", this.variantList);
      console.log("------->Addons List", this.addonList);

      if (this.modalObj.cart) {
        console.log("called to set addons");
        this.selectedAddons = this.modalObj.item.addons;
      }
    }
    this.initForm();
  }
  initForm() {
    let selectOption = "";
    const selectedVariant = this.modalObj.item.variant;
    console.log(selectedVariant);
    if (selectedVariant && selectedVariant.options) {
      selectOption = selectedVariant.options["optionName"];
    } else if (this.variantList.length > 0) {
      selectOption = this.variantList[0].options[0].optionName;
    }
    this.variantForm = this.fb.group({
      options: new FormControl(selectOption, [Validators.required]),
    });
    console.log(this.variantList);
    this.getVariantCost();
  }

  getVegNonVegImg(isVeg, containsEgg) {
    return getVegNonVegItemImg(isVeg, containsEgg);
  }

  // checkbox options
  optionSet(option, addonId) {
    let select = false;
    const selectAddons = this.modalObj.item.addons;
    if (selectAddons.length > 0) {
      const findAddon = selectAddons.find((item) => item.addonId === addonId);
      if (findAddon) {
        const matchOption = findAddon.options.find(
          (op) => op.optionName === option.optionName
        );
        if (matchOption) {
          select = true;
        }
      }
    }
    return select;
  }
  // generateAddonsString(minSelection, maxSelection) {
  //   let displayString = "";
  //   if (minSelection === 0) {
  //     switch (maxSelection) {
  //       case 1:
  //         displayString = "Select any one option";
  //         break;
  //       default:
  //         displayString = `Select upto ${maxSelection} options`;
  //     }
  //   } else if (minSelection === 1 && maxSelection === 1) {
  //     displayString = 'Select any one option';
  //   } else {
  //     displayString = `Select ${minSelection} - ${maxSelection} options`;
  //   }
  //   return displayString;
  // }

  generateAddonsString(minSelection, maxSelection) {
    let displayString = "";
    if (minSelection === 0) {
      switch (maxSelection) {
        case 1:
          displayString = "Select one add-on:";
          break;
        default:
          displayString = `Select up to ${maxSelection} add-ons:`;
      }
    } else if (minSelection === 1 && maxSelection === 1) {
      displayString = "Select any one option:";
    } else {
      displayString = `Select ${minSelection} to ${maxSelection} add-on(s)`;
    }
    return displayString;
  }

  // checkbox addons data
  onAddonChange(event, option, addons) {
    console.log("called to add addons");
    if (event.target.checked === true) {
      this.pushAddonObj(addons, option);
      if (this.selectedAddons.length > 0) {
        const addonsObj = this.selectedAddons.find(
          (item) => item.addonId === addons.addonId
        );
        if (addonsObj) {
          addonsObj.options.push(option);
        } else {
          this.selectedAddons.push(this.addonModel);
        }
      } else {
        // selectedAddons array is empty then pushing the addon object
        this.selectedAddons.push(this.addonModel);
      }
    } else {
      this.selectedAddons.forEach((addon, index) => {
        if (addon.addonId === addons.addonId) {
          let ind = addon.options.findIndex(
            (ind) => ind.optionName === option.optionName
          );
          addon.options.splice(ind, 1);
          if (addon.options.length === 0) {
            this.selectedAddons.splice(index, 1);
          }
        }
      });
    }
    console.log(this.selectedAddons);
    this.getAddonsCost();
  }
  onChangeVariant(event) {
    this.getVariantCost();
  }
  getVariantCost() {
    const optionObj = this.variantList[0]["options"].find(
      (item) => item.optionName === this.variantForm.controls["options"].value
    );
    this.totalVariantCost = optionObj.cost;
  }
  getAddonsCost() {
    let addOnsCost = 0;
    this.selectedAddons.forEach((ele) => {
      ele.options.map((a) => {
        return (addOnsCost += a.cost);
      });
    });
    this.totalCost = addOnsCost;
  }
  pushAddonObj(addon, option) {
    this.addonModel = new Addons();
    this.addonModel["addonId"] = addon.addonId;
    this.addonModel["addonName"] = addon.addonName;
    this.addonModel["isVariant"] = addon.isVariant;
    this.addonModel["maxSelection"] = addon.maxSelection;
    this.addonModel["minSelection"] = addon.minSelection;
    this.addonModel["options"].forEach((obj) => {
      obj["optionName"] = option.optionName;
      obj["isVeg"] = option.isVeg;
      obj["cost"] = option.cost;
      obj["offerPrice"] = option.offerPrice;
    });
  }

  pushVariantObj(variant, option) {
    this.variantModel["addonId"] = variant.addonId;
    this.variantModel["addonName"] = variant.addonName;
    this.variantModel["isVariant"] = variant.isVariant;
    this.variantModel["options"] = { ...option };
  }
  // for close model
  onCloseAddonModal() {
    this.activeModal.dismiss();
  }

  // for save data in cart
  onSaveAddon() {
    if (this.variantForm.controls["options"].value !== "") {
      const optionObj = this.variantList[0]["options"].find(
        (item) => item.optionName === this.variantForm.controls["options"].value
      );
      this.pushVariantObj(this.variantList[0], optionObj);
      if (optionObj) {
        this.selectedVariant = { ...this.variantModel };
      }
    }
    console.log(this.selectedVariant, this.selectedAddons, this.menuItems);
    this.validationAddonVariantList();
    console.log(this.selectedAddons, this.selectedVariant);
  }

  // validating the addons and variants and on that error msgs
  validationAddonVariantList() {
    if (this.selectedAddons.length > 0 || this.selectedVariant["isVariant"]) {
      if (this.variantList.length > 0) {
        if (this.selectedVariant["isVariant"]) {
          if (this.addonList.length > 0) {
            const addonsArrayList = JSON.parse(
              JSON.stringify(this.selectedAddons)
            );
            console.log("Selected Addons", addonsArrayList);
            this.addonsValidation(addonsArrayList);
          } else {
            this.customMenuItemAdd();
          }
        } else if (this.variantList[0]["minSelection"] > 0) {
          const message = ErrorMessage.VARIANT_SELECTION_REQ_MEG;
          this.toasterService.presentToast(message, "", "error");
        }
      } else {
        if (this.addonList.length > 0) {
          console.log(JSON.parse(JSON.stringify(this.selectedAddons)));
          const addonsArrayList = JSON.parse(
            JSON.stringify(this.selectedAddons)
          );
          console.log("Selected Addons", addonsArrayList);
          if (this.selectedAddons.some((addon) => addon.isVariant === false)) {
            this.addonsValidation(addonsArrayList);
          }
        }
      }
    } else {
      if (this.variantList.length > 0) {
        if (this.variantList[0]["minSelection"] > 0) {
          const message = ErrorMessage.VARIANT_SELECTION_REQ_MEG;
          this.toasterService.presentToast(message, "", "error");
        }
      } else if (this.addonList.length > 0) {
        if (this.addonList.some((addon) => Number(addon.minSelection) >= 1)) {
          let message;
          if (this.addonList.length === 1) {
            message = ErrorMessage.ADDON_SELECTION_REQ_MEG;
          } else {
            message = ErrorMessage.ADDONS_SELECTION_REQ_MEG;
          }
          this.toasterService.presentToast(message, "", "error");
        } else {
          this.customMenuItemAdd();
        }
      } else {
        this.customMenuItemAdd();
      }
    }
  }

  // addons validation
  addonsValidation(addonsArrayList) {
    let validate = [];
    for (let i = 0; i < this.addonList.length; i++) {
      const addons = addonsArrayList.some(
        (item) => item.addonName === this.addonList[i].addonName
      );
      let tostKey;
      if (addons) {
        for (let j = 0; j < addonsArrayList.length; j++) {
          if (this.addonList[i].addonName === addonsArrayList[j].addonName) {
            if (
              addonsArrayList[j].minSelection <=
                addonsArrayList[j].options.length &&
              addonsArrayList[j].maxSelection >=
                addonsArrayList[j].options.length
            ) {
              validate.push(true);
            } else {
              validate.push(false);
              let message =
                this.addonList[i].displayName +
                " min selection " +
                this.addonList[i].minSelection +
                " & max selection is " +
                this.addonList[i].maxSelection;
              this.toasterService.presentToast(message, "", "error");
              tostKey = true;
            }
          }
        }
      } else {
        if (this.addonList[i].minSelection > 0) {
          validate.push(false);
          const message =
            this.addonList[i].displayName +
            " min selection " +
            this.addonList[i].minSelection +
            " & max selection is " +
            this.addonList[i].maxSelection;
          this.toasterService.presentToast(message, "", "error");
          tostKey = true;
        }
      }
      if (tostKey === true) break;
    }
    if (!validate.includes(false)) {
      console.log("check me");
      // customized menu item or outlet adding in order object
      this.customMenuItemAdd();
    }
  }

  @HostListener("window:scroll", ["$event"])
  scrollEvent(event, isVirtualScroll?) {
    const itemsDescriptionSec = document.querySelector(
      ".items-description-section"
    );
    const itemsDescription = document.querySelector(".items-description");
    const stickydiv = document.querySelector(".item-qty-section");
    this.renderer.setStyle(
      stickydiv,
      "top",
      itemsDescription.clientHeight + 20 + "px"
    );
    const scrollPosition = event.srcElement.scrollTop;
    if (scrollPosition > 20) {
      this.renderer.setStyle(itemsDescription, "opacity", "0");
    } else {
      this.renderer.setStyle(itemsDescription, "opacity", "1");
    }
  }

  pushMenuItem(menu, addons) {
    this.menuItemModel = new MenuItem();
    this.menuItemModel._outletId = menu.outletId;
    this.menuItemModel._itemId = menu.itemId;
    this.menuItemModel._name = menu.name;
    this.menuItemModel._quantity = menu.quantity;
    this.menuItemModel._isVeg = menu.isVeg;
    this.menuItemModel._gst = menu.gst;
    this.menuItemModel._price = menu.price;
    this.menuItemModel._offerPrice = menu.offerPrice;
    this.menuItemModel._containsEgg = menu.containsEgg;
    this.menuItemModel._totalAmount = 0; //
    this.menuItemModel._addons = addons;
    this.menuItemModel["variant"] = this.selectedVariant;
    this.menuItemModel["additionalCharges"] = menu["additionalCharges"];
    return this.menuItemModel;
  }

  customMenuItemAdd() {
    this.menuItemModel = this.pushMenuItem(this.menuItems, this.selectedAddons);
    const updateMenu = JSON.parse(JSON.stringify(this.menuItemModel));
    console.log(updateMenu);
    if (!this.modalObj.cart) {
      const customAddons = AppData.selectedAddonsList;
      if (customAddons.length > 0) {
        this.modalObj.addons.forEach((addon) => {
          const findAddon = customAddons.find(
            (itm) => itm.addonId === addon.addonId
          );
          if (!findAddon) {
            customAddons.push(addon);
          }
        });
      } else {
        customAddons.push(...this.modalObj.addons);
      }
      AppData.selectedAddonsListSub$.next(customAddons);
    }
    updateMenu["qnty"] = this.showQty;
    this.activeModal.close(JSON.parse(JSON.stringify(updateMenu)));
  }

  // getOfferPrice(offerList, price) {
  //   if (offerList) {
  //     this.offerPrice = this.menuItemService.getOfferPrice(offerList, price);
  //   } else if (this.menuItems.offer) {
  //     this.offerPrice = this.cartCalService.calculateDiscount(
  //       this.menuItems.offer,
  //       price
  //     );
  //   }

  //   return this.offerPrice;
  // }

  setDiscountVal(option) {
    if (option.cost && option.cost < option.originalCost) {
      return true;
    }
    return false;
  }

  setCost(option) {
    if (option.cost !== null) {
      console.log(option);
      return option.cost;
    } else {
      return option.originalCost;
    }
  }

  onItemDecrease() {
    if (this.showQty <= 1) {
      this.activeModal.close();
    } else {
      this.showQty -= 1;
    }
  }

  onItemIncrease() {
    this.showQty += 1;
  }
}
