import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../modals/login-signup-modal/service/login.service';
import { LoginComponent } from '../login.component';
import { Location } from '@angular/common';
import { CacheService } from 'src/app/shared/services/cache.service';

@Component({
  selector: 'app-login-desktop',
  templateUrl: './login-desktop.component.html',
  styleUrls: ['./login-desktop.component.scss']
})
export class LoginDesktopComponent extends LoginComponent {

  constructor(
    fb: FormBuilder,
    loginService: LoginService,
    router: Router,
    location: Location,
    activeModal: NgbActiveModal,
    modalService: NgbModal,
    activatedRoute: ActivatedRoute,
    cacheService: CacheService
  ) {
    super(fb,
      loginService,
      router,
      location,
      activeModal,
      modalService,
      activatedRoute,
      cacheService)
  }

}
