import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/core/services/storage.service';
import { ApiRequestService } from './api-request.service';
import { AppData } from './app-data.service';
import { LocationService } from './location.service';

@Injectable({
  providedIn: 'root'
})
export class TempTokenService {

  constructor(
    private apiRequestService: ApiRequestService,
    private locationService: LocationService,
    private storageService: StorageService
  ) { }

  getTempToken() {
    console.log('--------->Temp Token', AppData.token);

    const reqBody = {
      deviceId: AppData.deviceId
    }
    this.apiRequestService.post('exploreFirst', reqBody).then(async (res) => {
      const responseData = res['body']['data'];
      console.log(responseData);
      AppData.token$.next(responseData.token);
      AppData.cartIdSub$.next(responseData.user.cartId);
      AppData.userInfoSub$.next(responseData.user);
      AppData.userIdSub$.next(responseData.user.userId);
      this.locationService.setAndUpdateLocation();
      AppData.currentAddressSub$.next(await this.createLocationObj());
    });
  }

  resetAppData() {
    console.log('reset app date');
    AppData.isAuthenticated = false;
    AppData.bottomNavMobileIndSub$.next(0);
    AppData.yourPlateItemsSub$.next(null);
    AppData.plateItemCountSub$.next({ itemCount: 0, itemTotal: 0, outletName: '' });
    AppData.spInstructionsSub$.next('');
    const deviceIdStor = this.storageService.getItem('deviceId');
    this.storageService.clearStorage();
    AppData.deviceIdSub$.next(deviceIdStor);
    AppData.currentAddressSub$.next(AppData.currentAddressObj);
    AppData.selectedAddonsListSub$.next(AppData.selectedAddonsList);
    AppData.itemsInPlateSub$.next(AppData.itemsInPlate);
  }

  createLocationObj() {
    const body = {
      'addressTag': AppData.currentAddressObj.locality,
      'fullAddress': AppData.currentAddressObj.fullAddress,
      'city': AppData.currentAddressObj.city,
      'country': AppData.currentAddressObj.country,
      'state': AppData.currentAddressObj.state,
      'pincode': AppData.currentAddressObj.pincode,
      'locality': AppData.currentAddressObj.locality,
      'longLat': AppData.currentAddressObj.longLat
    }
    return body;
  }
}
