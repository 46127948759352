import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-coupon-confirmation-modal',
  templateUrl: './coupon-confirmation-modal.component.html',
  styleUrls: ['./coupon-confirmation-modal.component.scss']
})
export class CouponConfirmationModalComponent implements OnInit {

  @Input() modalObj: any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    console.log(this.modalObj);
    setTimeout(() => {
      this.activeModal.close();
    }, 2000);
  }

}
