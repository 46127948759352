import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-addon-model-repeat',
  templateUrl: './addon-model-repeat.component.html',
  styleUrls: ['./addon-model-repeat.component.scss']
})
export class AddonModelRepeatComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) { }
  ngOnInit() { }
}
