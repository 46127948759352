export const environment = {
    production: false,
    mapApiKey: 'AIzaSyAu7d9alua551hRqOmLLFd3sz4Kk2bORak',
    // baseUrl: 'http://68.183.89.81:3000',
    // baseUrl: 'http://139.59.92.1:1337',
    baseUrl: 'https://api.dev.yumzy.ai/suggestionv2',
    analyticsUrl: 'https://dev.analytics.yumzy.in',
    googleMapUrl: 'https://maps.googleapis.com',
    termsAndConditionsUrl: 'https://listing.sgp1.digitaloceanspaces.com/terms/terms/terms_yumzy.html',
    razorPayKey: 'rzp_test_L9afSYwM9TfHhI',
    // postBackUrl:'http://localhost:4200/restaurants/cart',
    postBackUrl: 'https://dev.web.yumzy.ai/restaurants/cart',
    firebaseConfig: {
        apiKey: "AIzaSyCB_x7FW7uc9OFUaLa2w9R_dsANNXNcK-o",
        authDomain: "yumzy-dev.firebaseapp.com",
        databaseURL: "https://yumzy-dev.firebaseio.com",
        projectId: "yumzy-dev",
        storageBucket: "yumzy-dev.appspot.com",
        messagingSenderId: "483512165996",
        appId: "1:483512165996:web:27e24e93396d44356dda49",
        measurementId: "G-6JB3XW7WS4"
    }
}


