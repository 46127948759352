import { MoneyTransferMobileModule } from './../../modules/account/component/money-transfer/money-transfer-mobile/money-transfer-mobile.module';
import { NoInternetModule } from './../../modules/no-internet/no-internet.module';
import { CanActivate } from '@angular/router';
import { NgbModalDismissAllGuard } from 'src/app/shared/guards/ngb-modal-dismiss-all.guard';
import { AuthGuard } from 'src/app/shared';

export const MOBILE_ROUTES = [
    { path: '', redirectTo: 'welcome', pathMatch: 'full' },
    {
        path: 'welcome',
        loadChildren: () => import('../../modules/welcome/welcome.module').then(m => m.WelcomeModule)
    },
    {
        path: 'no-internet',
        loadChildren: () => import('../../modules/no-internet/no-internet.module').then(m => m.NoInternetModule)
    },
    {
        path: 'login',
        loadChildren: () => import('../../modules/login/login-mobile/login-mobile.module').then(m => m.LoginMobileModule)
    },
    {
        path: 'otp',
        loadChildren: () => import('../../modules/otp/otp-mobile/otp-mobile.module').then(m => m.OtpMobileModule)
    },
    {
        path: 'sign-up',
        loadChildren: () => import('../../modules/sign-up/sign-up-mobile/sign-up-mobile.module').then(m => m.SignUpMobileModule)
    },
    {
        path: 'select-location',
        // canActivate: [AuthGuard],
        loadChildren: () => import('../../modules/select-location/select-location.module')
            .then(m => m.SelectLocationModule)
    },
    {
        path: 'restaurants',
        loadChildren: () => import('../../modules/main-routing/main-mobile/main-mobile.module')
            .then(m => m.MainMobileModule),
    },
    {
        path: 'see-all-dish',
        canActivate: [NgbModalDismissAllGuard],
        loadChildren: () => import('../../modules/see-all-dish/see-all-dish-mobile/see-all-dish-mobile.module')
            .then(m => m.SeeAllDishMobileModule),
    },
    {
        path: 'see-all-restaurants',
        canActivate: [NgbModalDismissAllGuard],
        loadChildren: () => import('../../modules/see-all-restaurants/see-all-restaurants-mobile/see-all-restaurants-mobile.module')
            .then(m => m.SeeAllRestaurantsMobileModule),
    },
    {
        path: 'food-preferences',
        loadChildren: () => import('../../modules/account/component/food-preferences/mobile/food-preferences-mobile.module')
            .then(m => m.FoodPreferencesMobileModule),
    },
    {
        path: 'favorites',
        loadChildren: () => import('../../modules/account/component/favorites/mobile/favorites-mobile.module')
            .then(m => m.FavoritesMobileModule),
    },
    {
        path: 'manage-address',
        canActivate: [NgbModalDismissAllGuard],
        loadChildren: () => import('../../modules/account/component/manage-address/mobile/manage-address-mobile.module')
            .then(m => m.ManageAddressMobileModule),
    },
    {
        path: 'my-wallet',
        loadChildren: () => import('../../modules/account/component/my-wallet/mobile/my-wallet-mobile.module')
            .then(m => m.MyWalletMobileModule),
    },
    {
        path: 'money-transfer',
        loadChildren: () => import('../../modules/account/component/money-transfer/money-transfer-mobile/money-transfer-mobile.module')
            .then(m => m.MoneyTransferMobileModule),
    },
    {
        path: 'my-rewards',
        loadChildren: () => import('../../modules/account/component/my-rewards/mobile/my-rewards-mobile.module')
            .then(m => m.MyRewardsMobileModule),
    },
    {
        path: 'scratch-info',
        loadChildren: () => import('../../shared/components/scratch-card-mobile-info/scratch-card-mobile-info.module')
            .then(m => m.ScratchCardMobileInfoModule),
    },
    {
        path: 'order-history',
        canActivate: [NgbModalDismissAllGuard],
        loadChildren: () => import('../../modules/account/component/order-history/mobile/order-history-mobile.module')
            .then(m => m.OrderHistoryMobileModule),
    },
    {
        path: 'rest-details/:outletName',
        canActivate: [NgbModalDismissAllGuard],
        loadChildren: () => import('../../modules/restaurant-details/restaurant-details-mobile/restaurant-details-mobile.module')
            .then(m => m.RestaurantDetailsMobileModule),
    },
    {
        path: 'help-faqs',
        loadChildren: () => import('../../modules/account/component/help/help-mobile/help-mobile.module')
            .then(m => m.HelpMobileModule),
    },
    {
        path: 'profile-details',
        loadChildren: () => import('../../modules/account/component/profile-details/mobile/profile-details-mobile.module')
            .then(m => m.ProfileDetailsMobileModule),
    },
    {
        path: 'rewards-coupons',
        loadChildren: () => import('../../modules/account/component/my-rewards/components/rewards-coupons/mobile/rewards-coupons-mobile.module')
            .then(m => m.RewardsCouponsMobileModule),
    },
    {
        path: 'rewards-earned',
        loadChildren: () => import('../../modules/account/component/my-rewards/components/rewards-earned/mobile/rewards-earned-mobile.module')
            .then(m => m.RewardsEarnedMobileModule),
    },
    {
        path: 'offers',
        loadChildren: () => import('../../modules/offers/offers-mobile/offers-mobile.module')
            .then(m => m.OffersMobileModule),
    },
    {
        path: 'earned-scratch-cards',
        loadChildren: () => import('../../modules/account/component/earned-scratch-cards/mobile/earned-scratch-cards-mobile/earned-scratch-cards-mobile.module')
            .then(m => m.EarnedScratchCardsMobileModule),
    },
    {
        path: 'referral',
        loadChildren: () => import('../../modules/referrals/referrals.module').then(m => m.ReferralsModule),
    }
]