import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { CHARGES } from 'src/app/core/constants/app-constants';

@Component({
  selector: 'app-tax-and-charges-modal',
  templateUrl: './tax-and-charges-modal.component.html',
  styleUrls: ['./tax-and-charges-modal.component.scss']
})
export class TaxAndChargesModalComponent implements OnInit {
  @Input() modalObj;
  chargesList = [];
  gst = 0;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    console.log(this.modalObj);
    this.gst = this.modalObj['billing']['taxes'][0]['value'];
    this.chargesList = this.modalObj['billing']['charges'];
  }

  // for get charges label name
  getChargesName(charge) {
    return CHARGES[charge];
  }

  isTaxCharges(changes) {
    if (Number(changes.value) === 0 && changes.name === 'packingCharges') {
      return false;
    } else if (changes.name === 'deliveryCharge') {
      return true;
    } else if (Number(changes.value) === 0 && changes.name === 'restaurantCharges') {
      return false;
    }
    return true;
  }

}
