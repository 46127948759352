export const environment = {
  production: true,
  mapApiKey: 'AIzaSyAYgPwBdqSnnsxfjEI7F763kMh6KEyw9EM',
  baseUrl: 'https://api.yumzy.ai/suggestionv2',
  analyticsUrl: '',
  googleMapUrl: 'https://maps.googleapis.com',
  termsAndConditionsUrl: 'https://listing.sgp1.digitaloceanspaces.com/terms/terms/terms_yumzy.html',
  razorPayKey: 'rzp_live_yK1mfdeCZXuLDy',
  postBackUrl: 'https://order.yumzy.in/restaurants/cart',
  firebaseConfig: {
    apiKey: "AIzaSyAax3HIru-vx5TIfnNmtV3cQG1MTWK51Xc",
    authDomain: "yumzy-de919.firebaseapp.com",
    databaseURL: "https://yumzy-de919.firebaseio.com",
    projectId: "yumzy-de919",
    storageBucket: "yumzy-de919.appspot.com",
    messagingSenderId: "602389669138",
    appId: "1:602389669138:web:af9e13badc75b1e88a3fcb",
    measurementId: "G-HYV6Y6ZVYS"
  }
};
