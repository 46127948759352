import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { TooltipOptions } from "ng2-tooltip-directive";
import { DotsData } from "ngx-owl-carousel-o/lib/models/navigation-data.models";
import { ApplicationStateService } from "src/app/shared/services/application-state.service";

export const EMAIL_PATTERN =
  /^(([^<>!#$%&\^\*()\[\]\\.,;:\s@'"?`~]+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ONLY_CHAR = "^[a-zA-Z ]+$";
export const CHAR_WITH_NUMBER = "^[a-zA-Z0-9_ ]*$";
export const COUNTER = 2;
export const SECONDS = 59;
export const COUNTRY_CODE = "+91";
export const RAZOR_PAY_URL = "https://checkout.razorpay.com/v1/checkout.js";
export const RAZOR_PAY_KEY = "rzp_test_L9afSYwM9TfHhI"; // Testing
// export const RAZOR_PAY_KEY = 'rzp_live_yK1mfdeCZXuLDy'; // Production
export const ROUTES_STR = {
  home: "restaurants/all",
  account: "/restaurants/account",
  explore: "/restaurants/explore",
  cart: "/restaurants/cart",
};
export const THEME_COLOR = {
  categorySelected: "#fe0076",
  categoryNonSelected: "#71767a",
};
export const OfferColors = [
  {
    backgroundColor:"#F672801A",
    border:"#BC3357"
  },
  {
    backgroundColor:"#9072F61A",
    border:"#4833BC"
  },
  {
    backgroundColor:"#72F6AA1A",
    border:"#43BC33"
  }

]
export const ACCOUNT_ITEMS = [
  {
    name: "Manage Addresses",
    icon: "assets/icon/account/manage-address.svg",
    type: "MANAGE_ACCOUNT",
    url: "manage-address",
    showTitle: true,
    showInDesktop: true,
  },
  // {
  //   name: "Food Preferences",
  //   icon: "assets/icon/account/food-preferences.svg",
  //   type: "FOOD_PREFERENCES",
  //   url: "food-preferences",
  //   showTitle: true,
  //   showInDesktop: false,
  // },
  {
    name: "My Wallet",
    icon: "assets/icon/account/my-wallet.svg",
    type: "MY_WALLET",
    url: "my-wallet",
    showTitle: false,
    showInDesktop: true,
  },
  {
    name: "Favourites",
    icon: "assets/icon/account/favorite.svg",
    type: "FAVORITES",
    url: "favorites",
    showTitle: true,
    showInDesktop: false,
  },
  {
    name: "My Rewards",
    icon: "assets/icon/account/my-rewards.svg",
    type: "MY_REWARDS",
    url: "my-rewards",
    showTitle: true,
    showInDesktop: true,
  },
  {
    name: "Order History",
    icon: "assets/icon/account/orders-history.svg",
    type: "ORDERS_HISTORY",
    url: "order-history",
    showTitle: true,
    showInDesktop: true,
  },
  {
    name: "Help & FAQs",
    icon: "assets/icon/account/help-faqs.svg",
    type: "HELP_FAQS",
    url: "help-faqs",
    showTitle: true,
    showInDesktop: false,
  },
];

export const CONVERSATION_THREADS = [
  {
    question: "I have payment, refund and bill related queries for this order",
    orderNo: "1426356524",
    orderDate: "2020-04-20T09:05:24.495Z",
    threadClosedDate: "2020-05-20T09:05:24.495Z",
  },
  {
    question: "I have payment, refund and bill related queries for this order",
    orderNo: "1426356524",
    orderDate: "2020-04-20T09:05:24.495Z",
    threadClosedDate: "2020-05-20T09:05:24.495Z",
  },
  {
    question: "I have payment, refund and bill related queries for this order",
    orderNo: "1426356524",
    orderDate: "2020-04-20T09:05:24.495Z",
  },
  {
    question: "I have payment, refund and bill related queries for this order",
    orderNo: "1426356524",
    orderDate: "2020-04-20T09:05:24.495Z",
    threadClosedDate: "2020-05-20T09:05:24.495Z",
  },
];

export const NAV_ITEM = [
  {
    name: "Home",
    greyIcon: "assets/icon/nav-menu/home-grey.svg",
    redIcon: "assets/icon/nav-menu/home-red.svg",
    url: ROUTES_STR.home,
    type: "HOME",
  },
  {
    name: "Explore",
    greyIcon: "assets/icon/nav-menu/search-grey.svg",
    redIcon: "assets/icon/nav-menu/search-red.svg",
    url: ROUTES_STR.explore,
    type: "EXPLORE",
  },
  {
    name: "Your Plate",
    greyIcon: "assets/icon/nav-menu/your-plate-grey.svg",
    redIcon: "assets/icon/nav-menu/your-plate-red.svg",
    url: ROUTES_STR.cart,
    type: "YOUR_PLATE",
  },
  {
    name: "Account",
    greyIcon: "assets/icon/nav-menu/account-grey.svg",
    redIcon: "assets/icon/nav-menu/account-red.svg",
    url: ROUTES_STR.account,
    type: "ACCOUNT",
  },
];

export const ALLOWED_IMAGE_TYPE = [
  "image/png",
  "image/PNG",
  "image/jpg",
  "image/JPG",
  "image/jpeg",
  "image/JPEG",
];

export const FOOD_PREFERENCES_FILTER_LIST = [
  {
    name: "anything",
    val: "anything",
    img: "assets/icon/account/food-preferences/anything-food.svg",
  },
  {
    name: "vegetarian",
    val: "veg",
    img: "assets/icon/account/food-preferences/vegetarian-food.svg",
  },
  {
    name: "non vegetarian",
    val: "nonveg",
    img: "assets/icon/account/food-preferences/non-vegetarian-food.svg",
  },
];

export function getOWLCarouselConfig(type) {
  const MESSAGES = {
    COMMON_OPTIONS: {
      autoWidth: true,
      navSpeed: 0,
      center: false,
      dots: false,
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 2,
        },
        740: {
          items: 3,
        },
        // 940: {
        //   items: 4,
        // }
      },
      nav: false,
    },

    DESKTOP_BANNER_OPTIONS: {
      autoWidth: true,
      navSpeed: 600,
      center: false,
      dots: true,
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 2,
        },
        740: {
          items: 3,
        },
        // 940: {
        //   items: 4,
        // }
      },
      nav: true,
      navText: [
        '<i class="fa fa-angle-left" aria-hidden="true"></i>',
        '<i class="fa fa-angle-right" aria-hidden="true"></i>',
      ],
      loop: true,
    },

    INFO_BAR_OPTIONS: {
      autoWidth: true,
      navSpeed: 700,
      center: false,
      dots: false,
      loop: true,
      dragging: true,
      autoplay: true,
      responsive: {
        0: {
          items: 1,
        },
        770: {
          items: 2,
        },
        940: {
          items: 2,
        },
      },
      nav: false,
      navText: ["", ""],
    },
    FULL_WDT_BANNER_OPTIONS: {
      navSpeed: 0,
      center: false,
      dots: false,
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 1,
        },
        740: {
          items: 1,
        },
        940: {
          items: 1,
        },
      },
      nav: false,
    },
    EXPLORE_BANNER_OPTIONS: {
      autoWidth: true,
      navSpeed: 600,
      center: true,
      dots: true,
      loop: true,
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 2,
        },
        740: {
          items: 3,
        },
      },
    },
  };
  
  return MESSAGES[type];
}

export function getLottieConfig(type) {
  const CONFIG = {
    REWARD_ILLUSTRATION_LOTTIE_CONFIG: {
      path: "assets/lotties-files/rewards-invate.json",
      renderer: "canvas",
      autoplay: true,
      loop: true,
    },
    SKELETON_LOADING_LOTTIE_CONFIG: {
      path: "assets/lotties-files/hafl-skeleton-loading-card.json",
      renderer: "canvas",
      autoplay: true,
      loop: true,
    },
    DESKTOP_LOADING_LOTTIE_CONFIG: {
      path: "assets/lotties-files/home-desktop-loader.json",
      renderer: "canvas",
      autoplay: true,
      loop: true,
    },
    WELCOME_PAGE_LOTTIE_CONFIG: {
      path: "assets/lotties-files/welcome-page.json",
      renderer: "canvas",
      autoplay: true,
      loop: true,
    },
    NO_INTERNET_LOTTIE_CONFIG: {
      path: "assets/lotties-files/no-internet.json",
      renderer: "canvas",
      autoplay: true,
      loop: true,
    },
    CARD_EARN_CONFIG: {
      path: "assets/lotties-files/scratch-card-earn.json",
      renderer: "canvas",
      autoplay: true,
      loop: false,
      showConfig: false,
    },
    GIFT_REWARDS_CONFIG: {
      path: "assets/lotties-files/gift-rewards.json",
      renderer: "canvas",
      autoplay: true,
      loop: false,
      showConfig: false,
    },
    SUCCESS_CONFIG: {
      path: "assets/lotties-files/success-animation.json",
      renderer: "canvas",
      autoplay: true,
      loop: false,
      showConfig: false,
    },
    OUTLET_UNAVAILABLE_CONFIG:{
      path:"assets/lotties-files/No-outlet-blinker.json",
      renderer: "canvas",
      autoplay: true,
      loop: true,
      showConfig: false,

    }
  };

  return CONFIG[type];
}

export function getConfig(type) {
  const CONFIG = {
    LOCATION_MODAL_CONFIG: {
      windowClass: "modal fade come-from-modal hide-scroll-view left",
      backdrop: "static",
      keyboard: false,
      scrollable: false,
      centered: true,
    },
    COMMON_CSS_MODAL_CONFIG: {
      windowClass: "common-modal-css",
      backdrop: "static",
      keyboard: false,
      scrollable: false,
      centered: true,
    },
    RATING_MODAL_CONFIG: {
      windowClass: "rating-modal-mobile-css",
      backdrop: "static",
      keyboard: false,
      scrollable: false,
      centered: true,
    },
    ADDON_VARIANT_CONFIG: {
      windowClass: "addon-variant-modal-css",
      backdrop: "static",
      keyboard: false,
      scrollable: true,
      centered: true,
    },
    ADDON_VARIANT_CONFIG_DESKTOP: {
      windowClass: "common-modal-css",
      backdrop: "static",
      keyboard: false,
      scrollable: true,
      centered: true,
      size: "md",
    },
    MENU_CATEGORY_CONFIG: {
      windowClass: "categoryModal",
      keyboard: false,
      scrollable: true,
      centered: true,
    },

    CLEAR_PLATE_CONFIG: {
      windowClass: "clearPlateModal",
      backdrop: "static",
      keyboard: false,
      scrollable: true,
      centered: true,
    },
    ADDON_REPEAT_CONFIG: {
      windowClass: "repeat-item-modal-css",
      // backdrop: 'static',
      keyboard: false,
      scrollable: true,
      centered: true,
    },
    CHANGE_ADDRESS_CONFIG: {
      windowClass: "change-address-modal-css",
      backdrop: "static",
      keyboard: false,
      scrollable: true,
      centered: true,
    },
    FROM_RIGHT_MODAL: {
      windowClass: "modal fade come-from-modal right",
      backdrop: true,
      keyboard: false,
      scrollable: false,
      centered: true,
    },
    FROM_LEFT_MODAL:{
      windowClass: "modal fade come-from-modal left",
      backdrop: "static",
      keyboard: false,
      scrollable: false,
      centered: true,
    },
    ORDER_PLACED_MSG_CONFIG: {
      windowClass: "order-place-modal",
      backdrop: "static",
      keyboard: false,
      scrollable: false,
      centered: true,
    },
    TAX_AND_CHARGES_CONFIG: {
      windowClass: "tax-and-charges-modal",
      backdrop: "static",
      keyboard: false,
      scrollable: false,
      centered: true,
    },
    THANKSFOR_RATING_CONFIG: {
      windowClass: "thanks-for-rating-modal",
      backdrop: "static",
      keyboard: false,
      scrollable: false,
      centered: true,
    },
    COUPON_CONFIRMATION_CONFIG: {
      windowClass: "coupon-confirmation-modal",
      backdrop: "static",
      keyboard: false,
      scrollable: false,
      centered: true,
    },
    REFERRAL_CODE_MODAL_CONFIG: {
      windowClass: "addon-variant-modal-css",
      backdrop: "static",
      keyboard: false,
      scrollable: true,
      centered: true,
    },
    FROM_BOTTOM_MODAL: {
      windowClass: "from-bottom-modal",
      backdrop: "static",
      keyboard: false,
      scrollable: false,
      centered: true,
    },
    LOGIN_DESKTOP_CONFIG: {
      windowClass: "login-desktop-modal",
      backdrop: "static",
      keyboard: false,
      scrollable: false,
      centered: true,
      size: "md",
    },
    DESKTOP_OFFER_MODAL_CONFIG: {
      windowClass: "offer-modal-css",
      backdrop: "static",
      keyboard: false,
      scrollable: false,
      centered: true,
    },
    SCRATCH_CARD_CONFIG: {
      ORDER_TRACK: {
        windowClass: "scratch-card-order-track",
        backdrop: false,
        scrollable: false,
        centered: true,
      },
      EARNED_CARDS: {
        windowClass: "scratch-card-earned",
        backdrop: true,
        keyboard: false,
        scrollable: false,
        centered: true,
      },
      EARNED_CARDS_DESKTOP:{
        windowClass: "scratch-card-earned_desktop",
        backdrop: true,
        keyboard: false,
        scrollable: false,
        centered: false,
      }
    },
  };

  return CONFIG[type];
}

export function getMessages(type) {
  const MESSAGES = {
    LOGOUT_MSG: {
      title: "Logging Out?",
      msg: "Are you sure you want to sign out? We will miss you 😞",
      btnClose: "I will stay",
      btnConfirm: "Log Out",
      isLogout: true,
    },
    DELETE_ADDRESS_MSG: {
      title: "Delete",
      msg: "Do you want to Delete this Address?",
      btnClose: "No",
      btnConfirm: "Yes",
      isDelete: true,
    },
    CLEAR_ALL_DISH_MSG: {
      img: "assets/images/v3/clear-plate.svg",
      title: "Clear cart",
      msg: "Do you wish to discard the selected dishes from your Plate?",
      btnClose: "Cancel",
      btnConfirm: "Clear Plate",
      isClearAllDish: true,
    },
    CLEAR_PLATE_MSG: {
      img: "assets/images/v3/clear-plate.svg",
      title: "Clear cart",
      msg: "",
      previousOutlet: "",
      newOutlet: "",
      btnClose: "Cancel",
      btnConfirm: "Clear all",
      isPLateClear: true,
    },
    OUTLET_MENU_CLOSE_MSG: {
      title: "",
      msg: "",
      btn: "Cancel",
      isOutletClose: true,
    },
    NEW_USER_CNF_MSG: {
      img: "assets/images/v3/clear-plate.svg",
      title: "New User",
      msg: "Sorry, No user was found with this number. Sign up to get the best deals on your favorites food!",
      btnClose: "Cancel",
      btnConfirm: "Create account",
      isNewUser: true,
    },
  };
  return MESSAGES[type];
}

export const ADDRESS_BY_CITY = {
  types: [],
  componentRestrictions: { country: "IN" },
  fields: ["address_components", "geometry", "place_id", "formatted_address"],
};

export function currentOrderStatus(orderStatus) {
  const type = {
    cancelled: { index: "" },
    rejected: { index: "" },
    inDispute: { index: "" },
    enforcedCancel: { index: "" },
    acceptanceWait: { index: 0 },
    valetOnWayToConfirm: { index: "" },
    foodPreparation: { index: 1 },
    foodReady: { index: 1 },
    waitingDeliveryPickup: { index: 1 },
    pickup_complete: { index: 1 },
    started_for_delivery: { index: 1 },
    outForDelivery: { index: 2 },
    delivered: { index: 2 },
    complete: { index: "" },
  };

  return type[orderStatus].index;
}

export function currentOrderStatusIndex(statusObj) {
  let index = "";
  if (
    statusObj.deliveryStatus === "pickup_complete" ||
    statusObj.deliveryStatus === "started_for_delivery" ||
    statusObj.deliveryStatus === "delivered"
  ) {
    index = currentOrderStatus(statusObj.deliveryStatus);
  } else {
    index = currentOrderStatus(statusObj.orderStatus);
  }

  return index;
}

export const ERROR_CART_MESSAGES = {
  1010: {
    imgUrl: "assets/icon/cart/item-unavailable.svg",
    title: "not serving",
    desc: "",
    subTitle: "",
    btnTxt: "Remove Item",
    btnClassCSS: "btn-fw-bg",
    titleCss: "red-title-txt",
    btnAction: "ITEM_NOT_SERVING",
  },
  1011: {
    imgUrl: "assets/icon/cart/restaurant-closed.svg",
    title: "Restaurant is closed",
    desc: "",
    subTitle: "",
    btnTxt: "Check other restaurant",
    btnClassCSS: "btn-fw-bg",
    titleCss: "red-title-txt",
    btnAction: "OUTLET_NOT_SERVING",
  },
  1012: {
    imgUrl: " ",
    title: "",
    desc: "",
    subTitle: "",
    btnTxt: "",
    btnClassCSS: "btn-fw-bg",
    titleCss: "red-title-txt",
    btnAction: "OFFER_INVALID",
  },
  1013: {
    imgUrl: " ",
    title: "",
    desc: "",
    subTitle: "",
    btnTxt: "",
    btnClassCSS: "btn-fw-bg",
    titleCss: "red-title-txt",
    btnAction: "OFFER_MIN_ORDER",
  },
  1014: {
    imgUrl: "assets/icon/cart/new-location.svg",
    title: "Delivery person unavailable",
    desc: "We are not serving in this location.",
    subTitle: "",
    btnTxt: "Try Other Location",
    btnClassCSS: "btn-fw-bg",
    titleCss: "red-title-txt",
    btnAction: "DELIVERY_PERSON_UNAVAILABLE",
  },
  1015: {
    imgUrl: "assets/icon/cart/new-location.svg",
    title: "Location unserviceable",
    desc: "We are not serving this location.",
    subTitle: "",
    btnTxt: "Try Other Location",
    btnClassCSS: "btn-fw-bg",
    titleCss: "red-title-txt",
    btnAction: "LOCATION_UNSERVICEABLE",
  },
  DELIVERY_ADDRESS: {
    imgUrl: "assets/icon/cart/deliver-to-home.svg",
    title: "",
    desc: "",
    subTitle: "Change address",
    btnTxt: "",
    btnClassCSS: "btn-green-bg",
    titleCss: "black-title-txt",
    btnAction: "PLACE_ORDER",
  },
  ADD_ADDRESS: {
    imgUrl: "assets/icon/cart/new-location.svg",
    title: "You are in new location !",
    desc: "",
    subTitle: "",
    btnTxt: "Add Address To Proceed",
    btnClassCSS: "btn-fw-bg",
    titleCss: "black-title-txt",
    btnAction: "ADD_ADDRESS",
  },
  SELECT_ADDRESS: {
    imgUrl: "assets/icon/cart/new-location.svg",
    title: "You are in new location !",
    desc: "",
    subTitle: "",
    btnTxt: "Select Address",
    btnClassCSS: "btn-fw-bg",
    titleCss: "black-title-txt",
    btnAction: "SELECT_ADDRESS",
  },
  LOGIN: {
    imgUrl: "",
    title: "",
    desc: "",
    subTitle: "",
    btnTxt: "Login",
    btnClassCSS: "btn-fw-bg",
    titleCss: "black-title-txt",
    btnAction: "LOGIN",
  },
};

export const ADDRESS_TAG_IMG = {
  home: "assets/icon/cart/deliver-to-home.svg",
  office: "assets/icon/cart/deliver-to-office.svg",
  others: "assets/icon/cart/deliver-to-other.svg",
};

export const CHARGES = {
  packingCharges: "Packing Charges",
  restaurantCharges: "Restaurant Charges",
  surgeCharges: "Surge Charges",
  deliveryCharge: "Delivery Charges",
};

export const EMPTY_PLATE = {
  YES: "YES",
  NO: "NO",
};

export const ORDER_TRACK_URLS = {
  MAPS_ICON_URLS: {
    mapsMarkerUrl: "assets/icon/address/maps-marker.svg",
    locationPointRedUrl: "assets/icon/address/location-point-red-icon.svg",
    riderUrl: "assets/images/address/rider.png",
    restLocationUrl: "assets/icon/address/rest-location-2.svg",
    homeLocationUrl: "assets/icon/address/home-location.svg",
  },
  ORDER_STATUS_URLS: {
    foodReadyGrey: "icon-food-ready primary-grey-2",
    foodReadyRed: "icon-food-ready rose-clr",
    foodReadyGreen: "icon-food-ready weird-green",
    foodOnTheWayGrey: "icon-food-on-way primary-grey-2",
    foodOnTheWayRed: "icon-food-on-way rose-clr",
    foodOnTheWayGreen: "icon-food-on-way weird-green",
    orderPickedUpGrey: "icon-order-pickedup primary-grey-2",
    orderPickedUpRed: "icon-order-pickedup rose-clr",
    orderPickedUpGreen: "icon-order-pickedup weird-green",
    orderReceivedGrey: "icon-order-received primary-grey-2",
    orderReceivedRed: "icon-order-received rose-clr",
    orderReceivedGreen: "icon-order-received weird-green",
  },
};

export const MAP_ICONS = {
  renderOptions: {
    polylineOptions: {
      strokeColor: "#fc6f7f",
      strokeWeight: "2.5",
    },
    suppressMarkers: true,
    suppressInfoWindows: true,
  },

  markerOptions: {
    origin: {
      infoWindow: "Origin.",
      icon: {
        url: ORDER_TRACK_URLS.MAPS_ICON_URLS.riderUrl,
        scaledSize: { width: 20, height: 30 },
        rotation: 90,
      },
    },
    destination: {
      infoWindow: "Destination",
      icon: ORDER_TRACK_URLS.MAPS_ICON_URLS.homeLocationUrl,
      size: { width: 20, height: 30 },
    },
  },

  riderIcon: {
    url: ORDER_TRACK_URLS.MAPS_ICON_URLS.riderUrl,
    scaledSize: { width: 20, height: 30 },
  },

  restLocationIcon: {
    url: ORDER_TRACK_URLS.MAPS_ICON_URLS.restLocationUrl,
    scaledSize: { width: 30, height: 30 },
  },

  userLocationIcon: {
    url: ORDER_TRACK_URLS.MAPS_ICON_URLS.homeLocationUrl,
    scaledSize: { width: 30, height: 30 },
  },
};

export const ORDER_STATUS_TO_DISPLAY = [
  "Order Accepted",
  "Food is being prepared",
  "Order Delivered",
];

export const ORDER_STATUS_LIST = {
  REJECTED_LIST: ["cancelled", "rejected", "inDispute", "enforcedCancel"],
  PROCESSING_LIST: [
    "acceptanceWait",
    "valetOnWayToConfirm",
    "foodPreparation",
    "foodReady",
    "waitingDeliveryPickup",
    "outForDelivery",
  ],
  DELIVERED_LIST: ["delivered", "complete"],
};

export const KEYS_TO_SHOW_RIDER_DETAILS = [
  "runner_accepted",
  "reached_for_pickup",
  "pickup_complete",
  "started_for_delivery",
  "reached_for_delivery",
];

export const RATING_STATUS_DECLARATION = {
  process: [
    {
      name: "Packaging",
      url: "assets/icon/rate/rate-packaging.svg",
      width: 72,
    },
    { name: "Taste", url: "assets/icon/rate/rate-taste.svg", width: 60 },
    {
      name: "Portion size",
      url: "assets/icon/rate/rate-portion-size.svg",
      width: 60,
    },
  ],
  statusOfDelivery: [
    { name: "I loved it" },
    { name: "I didn't like it" },
    { name: "Delivery Executive was good" },
    { name: "Delivery was fast!" },
    { name: "Delivery Executive was rude" },
    { name: "Late Delivery" },
  ],
};

export const FEEDBACK_STATUS_MAPPING = {
  1: "Highly Disappointed",
  2: "Needs to Improve",
  3: "Satisfactory",
  4: "Good",
  5: "Excellent",
};

export const PAYMENT_TYPE = {
  paymentGateway: "PAYMENT GATEWAY",
  wallet: "WALLET",
  netbanking: "NETBANKING",
};

export const SCRATCH_CARDS = {
  blueCard: "assets/scratch-cards/blue.jpg",
  greenCard: "assets/scratch-cards/green.jpg",
  pinkCard: "assets/scratch-cards/pink.jpg",
  yellowCard: "assets/scratch-cards/yellow.jpg",
  orangeCard: "assets/scratch-cards/orange.jpg",
  whiteCard: "assets/scratch-cards/white.png",
};
export const deliveryTypeMessage = [
  {
    name: "Delivery",
    text: "Order will be delivered at your selected address.",
  },
  {
    name: "Self-Pick up",
    text: "You have to pick up the order yourself from the restaurant.",
  },
];
export const ICONS_ON_CARDS = {
  coupon: "assets/scratch-cards/icons-on-card/coupon.svg",
  dollar: "assets/scratch-cards/icons-on-card/dollat.svg",
  rupee: "assets/scratch-cards/icons-on-card/rupee.svg",
  watermelon: "assets/scratch-cards/icons-on-card/watermelon.svg",
  cookie: "assets/scratch-cards/icons-on-card/cookie.png",
  background: "assets/scratch-cards/icons-on-card/background.svg",
};

export const MY_REWARD_CARDS = [
  {
    imageUrl:
      "assets/scratch-cards/scratch-summary-icons/yumzy-wallet-icon.png",
    title: "Wallet",
    subTitle: "Yumzy Wallet",
    route: "/my-wallet",
  },
  {
    imageUrl: "assets/scratch-cards/scratch-summary-icons/scratch-icon.png",
    title: "Scratch Card",
    subTitle: "Scratch Card",
    route: "/earned-scratch-cards",
  },
];

export const REDEEM_TYPE = {
  CASHBACK: 80,
  THIRD_PARTY: 81,
};

export const PLACEHOLDER_IMGS = {
  banners: [
    "assets/images/v3/placeholder-images/banner-pink.png",
    "assets/images/v3/placeholder-images/banner-grey.png",
  ],
  circleImgs: "assets/images/v3/placeholder-images/green-circle.svg",
  squareImgs: [
    "assets/images/v3/placeholder-images/violet-rect.svg",
    "assets/images/v3/placeholder-images/light-yellow-rect.svg",
    "assets/images/v3/placeholder-images/light-green-rect.svg",
    "assets/images/v3/placeholder-images/light-blue-rect.svg",
  ],
  mcDonald: "assets/images/v3/placeholder-images/mc-donald.png",
};

export const COMMON_IMG = {
  cross: "assets/icon/common/cross-grey.svg",
  black: "assets/icon/common/left-back-black.svg",
};

export const DefaultTooltipOptions: TooltipOptions = {
  placement: "bottom",
  "show-delay": 500,
  theme: "light",
  width: 200,
  "max-width": 200,
  "z-index": 99999999999,
};

export const AGM_MAP_CUSTOM_STYLES = [
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [{ visibility: "on" }],
  },
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    stylers: [{ visibility: "off" }],
  },
];

export const NAVIGATE_THROUGHT_NOTIFICATION = {
  home: ROUTES_STR.home,
  reward: ROUTES_STR.account,
  explore: ROUTES_STR.explore,
  plate: ROUTES_STR.cart,
  wallet: ROUTES_STR.account,
  invite: "/my-rewards",
  favourites: "/favorites",
  food_pref: "/food-preferences",
  offer: "/restaurants/offers",
  order_tracking: ROUTES_STR.account,
  promo_outlet: "/see-all-restaurants",
  promo_dish: "/see-all-dish",
  dish: "/rest-details",
  outlet: "/rest-details",
  update: "",
  scratch_card: "/earned-scratch-cards",
};


export const WELCOME_PAGE_IMAGES = [
  { 
    src: 'https://sgp1.digitaloceanspaces.com/listing/yumzy/app/images/welcome/wide_range.png',
    title: 'Wide range of dishes',
    desc: 'Discover new flavours and delicacies from the best restaurants.'
  },
  {
    src: 'https://sgp1.digitaloceanspaces.com/listing/yumzy/app/images/welcome/order_anywhere.png', 
    title: 'Order from anywhere', 
    desc: 'Experience deliveries at rocket speed from anywhere in your city.'
  },
  {
    src: 'https://sgp1.digitaloceanspaces.com/listing/yumzy/app/images/welcome/reward.png',
    title: 'Rewards on every order', 
    desc: 'Earn assured cashback and coupons on every order.'
  }
];

export const LOGIN_SIGNUP_TEXT = {
  main: {
    login: 'Explore amazing deals on restaurants near you. Login now.',
    signup: 'Explore amazing deals on restaurants near you. Create an account to start ordering.'
  },
  cart: {
    login: 'Tasty food awaits you. Login to continue with your order.',
    signup: 'Tasty food awaits you. Create an account to place your order.'
  },
  invite: {
    login: 'Login now to start referring Yumzy.',
    signup: 'Create an account to start referring Yumzy.'
  }
}