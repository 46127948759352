import { SafeHtmlPipe } from './../../utils/safe-html.pipe';
import { SafePipe } from './../../utils/safe.pipe';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent implements OnInit{

  @Input() modalObj: any;
  itsSafe: any;

  constructor(public activeModal: NgbActiveModal, private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    console.log(this.modalObj);
    document.getElementById('iframe')['contentWindow'].document.write(this.modalObj);
  }

  transform(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustResourceUrl(v);
  }
}
