import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// tslint:disable-next-line: max-line-length
import { CouponTermsAndConditionsComponent } from 'src/app/modules/modals/coupon-terms-and-conditions/coupon-terms-and-conditions.component';
import { getConfig } from 'src/app/core/constants/app-constants';
import { dynamicFontSize } from '../../utils/common-functions';

@Component({
  selector: 'app-coupon-card',
  templateUrl: './coupon-card.component.html',
  styleUrls: ['./coupon-card.component.scss']
})
export class CouponCardComponent implements OnInit, AfterViewInit {
  @Input() coupon;
  @Input() viewType;
  dummyList: any[];
  @Output() applyCoupon = new EventEmitter();

  constructor(private modalService: NgbModal) {
    this.dummyList = Array(9).fill(0);
  }

  ngOnInit() {
    console.log(this.viewType);

  }

  onApplyCoupon(code) {
    this.applyCoupon.emit(code);
  }

  openTermsAndConditions() {

    const modalRef = this.modalService.open(CouponTermsAndConditionsComponent, getConfig('COMMON_CSS_MODAL_CONFIG'));
    modalRef.componentInstance.termsConditions = this.coupon.terms;
  }


  ngAfterViewInit() {
    const divObj = document.querySelectorAll('.dynamic-fs');

    divObj.forEach(box => {
      box['style'].fontSize = dynamicFontSize(box.textContent.length);
    });
  }
}
