import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})

export class CacheService {
    private loginFormData: any;
    private signupFormData: any;

    constructor() { }

    public setLoginFormData(formData: any) {
        this.loginFormData = formData;
    }

    public getLoginFormData() {
        return this.loginFormData;
    }

    public setSignupFormData(formData: any) {
        this.signupFormData = formData;
    }

    public getSignupFormData() {
        return this.signupFormData;
    }
}