import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../modals/login-signup-modal/service/login.service';
import { getFormValidation, maxNumToBeAllowed } from 'src/app/shared/utils/common-functions';
import { COUNTER, COUNTRY_CODE, getConfig, getMessages, LOGIN_SIGNUP_TEXT, SECONDS } from 'src/app/core/constants/app-constants';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { HttpResponse } from '@angular/common/http';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { CacheService } from 'src/app/shared/services/cache.service';


export abstract class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  isSubmitted = false;
  mobile = '';
  otpDesktop: boolean = false;
  loginDesktop: boolean = true;
  titleTxt = "";
  navUrl = ""
  otpForm: FormGroup;
  counter = COUNTER;
  interval: any;
  showResend = false;
  seconds = SECONDS;
  routeObj;
  notificationService: any;
  showOtpModal = false;
  modalObj;
  navigateToAccount = false;
  loginText = LOGIN_SIGNUP_TEXT.main.login;
  navigationFrom = 'main';

  constructor(private fb: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private location: Location,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private cacheService: CacheService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.mobile = params['mobile'] ? String(params['mobile']) : '';
      if (params['navigateToAccount']) {
        this.navigateToAccount = true;
      }
      if(params['navigationFrom']) {
        this.loginText = LOGIN_SIGNUP_TEXT[params['navigationFrom']].login;
        this.navigationFrom = params['navigationFrom'];
      }
      if (params['navigateToCart'] && params['navigateToCart']==='Y') {
        this.navUrl = 'main/cart'
      } else  if(params['navigateToAccount'] === 'true' || params['fromHome'] === 'Y') {
        this.navUrl = 'main/home'
      } else {
        this.navUrl = 'welcome'
      }
    });
  }

  ngOnInit() {
    console.log('oninit');
    this.initFrom();
    this.loginForm.controls['mobile'].setValue(this.mobile);
    const formData = this.cacheService.getLoginFormData();
    if(formData) {
      this.loginForm.patchValue(formData);
    }
  }

  onBack() {
    this.location.back();
    // this.router.navigate(['welcome']);
  }

  initFrom() {
    console.log('form initn');
    this.loginForm = this.fb.group({
      'mobile': [null, [Validators.required, Validators.min(1111111111), Validators.max(9999999999)]]
    });
    this.otpForm = this.fb.group({
      'otp': ['', [Validators.required, Validators.min(1000), Validators.max(9999), Validators.maxLength(4)]]
    });
  }

  onCloseLoginModal() {
    this.modalService.dismissAll();
  }

  // convenience getter for easy access to form fields
  get f() {
    // console.log(this.loginForm);
    return this.loginForm.controls;
  }

  get otpControl() { return this.otpForm.controls; }

  getOTPValid(fieldName) {
    return getFormValidation(this.otpControl, fieldName, this.isSubmitted);
  }

  // for checking valid fields
  getValid(fieldName) {
    return getFormValidation(this.f, fieldName, this.isSubmitted);
  }

  createObjForSendOTP() {
    const body = {
      mobile: String(this.loginForm['controls']['mobile'].value),
      countryCode: COUNTRY_CODE,
      action: 'login',
      type: 'LOGIN'
    }
    return body;
  }

  // for continue to send otp
  async onContinue() {
    this.isSubmitted = true;
    let navigateToCart = 'N';
    this.activatedRoute.queryParams.subscribe((params) => {
      console.log(params);
      if (Object.keys(params).length > 0 && params['navigateToCart'] === 'Y') {
        navigateToCart = 'Y';
      }
    })
    if (this.loginForm.valid) {
      const reqBody = await this.createObjForSendOTP();
      this.loginService.sendOtp(reqBody).then((res: HttpResponse<any>) => {
        const responseData = res.body;
        console.log(responseData);
        reqBody['userType'] = responseData['code'] === 1003 ? 'NEW' : 'OLD';
        if (reqBody['userType'] === 'NEW') {
          const mObj = getMessages('NEW_USER_CNF_MSG');
          const modalRef = this.modalService.open(ConfirmationModalComponent, getConfig('COMMON_CSS_MODAL_CONFIG'));
          modalRef.componentInstance.modalObj = mObj;
          modalRef.result.then(async (res) => {
            if (ApplicationStateService.isMobileResolution) {
              this.router.navigate(['sign-up'], { queryParams: { navigationFrom: this.navigationFrom, mobile: reqBody.mobile } });
            } else {
              console.log(this.loginForm.controls['mobile'].value);
              const userEnteredMobile = this.loginForm.controls['mobile'].value;
              this.activeModal.close({ openSignupModal: true, mobile: userEnteredMobile });
              // this.modalService.open(SignUpDesktopComponent, getConfig('LOGIN_DESKTOP_CONFIG'));
            }
          }).catch((error) => {
            console.log(error);
          });
        } else {
          const navigationExtras: NavigationExtras = {
            queryParams: {
              mobile: reqBody.mobile,
              type: reqBody.type,
              navigateToCart: navigateToCart
            }
          };
          if (ApplicationStateService.isMobileResolution) {
            this.router.navigate(['otp'], navigationExtras);
          } else {
            this.modalObj = { type: 'LOGIN', mobile: this.loginForm.controls['mobile'].value };
            this.showOtpModal = true;
            // const userEnteredMobile = this.loginForm.controls['mobile'].value;
            // this.activeModal.close({ openOtpModal: true, mobile: userEnteredMobile });
          }
        }
      });
    } else {
      return;
    }
  }

  // openSignup() {
  //   this.activeModal.close({ openSignupModal: true });
  //   this.modalService.open(SignUpDesktopComponent, getConfig('LOGIN_DESKTOP_CONFIG'));
  // }

  onSignUp() {
    this.router.navigate(['sign-up']);
  }

  onKeyPress(event) {
    if (event.keyCode === 13) {
      this.onContinue();
    }
    return maxNumToBeAllowed(event, 10);
  }

  onChangeMobileNumber(event) {
    console.log(event);
    this.showOtpModal = false;
  }

  ngOnDestroy() {
    this.cacheService.setLoginFormData(this.loginForm.value);
  }
}
