import { Router } from '@angular/router';
import { ErrorCode } from './../utils/enums/error-code.enum';
import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';
import { ApplicationStateService } from '../services/application-state.service';
import { TempTokenService } from '../services/temp-token.service';
import { ROUTES_STR } from 'src/app/core/constants/app-constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
	callTempToken = true;
	/**
   *
   * @param req
   * @param next
   * expected output from api : {message:"", data:[] | {} }
   */
	constructor(private toasterService: ToastService,
		private router: Router,
		private tempTokenService: TempTokenService,
		private modalService: NgbModal) {
	}
	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		console.log('called response interceptor', req);
		return next.handle(req).pipe(
			map((event: HttpEvent<any>) => {
				console.log(event);
				if (event['status'] === 200) {
					this.callTempToken = true;
				}
				return event;
			}),
			catchError((err: any, caught) => {
				if (err instanceof HttpErrorResponse) {
					if (Math.floor(err.status / 100) === 4) {
						// handle 400 errors
						// todo load a toast here with err.message - received from server
						if (err.status === 403) {
							//  Handling the GSTN API response
							if (err && err.error.message) {
								this.toasterService.presentToast(err.error.message, 'Error', 'error');
							} else {
								this.toasterService.presentToast(err.error, 'Error', 'error');
							}
						} else {
							this.toasterService.presentToast(err.error.message, 'Error', 'error');
							console.log('err.error =', err.error, ';');
						}
					}
					if (Math.floor(err.status / 100) === 5) {
						console.log('err.error =', err.error, ';');
						if (!this.callTempToken) {
							this.callTempToken = false;
						}
						const responseError = err.error;
						if (responseError['data']) {
							// this.toasterService.presentToast(err.error.data.message, '', 'error');
						} else if (ErrorCode.LOGGED_OUT === responseError['code']
							|| ErrorCode.TOKEN_INVALID === responseError['code']
							|| ErrorCode.TOKEN_EXPIRED === responseError['code']) {
							if (ApplicationStateService.isMobileResolution) {
								this.router.navigate(['welcome']);
							} else {
								if (this.callTempToken) {
									this.callTempToken = false;
									this.tempTokenService.getTempToken();
									// this.router.navigate([ROUTES_STR.home]);
								}
							}
							this.tempTokenService.resetAppData();
							if (!ApplicationStateService.isMobileResolution) {
								this.modalService.dismissAll();
								// this.router.navigate([ROUTES_STR.home]);
							}
						} else if (ErrorCode.RESTAURANT_NOT_SERVING === responseError['code']
							|| ErrorCode.NOT_SERVING_LOCATION === responseError['code']
							|| ErrorCode.DELIVERY_OPERATION_OFF === responseError['code']) {
						} else {
							this.toasterService.presentToast(err.error.message, '', 'error');
						}
					}
					console.log(err);
					return throwError(err);
				}
			})
		);
	}
}
