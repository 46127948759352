import {
  Component,
  HostListener,
  Input,
  OnInit,
  Renderer2,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationStateService } from "../../services/application-state.service";

@Component({
  selector: "app-outlet-group",
  templateUrl: "./outlet-group.component.html",
  styleUrls: ["./outlet-group.component.scss"],
})
export class OutletGroupComponent implements OnInit {
  @Input() modalObj;
  selectedOutlet:any;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  onChangeOutlet(outlet) {
    this.selectedOutlet = outlet;
    console.log(this.selectedOutlet);
  }
  onSelectOutlet() {
    this.activeModal.close(this.selectedOutlet);
  }
}
