import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrderService } from '../../service/order.service';
import { RATING_STATUS_DECLARATION, FEEDBACK_STATUS_MAPPING, getConfig, ROUTES_STR } from 'src/app/core/constants/app-constants';
import { ThankyouForRatingModalComponent } from '../thankyou-for-rating-modal/thankyou-for-rating-modal.component';
import { Router } from '@angular/router';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';

export abstract class RatingComponent implements OnInit {

  @Input() modalObj: any;
  @Input() selectedRating;
  ratingObj = {};
  rateBdrIcon = "assets/icon/rate/rate-star-grey-border.svg";
  rateYellowIcon = "assets/icon/rate/rate-star-yellow.svg";
  starsList = [0, 1, 2, 3, 4];
  isYouLikeMost = true;
  outletObj;
  statusList = RATING_STATUS_DECLARATION;
  feedback = '';
  riderFeedback = '';
  @Output() passRating: EventEmitter<any> = new EventEmitter();
  comments = '';
  textToShow = 'How\'s the food?';
  likeMostTxt = 'What did you like most?';
  riderRatingTxt = 'What did you like most?';
  descText = '';

  constructor(
    private orderService: OrderService,
    private modalService: NgbModal,
    private router: Router,
    public activeModal: NgbActiveModal,
  ) {
  }
  ngOnInit() {
    this.outletObj = this.modalObj;
    RATING_STATUS_DECLARATION.statusOfDelivery.map(status => status['selectItem'] = false);
    RATING_STATUS_DECLARATION.process.map(status => status['selectItem'] = false);

    const splitStr = this.outletObj.outletName.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    const outletName = splitStr.join(' ');
    this.descText = 'Rate your experience with ' + outletName + ' to Improve their service';

    if (this.selectedRating) {
      const selectedRating = Number(this.selectedRating) - 1;
      setTimeout(() => {
        this.onRatingClick(selectedRating);
      }, 800);
    }

    setTimeout(() => {
      document.getElementById('scrollToView').scrollIntoView();
    }, 200);
  }

  onRatingClick(rat, type?) {
    console.log(type);
    const rating = rat + 1;

    for (let i = 0; i < this.starsList.length; i++) {
      const ind = String(i);
      if (type) {
        if (i <= rat) {
          document.getElementById(`${type}-${ind}`)['src'] = this.rateYellowIcon;
        } else {
          document.getElementById(`${type}-${ind}`)['src'] = this.rateBdrIcon;
        }
        if (type) {
          if (type === 'rest') {
            this.ratingObj['foodRating'] = rating;
            this.feedback = FEEDBACK_STATUS_MAPPING[rating];
            this.likeMostTxt = rating < 3 ? 'What went bad?' : 'What did you like most?';
          } else {
            this.ratingObj['deliveryRating'] = rating;
            this.riderFeedback = FEEDBACK_STATUS_MAPPING[rating];
            this.riderRatingTxt = rating < 3 ? 'And is there any delivery issue?' : 'What did you like most?';

          }
        }
      } else {
        if (i <= rat) {
          document.getElementById(ind)['src'] = this.rateYellowIcon;
        } else {
          document.getElementById(ind)['src'] = this.rateBdrIcon;
        }
      }
    }

    if (ApplicationStateService.isMobileResolution) {
      if (this.isYouLikeMost) {
        this.ratingObj['foodRating'] = rating;
        this.likeMostTxt = rating < 3 ? 'What went bad?' : 'What did you like most?';
      } else {
        this.ratingObj['deliveryRating'] = rating;
        this.likeMostTxt = rating < 3 ? 'And is there any delivery issue?' : 'What did you like most?';
      }
      this.feedback = FEEDBACK_STATUS_MAPPING[rating];
    }
  }

  status(index) {
    RATING_STATUS_DECLARATION.process[index]['selectItem'] = !RATING_STATUS_DECLARATION.process[index]['selectItem'];
  }

  rateType(index) {
    RATING_STATUS_DECLARATION.statusOfDelivery[index]['selectItem'] = !RATING_STATUS_DECLARATION.statusOfDelivery[index]['selectItem'];
  }

  onContinue() {
    this.isYouLikeMost = false;
    this.feedback = '';
    this.textToShow = 'How was your Delivery experience?'
    this.likeMostTxt = 'What did you like most?';
    this.descText = 'Rate your experience with our Delivery Executive! This enables us to help improve their services.';
  }

  onSubmit() {
    const reqBody = {
      orderId: this.outletObj.orderId,
      orderNum: this.outletObj.orderNum,
      deliveryrating: [{
        ratedFor: RATING_STATUS_DECLARATION.statusOfDelivery.filter(list => list['selectItem']).map(filterList => filterList.name),
        rating: this.ratingObj['deliveryRating'],
      }],
      foodRating: [{
        ratedFor: RATING_STATUS_DECLARATION.process.filter(list => list['selectItem']).map(filteredList => filteredList.name),
        rating: this.ratingObj['foodRating']
      }],
      comment: this.comments
    }


    console.log(reqBody);

    this.orderService.rateOrder(reqBody).then((res) => {
      const obj = {
        rated: true,
        orderNum: this.outletObj.orderNum,
        rating: this.ratingObj['foodRating']
      }
      this.passRating.emit(obj);
      this.modalService.open(ThankyouForRatingModalComponent, getConfig('THANKSFOR_RATING_CONFIG'));
      setTimeout(() => {
        if (this.outletObj.navigateToHome) {
          this.router.navigate([ROUTES_STR.home]);
        } else {
          this.modalService.dismissAll();
        }
      }, 2500);
    });
  }

  closeModal() {
    if (this.outletObj.navigateToHome) {
      this.router.navigate([ROUTES_STR.home]);
    } else {
      this.modalService.dismissAll();
    }
  }

  scrollToBottom() {
    setTimeout(() => {
      const divObj = document.getElementById('textarea');
      divObj.scrollIntoView(true);
    }, 1000);
  }

}
