export class AddressModel {
    private addressId: string;
    private houseNum: string;
    private googleLocation: string;
    private fullAddress: string;
    private addressTag: string;
    private landmark: string;
    private name: string;
    private city: string;
    private country: string;
    private state: string;
    private pincode: string;
    private locality: string;
    constructor() {
        this.addressId = '';
        this.houseNum = '';
        this.googleLocation = '';
        this.fullAddress = '';
        this.addressTag = '';
        this.landmark = '';
        this.name = '';
        this.city = '';
        this.country = '';
        this.state = '';
        this.pincode = '';
        this.locality = '';
    }
    public get _locality(): string {
        return this.locality;
    }

    public set _locality(locality: string) {
        this.locality = locality;
    }
    public get _addressId(): string {
        return this.addressId;
    }

    public set _addressId(addressId: string) {
        this.addressId = addressId;
    }

    public get _houseNum(): string {
        return this.houseNum;
    }

    public set _houseNum(houseNum: string) {
        this.houseNum = houseNum;
    }

    public get _googleLocation(): string {
        return this.googleLocation;
    }

    public set _googleLocation(googleLocation: string
    ) {
        this.googleLocation = googleLocation;
    }

    public get _fullAddress(): string {
        return this.fullAddress;
    }

    public set _fullAddress(fullAddress: string) {
        this.fullAddress = fullAddress;
    }

    public get _addressTag(): string {
        return this.addressTag;
    }

    public set _addressTag(addressTag: string) {
        this.addressTag = addressTag;
    }

    public get _landmark(): string {
        return this.landmark;
    }

    public set _landmark(landmark: string) {
        this.landmark = landmark;
    }

    public get _name(): string {
        return this.name;
    }

    public set _name(name: string) {
        this.name = name;
    }


    public get _city(): string {
        return this.city;
    }

    public set _city(city: string
    ) {
        this.city = city;
    }

    public get _country(): string {
        return this.country;
    }

    public set _country(country: string
    ) {
        this.country = country;
    }

    public get _state(): string {
        return this.state;
    }

    public set _state(state: string
    ) {
        this.state = state;
    }

    public get _pincode(): string {
        return this.pincode;
    }

    public set _pincode(pincode: string) {
        this.pincode = pincode;
    }



}