export enum ErrorCode {
    //Home Page
    BANNER = 1000,
    OFFER_OR_ADS_FULL = 1001,
    TOP_BRANDS = 1002,
    OFFER_OR_ADS_HALF = 1003,
    POPULAR_CUISINE = 1004,
    TOP_RESTAURANTS = 2001,
    NEW_LUNCHED = 2002,
    YUMZY_ECLUSIVE = 2003,
    TRENDING_RESTAURANT = 2004,
    FEATURED_BRANDS = 2005,
    YUMZY_BEST = 3001,
    WINTER_SPECIAL = 3002,
    POPULAR_FOOD = 3003,
    TRENDING_FOOD = 3004,
    DYNAMIC_OFFER = 4001,

    NOT_SERVING_LOCATION = 1020,
    RESTAURANT_NOT_SERVING = 1021,
    DELIVERY_OPERATION_OFF = 1023,

    // Cart error code
    ITEM_NOT_SERVING = 1010,
    OUTLET_NOT_SERVING = 1011,
    OFFER_INVALID = 1012,
    OFFER_MIN_ORDER = 1013,
    DELIVERY_PERSON_UNAVAILABLE = 1014,
    LOCATION_UNSERVICABLE = 1015,
    CART_IS_EMPTY = 1016,
    INVALID_CART_PAYLOAD = 1008,
    ITEM_QTY_MORE_THAN_1 = 1010,

    //Menu page
    BEST_SELLER_ITEM = 14,
    RECOMMENDED_ITEM = 13,
    NORMAL_MENU_ITEM = 12,

    USER_NOT_FOUND = 1003,
    USER_EXISTS = 1004,
    LOGGED_OUT = 1006,
    TOKEN_INVALID = 1009,
    TOKEN_EXPIRED = 1009,
}
