import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { StorageService } from "src/app/core/services/storage.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppData {
  static token$ = new BehaviorSubject(null);
  static token = '';
  static baseUrl = environment.baseUrl;
  static analyticsUrl = environment.analyticsUrl;
  static googleMapUrl = environment.googleMapUrl;

  static fcmTokenSub$ = new BehaviorSubject(null);
  static fcmToken = '';

  static isAuthenticated = false;

  static userInfoSub$ = new BehaviorSubject(null); // Contain user info object
  static userInfoData = null;

  static userIdSub$ = new BehaviorSubject(null); // Contain user id
  static userId = null;

  static cartIdSub$ = new BehaviorSubject(null); // contain cartId
  static cartId = '';

  static currentAddressSub$ = new BehaviorSubject(null); // contain current address
  static currentAddressObj;

  static bottomNavMobileIndSub$ = new BehaviorSubject(0);

  static selectedAddonsListSub$ = new BehaviorSubject([]);
  static selectedAddonsList = [];
  static addonList;

  static yourPlateItemsSub$ = new BehaviorSubject(null);
  static yourPlateItems;

  static spInstructionsSub$ = new BehaviorSubject('');
  static spInstructions = '';

  static plateItemCountSub$ = new BehaviorSubject({ itemCount: 0, itemTotal: 0, outletName: '' });
  static previousUrl = '';

  static deviceIdSub$ = new BehaviorSubject('');
  static deviceId = '';

  static itemsInPlateSub$ = new BehaviorSubject([]);
  static itemsInPlate = [];


  constructor(private storageService: StorageService) {
    // for setting current location
    if (this.storageService.getItem("currentLocation") !== null) {
      AppData.currentAddressSub$.next(this.storageService.getItem("currentLocation"));
    }

    AppData.currentAddressSub$.subscribe((res) => {
      this.storageService.setItem("currentLocation", res);
      AppData.currentAddressObj = JSON.parse(JSON.stringify(res));
    });

    if (this.storageService.getItem("token") !== null) {
      AppData.token$.next(this.storageService.getItem("token"));
      AppData.token = this.storageService.getItem("token")
      AppData.isAuthenticated = true;
    }

    AppData.token$.subscribe((data: string) => {
      AppData.token = data;
      this.setToken(data);
    });

    // for getting user info from local  storage
    if (this.storageService.getItem("userInfo") !== null) {
      AppData.userInfoSub$.next(this.storageService.getItem("userInfo"));
    }

    AppData.userInfoSub$.subscribe((userObj) => {
      this.storageService.setItem("userInfo", userObj);
      AppData.userInfoData = JSON.parse(JSON.stringify(userObj));
      console.log("User Info in App Data", userObj);
    });

    if (this.storageService.getItem("userId") !== null) {
      AppData.userIdSub$.next(this.storageService.getItem("userId"));
    }

    AppData.userIdSub$.subscribe((userId) => {
      this.storageService.setItem("userId", userId);
      AppData.userId = JSON.parse(JSON.stringify(userId));
      console.log("User Id in App Data", userId);
    });

    if (this.storageService.getItem('cartId') !== null) {
      AppData.cartIdSub$.next(this.storageService.getItem('cartId'));
    }

    AppData.cartIdSub$.subscribe((cartId) => {
      this.storageService.setItem('cartId', cartId);
      AppData.cartId = cartId;
    });


    if (this.storageService.getItem('yourPlateItem') !== null) {
      AppData.yourPlateItemsSub$.next(this.storageService.getItem('yourPlateItem'));
    }

    AppData.yourPlateItemsSub$.subscribe((plateObj) => {
      this.storageService.setItem('yourPlateItem', plateObj);
      AppData.yourPlateItems = JSON.parse(JSON.stringify(plateObj));
      if (AppData.yourPlateItems) {
        let itmCount = 0;
        let itemsInPlate = 0;
        if (AppData.yourPlateItems['outlets'].length > 0) {
          itemsInPlate = AppData.yourPlateItems['outlets'][0]['items'].length;
        }
        let itmSubTotal = 0
        if (itemsInPlate > 0) {
          AppData.yourPlateItems['outlets'][0]['items'].forEach((items) => {
            itmCount += items.quantity;
            itmSubTotal += items.billing.subTotal;
          });
          console.log('item added');
          AppData.plateItemCountSub$.next({ itemCount: itmCount, itemTotal: itmSubTotal, outletName: plateObj['outlets'][0]['outletName'] });
        } else {
          AppData.plateItemCountSub$.next({ itemCount: 0, itemTotal: 0, outletName: '' });
        }
      }
    });


    if (this.storageService.getItem('selectAddonsList')) {
      AppData.selectedAddonsListSub$.next(this.storageService.getItem('selectAddonsList'));
    }

    AppData.selectedAddonsListSub$.subscribe((addons) => {
      this.storageService.setItem('selectAddonsList', addons);
      AppData.selectedAddonsList = JSON.parse(JSON.stringify(addons));
    });

    if (this.storageService.getItem('spInstructions')) {
      AppData.spInstructionsSub$.next(this.storageService.getItem('spInstructions'));
    }

    AppData.spInstructionsSub$.subscribe((spl) => {
      console.log('splIns---->', spl);
      this.storageService.setItem('spInstructions', spl);
      AppData.spInstructions = JSON.parse(JSON.stringify(spl));
    });

    if (this.storageService.getItem("deviceId") !== null) {
      AppData.deviceIdSub$.next(this.storageService.getItem("deviceId"));
    }

    AppData.deviceIdSub$.subscribe((deviceId) => {
      this.storageService.setItem("deviceId", deviceId);
      AppData.deviceId = JSON.parse(JSON.stringify(deviceId));
      console.log("deviceId", deviceId);
    });

    if (this.storageService.getItem('itemsInPlate')) {
      AppData.itemsInPlateSub$.next(this.storageService.getItem('itemsInPlate'));
    }

    AppData.itemsInPlateSub$.subscribe((items) => {
      if (items) {
        this.storageService.setItem('itemsInPlate', items);
        AppData.itemsInPlate = items;
      }
    })
  }

  setToken(token: string) {
    this.storageService.setItem("token", token);
    console.log("token set", token);
    // AppData.token = token;
  }

  isAuthenticatedFunction() {
    return !(this.storageService.getItem("token") === null);
  }
}
