import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bank-offer-details',
  templateUrl: './bank-offer-details.component.html',
  styleUrls: ['./bank-offer-details.component.scss']
})
export class BankOfferDetailsComponent implements OnInit {
  @Input() bankOfferData: any;
  @Input() termsConditions = [];
  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    console.log(this.bankOfferData);
    console.log(this.termsConditions);
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
