import { OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ErrorMessage } from 'src/app/core/constants/validation-msg-constants';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    ORDER_TRACK_URLS,
    MAP_ICONS,
    ORDER_STATUS_TO_DISPLAY,
    currentOrderStatusIndex,
    getConfig,
    KEYS_TO_SHOW_RIDER_DETAILS,
    ORDER_STATUS_LIST,
    ROUTES_STR,
    AGM_MAP_CUSTOM_STYLES
} from 'src/app/core/constants/app-constants';
import { LatLngBounds } from '@agm/core';
import { copyTextToClipboard } from 'src/app/shared/utils/common-functions';
import { AppData, ToastService } from 'src/app/shared';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { OrderService } from '../../service/order.service';
import { RateOrderModalComponent } from '../rate-order-modal/rate-order-modal.component';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { Subscription } from 'rxjs';

declare function init(event, outletLocation, userLocation): any;
declare var google: any;

export abstract class OrderTrackComponent implements OnInit, OnDestroy {
    @Input() modalObj: any;
    @Output() returnData: EventEmitter<any> = new EventEmitter();

    orderDetails;
    outletAndItems;
    orderStatusToDisplay = ORDER_STATUS_TO_DISPLAY;
    orderStatusIndex;
    orderStatusObj;
    mapEvent;
    orderStatusInterval;
    arrivalTime = 0;
    showRiderDetails = false;
    mapOptions = MAP_ICONS;
    paymentThrough = 'Paid via';
    orderId;
    customStyle = AGM_MAP_CUSTOM_STYLES;
    isMobileRes = false;
    isOrderCancelled = false;
    logOutSub = new Subscription();

    constructor(
        private orderService: OrderService,
        public activeModal: NgbActiveModal,
        private toast: ToastService,
        private router: Router,
        private modalService: NgbModal,
        private ar: ActivatedRoute
    ) { }

    ngOnInit() {
        this.ar.queryParams.subscribe((params) => {
            console.log(params);
            if (Object.keys(params).length > 0) {
                this.orderId = params.orderId;
            }
        });

        if (ApplicationStateService.isMobileResolution) {
            this.isMobileRes = true;
        }

        this.getOrderDetails();

        this.getOrderStatus();

        this.orderStatusInterval = setInterval(() => {
            this.getOrderStatus();
        }, 40000);

        this.logOutSub = AppData.userInfoSub$.subscribe((userInfo) => {
            if (userInfo && userInfo['isTemporary']) {
                this.router.navigate([ROUTES_STR.home]);
            }
        })

    }

    getOrderDetails() {
        const orderId = ApplicationStateService.isMobileResolution ? this.modalObj.orderId : this.orderId;

        this.orderService.getOrderDetails(orderId).then((res) => {
            this.orderDetails = res['body']['data'];
            this.outletAndItems = this.orderDetails.outlets[0];
            const filteredList = this.orderDetails.payments.filter(list => list.paid > 0);
            if (filteredList.length === 1) {
                this.paymentThrough = `${this.paymentThrough} ${(filteredList[0].method).toUpperCase()}`;
            }
            if (filteredList.length === 2) {
                this.paymentThrough = `${this.paymentThrough} ${(filteredList[0].method).toUpperCase()} and ${(filteredList[1].method).toUpperCase()}`
            }
        });
    }

    getOrderStatus() {
        const orderId = ApplicationStateService.isMobileResolution ? this.modalObj.orderId : this.orderId;

        this.orderService.getOrderStatus(orderId).then(async (res) => {
            const responseData = await res['body']['data'];
            this.orderStatusObj = responseData;
            if (responseData.delivery.runner && Object.keys(responseData.delivery.runner).length > 0) {
                const obj = {
                    lat: Number(responseData.delivery.runner.location.lat),
                    lng: Number(responseData.delivery.runner.location.lng)
                }
                this.orderStatusObj.delivery.runner.location = obj;
            }

            const statusObj = {
                orderStatus: responseData.orderStatus,
                deliveryStatus: responseData.deliveryStatus
            }

            this.orderStatusIndex = currentOrderStatusIndex(statusObj);
            if (this.orderStatusIndex === 2) {
                this.orderStatusToDisplay[1] = 'Food is ready';
            }

            // if (!this.orderPickedUp) {
            //   init(this.mapEvent, responseData.outletLocation, responseData.userLocation);
            // }

            this.riderDetails(responseData);

            if (responseData.deliveryStatus === 'delivered' || responseData.orderStatus === 'complete' || responseData.orderStatus === 'enforcedCancel' || responseData.orderStatus === 'rejected' || responseData.orderStatus === 'cancelled') {
                this.ngOnDestroy();

                let status = '';
                if (responseData.deliveryStatus === 'delivered') {
                    status = responseData.deliveryStatus;
                } else {
                    status = responseData.orderStatus;
                }

                let isRated = false;
                if (responseData.deliveryStatus === 'delivered' || responseData.orderStatus === 'complete') {
                    if (!this.orderDetails.rated && ApplicationStateService.isMobileResolution) {
                        const orderDetailsObj = {
                            orderId: this.orderDetails.orderId,
                            orderNum: this.orderDetails.orderNum,
                            outletName: this.orderDetails.outlets[0].outletName,
                            navigateToHome: ApplicationStateService.isMobileResolution ? this.modalObj.navigateToHome : ''
                        }
                        const modalRef = this.modalService.open(RateOrderModalComponent, getConfig('ADDON_VARIANT_CONFIG'));
                        modalRef.componentInstance.outletObj = orderDetailsObj;
                        modalRef.componentInstance.returnData.subscribe((res) => {
                            if (res['rated']) {
                                isRated = true;
                                returnDataObj['rating'] = res['foodRating'];
                            }
                        });
                    }
                }

                if (ORDER_STATUS_LIST.REJECTED_LIST.includes(responseData.orderStatus)) {
                    if (ApplicationStateService.isMobileResolution) {
                        this.modalService.dismissAll();
                        if (this.modalObj && this.modalObj.navigateToHome) {
                            this.router.navigate([ROUTES_STR.home]);
                        }
                    } else {
                        this.isOrderCancelled = true
                    }
                }

                let returnDataObj = {};
                if (ApplicationStateService.isMobileResolution) {

                    returnDataObj = {
                        orderNum: this.modalObj.orderNum,
                        status: status,
                        rated: isRated
                    }
                    this.returnData.emit(returnDataObj);
                }
            }

        });
    }

    riderDetails(responseData) {
        this.showRiderDetails = KEYS_TO_SHOW_RIDER_DETAILS.includes(responseData.deliveryStatus) ? true : false;

        let myMarkers = [];
        if (responseData.deliveryStatus === 'pickup_complete' || responseData.deliveryStatus === 'started_for_delivery' || responseData.deliveryStatus === 'reached_for_delivery') {
            myMarkers = [responseData.delivery.runner.location, responseData.userLocation];

        } else if (responseData.deliveryStatus === 'runner_accepted' || responseData.deliveryStatus === 'reached_for_pickup') {
            myMarkers = [responseData.outletLocation, responseData.userLocation, responseData.delivery.runner.location];

        } else {
            myMarkers = [responseData.outletLocation, responseData.userLocation,]
        }

        const bounds: LatLngBounds = new google.maps.LatLngBounds();
        myMarkers.forEach((marker) => {
            bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
        });

        setTimeout(() => {
            this.mapEvent.fitBounds(bounds);
            if (responseData.delivery.eta.pickup) {
                this.arrivalTime = Math.round(responseData.delivery.eta.pickup) + Math.round(responseData.delivery.eta.dropoff);
            } else {
                this.arrivalTime = Math.round(responseData.delivery.eta.dropoff);
            }
        }, 1000);
    }

    loadMap(event) {
        this.mapEvent = event;
    }

    bgColorFunc(orderStatus, deliveryStatus, orderIndex) {
        const paleRedClr = '#ffdce0';
        const greenColor = 'rgb(88, 216, 150, .24)';
        const greyClr = '#e6e6e6';
        const borderRed = '#fc6f7f';
        const borderGreen = '#58d896';
        const dottedDivOne = document.getElementById('dynamic-line-clr' + 0);
        const dottedDivTwo = document.getElementById('dynamic-line-clr' + 1);

        if (orderStatus === 'acceptanceWait' || orderStatus === 'valetOnWayToConfirm') {
            dottedDivOne.style.borderLeft = `2px dashed ${borderRed}`;
            dottedDivTwo.style.borderLeft = `2px dashed ${greyClr}`;
        } else if (orderStatus === 'foodReady' || orderStatus === 'foodPreparation') {
            // dottedDivOne.style.borderLeft = `2px dashed ${borderGreen}`;
            dottedDivOne.setAttribute('style', `border-left: 2px dashed ${borderGreen};height: 100%`);
            dottedDivTwo.style.borderLeft = `2px dashed ${borderRed}`;
        }

        if (deliveryStatus === 'pickup_complete' || deliveryStatus === 'started_for_delivery') {
            dottedDivOne.style.borderLeft = `2px dashed ${borderGreen}`;
            dottedDivTwo.style.borderLeft = `2px dashed ${borderRed}`;
        } else if (deliveryStatus === 'reached_for_delivery' || deliveryStatus === 'delivered') {
            // dottedDivOne.style.borderLeft = `2px dashed ${borderGreen}`;
            dottedDivOne.setAttribute('style', `border-left: 2px dashed ${borderGreen};height: 100%`);
            // dottedDivTwo.style.borderLeft = `2px dashed ${borderGreen}`;
            dottedDivTwo.setAttribute('style', `border-left: 2px dashed ${borderGreen};height: 100%`);
        }

        const acceptanceStatus = 'acceptanceWait';
        const waitingStatus = ['valetOnWayToConfirm', 'foodPreparation', 'foodReady', 'waitingDeliveryPickup'];
        const pickUpStatus = ['outForDelivery', 'pickup_complete', 'started_for_delivery'];
        const reachedForDeliveryStatus = 'reached_for_delivery';
        const deliveredStatus = ['delivered', 'complete'];

        const index = [ // returns values as per index passed
            {
                bgColor: orderStatus === acceptanceStatus ? paleRedClr : greenColor,
                image: orderStatus === acceptanceStatus ?
                    ORDER_TRACK_URLS.ORDER_STATUS_URLS.orderReceivedRed : ORDER_TRACK_URLS.ORDER_STATUS_URLS.orderReceivedGreen
            },
            {
                bgColor: (deliveredStatus.includes(deliveryStatus) || deliveryStatus === reachedForDeliveryStatus) ? greenColor : (pickUpStatus.includes(deliveryStatus) ? paleRedClr : (waitingStatus.includes(orderStatus) ? paleRedClr : greyClr)),
                image: (deliveredStatus.includes(deliveryStatus) || deliveryStatus === reachedForDeliveryStatus) ? ORDER_TRACK_URLS.ORDER_STATUS_URLS.foodOnTheWayGreen : pickUpStatus.includes(deliveryStatus) ? ORDER_TRACK_URLS.ORDER_STATUS_URLS.foodOnTheWayRed :
                    (waitingStatus.includes(orderStatus) ? ORDER_TRACK_URLS.ORDER_STATUS_URLS.foodReadyRed : ORDER_TRACK_URLS.ORDER_STATUS_URLS.foodReadyGrey)
            },
            {
                bgColor: deliveredStatus.includes(deliveryStatus) ? greenColor : deliveryStatus === reachedForDeliveryStatus ? paleRedClr : greyClr,
                image: deliveredStatus.includes(deliveryStatus) ? ORDER_TRACK_URLS.ORDER_STATUS_URLS.orderPickedUpGreen : (deliveryStatus === reachedForDeliveryStatus ? ORDER_TRACK_URLS.ORDER_STATUS_URLS.orderPickedUpRed : ORDER_TRACK_URLS.ORDER_STATUS_URLS.orderPickedUpGrey)
            }
        ];

        return index[orderIndex];
    }

    copyRiderMblNum(mobileNum) {
        copyTextToClipboard(mobileNum).then(() => {
            this.toast.presentToast(ErrorMessage.MOB_NUM_COPY_MSG, '', 'success');
        }).catch(() => {
            this.toast.presentToast(ErrorMessage.FAILED_TO_COPY_MSG, '', 'success');
        });
    }

    openHelpPage() {
        this.activeModal.close();
        const obj = {
            outletName: this.outletAndItems.outletName,
            orderNum: this.orderDetails.orderNum,
            openTrackPage: true,
            orderId: this.orderDetails.orderId
        }
        const navigationExtras: NavigationExtras = {
            queryParams: obj
        }
        this.router.navigate(["/help-faqs/help-and-support"], navigationExtras);
    }

    closeModal() {
        this.activeModal.close();
        if (this.modalObj.navigateToHome) {
            this.router.navigate([ROUTES_STR.home]);
        }
    }

    showDirection(status) {
        const deliveryStatus = ['pickup_complete', 'started_for_delivery', 'reached_for_delivery'];
        if (deliveryStatus.includes(status)) {
            return true;
        }
        return false;
    }

    navigateToRewards() {
        this.activeModal.close();
        const obj = {
            from: '/order-history',
            orderId: this.orderDetails.orderId
        }
        const navigationExtras: NavigationExtras = {
            queryParams: obj
        };

        this.router.navigate(['/earned-scratch-cards'], navigationExtras);
    }

    ngOnDestroy() {
        clearInterval(this.orderStatusInterval);
        this.logOutSub.unsubscribe();
    }

}