import { IdsService } from './../../shared/services/ids.service';
import { CleverTapEventProperty } from './../../shared/utils/enums/clever-tap-event-property.enum';
import { NotificationService } from './../../shared/services/notification.service';
import { AppData } from 'src/app/shared/services/app-data.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Component, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter, AfterContentInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { COUNTER, SECONDS, COUNTRY_CODE, getConfig } from 'src/app/core/constants/app-constants';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../modals/login-signup-modal/service/login.service';
import { getFormValidation, maxNumToBeAllowed } from 'src/app/shared/utils/common-functions';
import { HttpResponse } from '@angular/common/http';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { CLEVER_TAB_EVENT } from 'src/app/core/constants/clever-tab-event-constants';
import { sendOtpToUser, userSIgnUpEvent } from 'src/app/shared/utils/clever-tab-functions';
declare let clevertap: any;


export abstract class OtpComponent implements OnInit, OnDestroy, AfterViewInit {
  cleverTapEventProperty = CleverTapEventProperty;
  otpForm: FormGroup;
  isSubmitted = false;
  counter = COUNTER;
  interval: any;
  showResend = false;
  seconds = SECONDS;
  routeObj;
  mobileNum: any;
  titleTxt = "";
  navUrl = "/login";
  @Input() modalObj: any;
  @Output() onChangeMobileNumber: EventEmitter<boolean> = new EventEmitter;
  otpInput: string;
  showOtpComponent = true;
  isOtpValid = true;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  navigateToCart = 'N';

  constructor(private fb: FormBuilder,
    private loginService: LoginService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private idsService: IdsService,
    private activeModal: NgbActiveModal,
    private locationService: LocationService,
    private analyticsService: AnalyticsService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.routeObj = params;
      // this.modalObj = params;
    });
    this.loginService.init(this.onCloseLoginModal.bind(this))
  }

  ngOnInit() {
    console.log(this.routeObj);
    console.log(this.modalObj);

    if (this.modalObj) {
      if (this.modalObj.type === 'LOGIN') {
        this.mobileNum = String(this.modalObj.mobile);
      } else if (this.modalObj.type === 'SIGNUP') {
        this.mobileNum = String(this.modalObj.mobile.value);
      }
    }

    this.seconds = 30;
    this.startTimer();
    this.initForm();
  }

  ngAfterViewInit() {
    const doc = document.getElementById('otp-input');
    console.log(doc);
    if (doc) {
      doc.getElementsByTagName('input')[0].focus();
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      console.log(params);
      if (params && params['navigateToCart'] === 'Y') {
        this.navigateToCart = 'Y';
      }
    })
  }

  onCloseLoginModal() {
    this.modalService.dismissAll();
  }

  initForm() {
    this.otpForm = this.fb.group({
      'otp': [this.otpInput, [Validators.required, Validators.min(1000), Validators.max(9999)]]
    });
  }

  get otpControl() { return this.otpForm.controls; }

  getOTPValid(fieldName) {
    return getFormValidation(this.otpControl, fieldName, this.isSubmitted);
  }

  onOtpChange(otp) {
    this.otpInput = otp;
    this.otpForm.controls.otp.setValue(otp);
    
    if (this.otpInput.length === 4 && !this.isSubmitted) {
      this.isSubmitted = true;
      this.onVerifyOTP();
    } else if (this.otpInput.length > 0) {
      this.isOtpValid = true;
    } else {
      this.isOtpValid = false;
    }
  }

  leadingZero(num: number, size = 2) {
    let n = num.toString();
    while (n.length < size) n = "0" + n;
    return n;
  }

  //create api request object for login and sign up
  createObjForLoginAndSignUp() {
    const body = {
      mobile: this.routeObj.mobile,
      otp: this.otpForm.controls['otp'].value,
      countryCode: COUNTRY_CODE,
      gcmToken: AppData.fcmToken
      // cartId: AppData.cartId,
    }

    if (this.modalObj) {
      if (this.modalObj.type === 'LOGIN') {
        body['mobile'] = String(this.modalObj.mobile);
        this.mobileNum = String(this.modalObj.mobile);
      } else if (this.modalObj.type === 'SIGNUP') {
        body['mobile'] = String(this.modalObj.mobile.value);
        this.mobileNum = String(this.modalObj.mobile.value);
      }

      body['otp'] = this.otpInput
    }
    console.log(this.mobileNum);

    return body;
  }

  onVerifyOTP() {
    if (ApplicationStateService.isMobileResolution) {
      // this.isSubmitted = true;
      console.log(this.otpForm);
      if (this.otpForm.valid) {
        if (this.routeObj.type === 'LOGIN') { // declaration for mobile view and desktop voew
          this.onLogin();
        } else {
          this.onSignUp();
        }
      } else {
        return;
      }
    } else {
      if (this.otpInput.length === 4) {
        if (this.modalObj && this.modalObj.type === 'LOGIN') {
          this.onLogin();
        } else {
          this.onSignUp();
        }
      } else {
        this.isOtpValid = false;
      }
    }
  }


  // for loin
  async onLogin() {
    const reqBody = await this.createObjForLoginAndSignUp();
    reqBody['deviceId'] = 'web-' + AppData.deviceId;
    this.loginService.verifyOtp(reqBody).then((res: HttpResponse<any>) => {
      this.activeModal.close();
      const responseData = res.body.data;
      const userInfoData = responseData && responseData.user;
      console.log(responseData);
      responseData['navigateToCart'] = this.navigateToCart;
      this.loginService.saveUserDataInLocal(responseData);
      this.notificationService.sendFCMTokenToServer(AppData.fcmToken);
      this.analyticsService.cleverTapEvent(CLEVER_TAB_EVENT.SEND_OTP_TO_USER, sendOtpToUser())
      this.loginSignUpCleverTapEventObj(userInfoData);
     
    }, (error => {
      console.log(error);
      this.isOtpValid = false;
    }));

  }

  onBack() {
    this.router.navigate(['login']);
  }

  // for sign up
  async onSignUp() {
    const reqBody = await this.createObjForLoginAndSignUp();
    if (ApplicationStateService.isMobileResolution) {
      reqBody['firstName'] = this.routeObj.firstName;
      reqBody['lastName'] = this.routeObj.lastName;
      reqBody['email'] = this.routeObj.email;
    } else {
      reqBody['firstName'] = this.modalObj.firstName.value;
      reqBody['lastName'] = this.modalObj.lastName.value;
      reqBody['email'] = this.modalObj.email.value;
      reqBody['referralCode'] = this.modalObj.referralCode;
    }
    reqBody['gcmToken'] = AppData.fcmToken;
    reqBody['deviceId'] = 'web-' + this.idsService.generate();
    this.loginService.signUp(reqBody).then((res: HttpResponse<any>) => {
      const responseData = res.body.data;
      console.log(responseData);
      // const userInfoData = responseData['user'];
      this.loginService.saveUserDataInLocal(responseData);
      this.analyticsService.cleverTapEvent(CLEVER_TAB_EVENT.USER_SIGNUP_EVENT, userSIgnUpEvent())
      this.notificationService.sendFCMTokenToServer(AppData.fcmToken);
      AppData.deviceIdSub$.next(reqBody['deviceId']);
      this.locationService.setAndUpdateLocation();
      // this.loginSignUpCleverTapEventObj(userInfoData);
      // this.signUpCleverTapEventObj(userInfoData);
      this.activeModal.close();

      const referralReqBody = {
        referralCode: this.routeObj['referralCode'] || reqBody['referralCode']
      }
      if (referralReqBody.referralCode || reqBody['referralCode']) {
        this.loginService.addReferralCode(referralReqBody);
      }

    }, (error => {
    }));
  }

  signUpCleverTapEventObj(userInfoData) {
    clevertap.profile.push({
      "Site": {
        "Name": userInfoData.name + ' ' + userInfoData.lastName,
        "Identity": userInfoData.userId,
        "Email": userInfoData.email,
        "Phone": '+91' + userInfoData.mobile,
        "Photo": userInfoData.picture ? userInfoData.picture : '',

        // optional fields. controls whether the user will be sent email, push etc.
        "MSG-email": true,   // Disable email notifications
        "MSG-push": true,    // Enable push notifications
        "MSG-sms": true,     // Enable sms notifications
        "MSG-whatsapp": true, // Enable whatsapp notifications
      }
    });
  }

  loginSignUpCleverTapEventObj(userInfoData) {
    const lat = userInfoData.currentLocation ? userInfoData.currentLocation.coordinates[1] : 0.0;
    const long = userInfoData.currentLocation ? userInfoData.currentLocation.coordinates[0] : 0.0;
    clevertap.onUserLogin.push({
      "Site": {
        "Name": userInfoData.name + ' ' + userInfoData.lastName,
        "Identity": userInfoData.userId,
        "Email": userInfoData.email,
        "Phone": '+91' + userInfoData.mobile,
        "Photo": userInfoData.picture ? userInfoData.picture : '',
        "CT Latitude": lat,
        "CT Longitude": long,
        "Lat": lat,
        "Long": long,
        "Location": userInfoData.currentLocality,
        // optional fields. controls whether the user will be sent email, push etc.
        "MSG-email": true,                      // Disable email notifications
        "MSG-push": true,                        // Enable push notifications
        "MSG-sms": true,                       // Enable sms notifications
        "MSG-whatsapp": true,                    // Enable whatsapp notifications
      }
    });
  }

  // create object for resend otp
  createObjForResendOTP() {
    const body = {
      countryCode: COUNTRY_CODE,
      mobile: ApplicationStateService.isMobileResolution ? String(this.routeObj.mobile) : this.mobileNum,
      action: this.routeObj.type === 'LOGIN' ? 'login' : 'signup',
      method: 'resendOtp',
      type: 'text',
    };
    if (!ApplicationStateService.isMobileResolution) {
      body['action'] = this.modalObj.type === 'LOGIN' ? 'login' : 'signup'
    }
    console.log('resend OTP: ', body);
    return body;
  }
  // for call api for resend otp
  async onResendOTP() {
    this.loginService.reSendOtp(await this.createObjForResendOTP()).then((res) => {
      this.seconds = 30;
      if (this.counter >= 0) {
        this.showResend = false;
        this.startTimer();
        this.counter--;
      }
    });
  }

  // Timer function for resend the OTP
  startTimer() {
    this.interval = setInterval(() => {
      if (this.seconds > 1) {
        this.showResend = false;
        this.seconds--;
      } else {
        this.showResend = true;
        clearInterval(this.interval);
        if (this.counter === 0) {
          this.showResend = false;
          this.counter = -1;
        }
      }
    }, 1000);
  }

  onChangeMobile() {
    if (ApplicationStateService.isMobileResolution) {
      if (this.routeObj.type === 'LOGIN') {
        this.router.navigate(['login'], { queryParams: { mobile: this.routeObj.mobile } });
      } else {
        const navigationExtras: NavigationExtras = {
          queryParams: {
            email: this.routeObj.email,
            mobile: this.routeObj.mobile,
            firstName: this.routeObj.firstName,
            lastName: this.routeObj.lastName,
            type: 'SIGN_UP',
            userName: this.routeObj.userName,
          }
        };
        this.router.navigate(['sign-up'], navigationExtras);
      }
    } else {
      // this.activeModal.close();
      // if (this.modalObj.type === 'LOGIN') {
      this.onChangeMobileNumber.emit(true);
      // this.modalService.open(LoginDesktopComponent, getConfig('LOGIN_DESKTOP_CONFIG'))
      // }
    }
  }

  onKeyPress(event) {
    if (event.keyCode === 13) {
      this.onVerifyOTP();
    }
    return maxNumToBeAllowed(event, 4);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }


  config = {
    allowNumbersOnly: true,
    length: 4,
    disableAutoFocus: false,
    inputStyles: {
      'width': '50px',
      'height': '50px',
      'background': 'var(--white) 0% 0% no-repeat padding-box',
      'border': '1px solid var(--silver)',
      'border-radius': '4px',
      'font-size': '1.2rem',
      'text-align': 'center',
      'color': 'var(--home-layout-title-clr)',
      'outline': '0',
      'margin': '12px',
    }
  };
  // onOtpChange(otp) {
  //   this.otpInput = otp;
  //   console.log(this.otpInput);
  //   if (this.otpInput.length === 4) {
  //     this.onVerifyOTP();
  //   }
  // }

}
