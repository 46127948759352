export const ErrorMessage = {
    PLEASE_ENTER_OTP_MSG: 'Please enter the OTP',
    LOCATION_PERMISSION_ALERT_MSG: 'Allow Access to your Location! Please change your Location Settings in the Browser.',
    PLEASE_SELECT_ADDRESS_TAG_MSG: 'Please select an Address',
    MAX_FILE_SIZE_MSG: 'Maximum 2 MB File allowed',
    ALLOW_FILE_TYPE_MSG: 'Only JPG and PNG files allowed',
    SELECT_FOOD_FILTER_MSG: 'Please select a food filter',
    ERROR_OCCURED: 'Something is wrong, please try again',
    SELECT_MIN_TWO_CUISINE_MSG: 'Please select at least 2 cuisines!',
    ADDON_SELECTION_REQ_MEG: 'Add-On Selection is required',
    ADDONS_SELECTION_REQ_MEG: 'Add-Ons Selection is required',
    VARIANT_SELECTION_REQ_MEG: 'Select a Variant',
    FAILED_TO_COPY_MSG: 'Failed to copy',
    MOB_NUM_COPY_MSG: 'Mobile Number copied',
    PAYMENT_IS_FAILED_MSG: 'Payment has failed.',
    CODE_COPIED_MSG: 'Code copied to clipboard'
}