import { Injectable } from '@angular/core';

@Injectable({
    providedIn: "root",
})
export class CartCalService {

    constructor() { }

    calculateDiscount(offer, price) {
        let discount = 0;
        if (offer.discountType === 'net') {
            discount = offer.value;
        } else {
            discount = price - (price * offer.value) / 100;
            if (discount >= offer.maxDiscount) {
                discount = offer.maxDiscount;
            }
        }
        return Number(discount);
    }

    calculatePercentage(amount, percentage) {
        return (amount * percentage) / 100;
    }

    reduceCostFromOptions = options => {
        if (options) {
            let cost = 0;
            if (options.offerPrice) {
                cost = options.offerPrice;
            } else {
                cost = options.cost;
            }
            return cost;
        }
    };

    reduceAndMapFromCharges = (chargesArr, chargesMap) => {
        return chargesArr.reduce((acc, charge) => {
            if (!chargesMap[charge.name]) chargesMap[charge.name] = 0;
            chargesMap[charge.name] += charge.value;
            return acc + charge.value;
        }, 0);
    };

    reduceAddons = addons => {
        let addonsCost = 0;
        addons.forEach((addon) => {
            addon.options.forEach(option => {
                addonsCost += this.reduceCostFromOptions(option)
            });
        }
        );
        return addonsCost;
    };

    computeItemsValue(items, charges) {
        let itemDiscount = 0;
        let itemSubTotal = 0;
        let itemTotalPrice = 0;
        for (const outletItem of items) {
            let itemPrice = outletItem.variant
                ? this.reduceCostFromOptions(outletItem.variant.options)
                : outletItem.price;
            itemPrice *= Number(outletItem.quantity);
            if (outletItem.offer) {
                const priceDiscount = this.calculateDiscount(outletItem.offer, itemPrice);
                outletItem.offerPrice = itemPrice - priceDiscount;
                itemDiscount += Number(priceDiscount);
            } else {
                if (itemPrice) {
                    outletItem.offerPrice = itemPrice;
                } else {
                    if (!outletItem.offerPrice) {
                        outletItem.offerPrice = outletItem.price;
                    }
                }
            }
            // calculate addons
            let totalAddonCharges = 0;
            if (outletItem.addons && outletItem.addons.length > 0) {
                totalAddonCharges = this.reduceAddons(outletItem.addons);
            }
            // calculate charges like packaging etc
            const totalAdditionalCharges = this.reduceAndMapFromCharges(
                outletItem.additionalCharges,
                charges,
            );
            const thisItemSubTotal =
                outletItem.offerPrice + totalAdditionalCharges + totalAddonCharges;
            itemSubTotal += thisItemSubTotal;
            itemTotalPrice += outletItem.offerPrice + totalAddonCharges;
            outletItem.calItemPrice = outletItem.offerPrice + totalAddonCharges;
        }
        return { itemTotalPrice, itemDiscount, itemSubTotal };
    }


    rebuild(cartDoc) {
        const orderBilling = {
            subTotal: 0,
            discount: 0,
            roundOff: 0,
            totalAmount: 0,
            loyaltyUsed: 0,
            itemTotal: 0
        };
        const orderCharges = {};
        const orderTaxes = { gst: 0 };
        for (const outlet of cartDoc.outlets) {
            let subTotal = 0;
            let discount = 0;
            const charges = {};
            let itemTotal = 0;
            // compute items
            const { itemTotalPrice, itemDiscount, itemSubTotal } = this.computeItemsValue(
                outlet.items,
                charges,
            );
            discount += itemDiscount;
            subTotal += itemSubTotal;
            itemTotal += itemTotalPrice;
            // calculate outlet discount
            let totalAmount = subTotal;
            if (outlet.offer && totalAmount >= outlet.offer.minOrderAmount) {
                const outletOfferDisc = this.calculateDiscount(outlet.offer, totalAmount);
                discount += outletOfferDisc;
                totalAmount -= outletOfferDisc;
            }
            let gstAmount = 0;
            if (outlet.gst) {
                gstAmount = this.calculatePercentage(totalAmount, outlet.gst);
                totalAmount += gstAmount;
            }
            if (outlet.additionalCharges) {
                const outletAddCharges = this.reduceAndMapFromCharges(
                    outlet.additionalCharges,
                    charges,
                );
                totalAmount += outletAddCharges;
            }
            outlet.billing = {
                totalAmount,
                discount,
                subTotal,
                itemTotal,
                charges: Object.keys(charges).map(chg => ({
                    name: chg,
                    value: charges[chg],
                })),
                taxes: [{ name: 'gst', value: gstAmount }],
            };
            // update order level billing
            orderBilling.totalAmount += totalAmount;
            orderBilling.subTotal += subTotal;
            orderBilling.discount += discount;
            orderBilling.itemTotal += itemTotal;
            // marge and update charge objects
            outlet.billing.charges.forEach(charge => {
                if (!orderCharges[charge.name]) orderCharges[charge.name] = 0;
                orderCharges[charge.name] += charge.value;
            });
            orderTaxes.gst += gstAmount;
        }
        cartDoc.billing = {
            ...orderBilling,
            charges: Object.keys(orderCharges).map(chg => ({
                name: chg,
                value: orderCharges[chg],
            })),
            taxes: [{ name: 'gst', value: orderTaxes.gst }],
        };
        delete cartDoc.addons;
        return cartDoc;
    }
}