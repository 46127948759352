import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  signUpSub$ = new BehaviorSubject("");

  saveSignUpDetails(data) {
    this.signUpSub$.next(data);
  }

  constructor() { }
}
