import { AppData } from '..';
import { makeAddonsStr } from './common-functions';
import { ErrorCode } from './enums/error-code.enum';

declare let clevertap: any;

export function cleverTapEvent(eventName, eventPropertiesObj) {
    clevertap.event.push(eventName, eventPropertiesObj);
}

export function loginSingUpCleverTapEvent(eventPropertiesObj) {
    clevertap.onUserLogin.push(eventPropertiesObj);
}

export function appStartedCleverTabObj(event, errorResponse?) {
    const body = {
        eventName: event,
        // start_code: errorResponse.code,
        // message: errorResponse.message,
    }

    return Object.assign(body, localityAndCityCleverTabObj());
}

export function localityAndCityCleverTabObj() {
    const body = {
        // locality: AppData.currentAddressObj.locality ? AppData.currentAddressObj.locality : ,
        userId: AppData.userId,
        userCity: AppData.currentAddressObj.city,
        userLocality: AppData.currentAddressObj.locality,
        providerName: "yumzyx",
        appName: "yumzy-web",
        coordinates: AppData.currentAddressObj.longLat.coordinates,

    }
    // if (AppData.currentAddressObj.locality) {
    //     body['userLocality'] = AppData.currentAddressObj.locality;
    // } else if (AppData.currentAddressObj.googleLocation) {
    //     body['userLocality'] = AppData.currentAddressObj.googleLocation;
    // } else {
    //     body['userLocality'] = AppData.currentAddressObj.city;
    // }
    return body;
}

export function outletViewedCleverTapObj(obj) {
    const body = {
        outlet_name: obj.outletName,
        outlet_id: obj.outletId,
        outlet_open: obj.available,
        userId: AppData.userId,
        userCity: AppData.currentAddressObj.city,
        userLocality: AppData.currentAddressObj.locality,
        outletCity: AppData.currentAddressObj ? AppData.currentAddressObj.city : ''


    }

    return Object.assign(body, localityAndCityCleverTabObj());;

}

export function addItemInCartCleverTapObj(obj, offerPrice, locality) {
    console.log(AppData.yourPlateItems);
    const body = {
        outletId: obj.outletId,
        outletName: obj.outletName,
        itemId: obj.itemId,
        itemName: obj.name,
        outletLocality: locality,
        itemDiscount: offerPrice === 0 ? obj.price : offerPrice,
        itemPrice: obj.price,
        cartId: AppData.cartId,
        outletCity: AppData.currentAddressObj ? AppData.currentAddressObj.city : '',
        categoryId: obj.category,
        subCategoryId:obj.subCategory


        // quantity: quantity,
        // addon: obj.addons.length > 0 ? makeAddonsStr(obj.addons) : '',
        // variant: obj.variant ? obj.variant.options.optionName : ''
    }

    console.log(body, localityAndCityCleverTabObj())
    console.log(body.outletLocality);

    return Object.assign(body, localityAndCityCleverTabObj());
}

export function removeItemInCartCleverTapObj(obj, offerPrice, quantity, outletId) {
    const body = {
        outletId: obj.outletId,
        outletName: obj.outletName,
        itemId: obj.itemId,
        itemName: obj.name,
        itemPrice: obj.price,
        orderId: outletId,
        outletLocality: AppData.yourPlateItems.outlets[0].locality,
        cartId: AppData.cartId,
        outletCity: AppData.currentAddressObj ? AppData.currentAddressObj.city : '',
        categoryId: obj.category,
        subCategoryId:obj.subCategory

    }
    console.log(body, localityAndCityCleverTabObj())

    return Object.assign(body, localityAndCityCleverTabObj());
}


export function userInfoCleverTapEventObj() {
    const body = {
        user_id: AppData.userId,
        user_name: AppData.userInfoData.name + ' ' + AppData.userInfoData.lastName
    }
    return Object.assign(body, localityAndCityCleverTabObj());
}


export function paymentSuccessObj(orderId, outletId) {
    const body = {
        orderId: orderId,
        outletId: outletId,
        timestamp: new Date().toISOString(),
        outletCity: AppData.currentAddressObj ? AppData.currentAddressObj.city : ''

    }
    console.log(body, localityAndCityCleverTabObj());

    return Object.assign(body, localityAndCityCleverTabObj());
}

export function paymentFailedObj(orderId, outletId) {
    const body = {
        orderId: orderId,
        outletId: outletId,
        timestamp: new Date().toISOString(),
        outletCity: AppData.currentAddressObj ? AppData.currentAddressObj.city : ''
    }
    return Object.assign(body, localityAndCityCleverTabObj());
}

export function outletViewedObj(outletObj) {
    const body = {
        outletId: outletObj.outletId,
        outletName: outletObj.outletName,
        outletLocality: outletObj.locality,
        outletCity: AppData.currentAddressObj ? AppData.currentAddressObj.city : '',
        timestamp: new Date().toISOString(),

    }
    console.log(body, localityAndCityCleverTabObj())
    return Object.assign(body, localityAndCityCleverTabObj());
}


export function couponAppliedCleverTapEventObj(billingObj, preCheckoutData) {
    const body = {
        coupon_code: preCheckoutData['cartDoc']['coupons'].length > 0 ? preCheckoutData['cartDoc']['coupons'][0]['code'] : '',
        coupon_amount: preCheckoutData['cartDoc']['coupons'].length > 0 ? preCheckoutData['cartDoc']['coupons'][0]['value'] : '',
        amount_saved: preCheckoutData['cartDoc']['coupons'].length > 0 ? preCheckoutData['cartDoc']['coupons'][0]['couponDiscount'] : '',
        applied_on_amount: '',
        outlet_id: preCheckoutData['cartDoc']['outlets'][0]['outletId'],
        cart_value: billingObj.totalAmount,
        coupon_type: preCheckoutData['cartDoc']['coupons'].length > 0 ? preCheckoutData['cartDoc']['coupons'][0]['discountType'] : '',
    }
    return Object.assign(body, localityAndCityCleverTabObj());
}

export function removeCouponCleverTapEventObj(preCheckoutData) {
    const body = {
        coupon_code: preCheckoutData['cartDoc']['coupons'].length > 0 ? preCheckoutData['cartDoc']['coupons'][0]['code'] : '',
        coupon_amount: preCheckoutData['cartDoc']['coupons'].length > 0 ? preCheckoutData['cartDoc']['coupons'][0]['value'] : '',
    }
    return Object.assign(body, localityAndCityCleverTabObj());
}

export function unableToCheckoutCleverTapEventObj(outletId, errorMsg, errorCode) {
    const body = {
        outlet_id: outletId,
        cart_id: AppData.cartId,
        failure_reason: errorMsg,
        failure_code: errorCode
    }
    return Object.assign(body, localityAndCityCleverTabObj());
}

export function paymentSuccessFailedCleverTapEvent(orderId, outletId) {
    const body = {
        orderId: orderId,
        outletId: outletId,
        cartId: AppData.cartId,
    }
    console.log(body, localityAndCityCleverTabObj())
    return Object.assign(body, localityAndCityCleverTabObj());
}

export function clearCartCleverTapObj() {
    console.log(AppData.userInfoData);
    const body = {
        outletId: AppData.yourPlateItems['outlets'][0]['outletId'],
        outletName: AppData.yourPlateItems['outlets'][0]['outletName'],
        outletLocality: AppData.yourPlateItems['outlets'][0]['locality'],
        cartId: AppData.cartId,
        outletCity: AppData.currentAddressObj ? AppData.currentAddressObj.city : '',
    }
    console.log(body)
    return Object.assign(body, localityAndCityCleverTabObj());
}

export function paymentInitiatedCleverTapEventObj(billingObj, preCheckoutData) {
    const body = {
        outlet_name: preCheckoutData['cartDoc']['outlets'][0]['outletName'],
        outlet_id: preCheckoutData['cartDoc']['outlets'][0]['outletId'],
        no_of_items: preCheckoutData['cartDoc']['outlets'][0]['items'].length,
        discount: billingObj.discount,
        delivery_price: preCheckoutData['deliveryInfo']['deliveryCharge'],
        savings: billingObj.discount,
        total_price: billingObj.totalAmount,
        coupon_applied: preCheckoutData['cartDoc']['coupons'].length > 0 ? 'Y' : 'N',
        coupon_amount: preCheckoutData['cartDoc']['coupons'].length > 0 ? preCheckoutData['cartDoc']['coupons'][0]['value'] : '',
        cart_id: preCheckoutData['cartDoc']['cartId'],
        coupon_type: preCheckoutData['cartDoc']['coupons'].length > 0 ? preCheckoutData['cartDoc']['coupons'][0]['discountType'] : '',
        gst: billingObj['taxes'][0]['value']
    }
    return Object.assign(body, localityAndCityCleverTabObj());
}

export function seeMoreCleverTapEventObj(obj) {
    const body = {
        clicked_banner: obj.heading,
        layout_id: obj.layoutType,
        promo_id: obj['list'][0]['promoId']
    }

    return Object.assign(body, localityAndCityCleverTabObj());
}

export function promoClickCleverTapEvent(obj) {
    const body = {
        layoutName: obj.heading,
        layoutId: obj.layoutType,
        promoId: obj.promoId,
        // layoutItemName: obj.title,
    }

    return Object.assign(body, localityAndCityCleverTabObj());
}

export function appExitedObj() {
    const body = {
    }
    return Object.assign(body, localityAndCityCleverTabObj());

}

export function topBannerClickObj(promoId) {
    const body = {
        promoId: promoId
    }
    return Object.assign(body, localityAndCityCleverTabObj());
}

// export function userChangeLocation() {
//     const body = {
//         cartId: AppData.cartId,
//         outletId: AppData.selectedOutlet.outletId,
//         outletLocality: AppData.selectedOutlet.locality,
//         outletName: AppData.selectedOutlet.outletName
//     }
//     return Object.assign(body, localityAndCityCleverTabObj())
// }

export function sendOtpToUser() {
    const body = {
        // outletId: AppData.selectedOutlet.outletId,
        // outletLocality: AppData.selectedOutlet.locality,
        // outletName: AppData.selectedOutlet.outletName   
    }
    return Object.assign(body, localityAndCityCleverTabObj())
}
export function applyCouponEvent(obj) {
    const body = {
        outletId: obj.outletId,
        outletLocality: obj.locality,
        outletName: obj.outletName,
        coordinates: obj.location.coordinates,
        cartId: AppData.cartId,
    }
    return Object.assign(body, localityAndCityCleverTabObj())
}

export function checkoutClicked(obj) {
    console.log(obj);
    
    const body = {
        outletId: obj[0].outletId,
        outletLocality: obj[0].locality,
        outletName: obj[0].outletName,
        coordinates: obj[0].location.coordinates,
        cartId: AppData.cartId,
    }
    return Object.assign(body, localityAndCityCleverTabObj())
}
export function cartClickedevent() {
    const body = {
        cartId: AppData.cartId,
        outletId: AppData.yourPlateItems['outlets'][0]['outletId'],
        outletLocality: AppData.yourPlateItems['outlets'][0]['locality'],
        outletName: AppData.yourPlateItems['outlets'][0]['outletName'],
    }
    return Object.assign(body, localityAndCityCleverTabObj())
}

export function payClickedEvent(obj) {
    const body = {
        cartId: AppData.cartId,
        outletId: obj['cartDoc']['outlets'][0]['outletId'],
        outletLocality: obj['cartDoc']['outlets'][0]['locality'],
        outletName: obj['cartDoc']['outlets'][0]['outletName'],
    }

    return Object.assign(body, localityAndCityCleverTabObj())

}

// export function letExploreEvent() {
//     const body = {

//     }
//     return Object.assign(localityAndCityCleverTabObj())
// }

export function vegToggleEvent(obj) {
    const body = {
        outletId: obj.outletId,
        outletLocality: obj.locality,
        outletName: obj.outletName
    }
    return Object.assign(body, localityAndCityCleverTabObj())
}


export function eggToggleEvent(obj) {
    const body = {
        outletId: obj.outletId,
        outletLocality: obj.locality,
        outletName: obj.outletName
    }
    return Object.assign(body, localityAndCityCleverTabObj())
}


// export function changeOutletEvent() {
//     const body = {
//         outletId: AppData.selectedOutlet.outletId,
//         outletLocality: AppData.selectedOutlet.locality,
//         outletName: AppData.selectedOutlet.outletName
//     }
//     return Object.assign(body, localityAndCityCleverTabObj())
// }


export function profileClickedEvent() {
    const body = {
        // outletId: AppData.selectedOutlet.outletId,
        // outletLocality: AppData.selectedOutlet.locality,
        // outletName: AppData.selectedOutlet.outletName
    }
    return Object.assign(body, localityAndCityCleverTabObj())
}


export function manageAddressEvent() {
    const body = {
        // outletId: AppData.selectedOutlet.outletId,
        // outletLocality: AppData.selectedOutlet.locality,
        // outletName: AppData.selectedOutlet.outletName
    }
    return Object.assign(body, localityAndCityCleverTabObj())
}

export function userWalletEvent() {
    const body = {
        // outletId: AppData.selectedOutlet.outletId,
        // outletLocality: AppData.selectedOutlet.locality,
        // outletName: AppData.selectedOutlet.outletName
    }
    return Object.assign(body, localityAndCityCleverTabObj())

}

export function orderHistoryEvent() {
    const body = {
        // outletId: AppData.selectedOutlet.outletId,
        // outletLocality: AppData.selectedOutlet.locality,
        // outletName: AppData.selectedOutlet.outletName
    }
    return Object.assign(body, localityAndCityCleverTabObj())
}
export function myRewardsEvent() {
    const body = {
        // outletId: AppData.selectedOutlet.outletId,
        // outletLocality: AppData.selectedOutlet.locality,
        // outletName: AppData.selectedOutlet.outletName
    }
    return Object.assign(body, localityAndCityCleverTabObj())
}

export function categoryClickedEvent(obj) {
    const body = {
        outletId: obj.outletId,
        outletLocality: obj.locality,
        outletName: obj.outletName
    }
    return Object.assign(body, localityAndCityCleverTabObj())
}

export function subCategoryClicked(obj) {
    const body = {
        outletId: obj.outletId,
        outletLocality: obj.locality,
        outletName: obj.outletName
    }
    return Object.assign(body, localityAndCityCleverTabObj())
}

export function searchMenuItemsEvent() {
    const body = {
        cartId: AppData.cartId,

    }
    return Object.assign(body, localityAndCityCleverTabObj())
}

export function userSIgnUpEvent() {
    const body = {
        userFirstName: AppData.userInfoData.name,
        userLastName: AppData.userInfoData.lastName,
        userEmail: AppData.userInfoData.email,
        userMobileNumber: AppData.userInfoData.mobile
    }
    return Object.assign(body, localityAndCityCleverTabObj())
}