import { AppData } from './app-data.service';
import { Router } from '@angular/router';
import { ApiRequestService } from './api-request.service';
import { StorageService } from './../../core/services/storage.service';
import { Injectable } from '@angular/core';
import { IdsService } from './ids.service';
import { ApplicationStateService } from './application-state.service';
import { ROUTES_STR } from 'src/app/core/constants/app-constants';
import { LocationService } from './location.service';
import { TempTokenService } from './temp-token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private storageService: StorageService,
    private apiRequestService: ApiRequestService,
    private router: Router,
    private idsService: IdsService,
    private locationService: LocationService,
    private tempTokenService: TempTokenService) {

    if (this.isAuthenticated()) {
      this.reInitializeAppData();
      AppData.token$.next(this.storageService.getItem('token'));
      AppData.isAuthenticated = true;
    } else {
      console.log('inside initApp');
      this.initializeAppData();
    }

    this.getDeviceId();
    console.log(AppData.cartId);
    if ((!AppData.userInfoData || AppData.userInfoData['isTemporary']) && !AppData.cartId) {
      this.tempTokenService.getTempToken();
    }
    console.log('auth service loaded');

    // this.reInitializeAppData();

  }

  isAuthenticated() {
    return AppData.isAuthenticated && AppData.userId;
  }

  async initializeAppData() {
    console.log('initialise app date');
    AppData.isAuthenticated = false;
    AppData.userInfoSub$.next(null);
    AppData.userIdSub$.next(null);
    AppData.bottomNavMobileIndSub$.next(0);
    // AppData.selectedAddonsListSub$.next([]);
    AppData.yourPlateItemsSub$.next(null);
    AppData.plateItemCountSub$.next({ itemCount: 0, itemTotal: 0, outletName: '' });
    AppData.spInstructionsSub$.next('');
    AppData.cartIdSub$.next(null);
    const deviceIdStor = this.storageService.getItem('deviceId');
    this.storageService.clearStorage();
    AppData.deviceIdSub$.next(deviceIdStor);
    // AppData.currentAddressSub$.next(await this.createLocationObj());
    AppData.selectedAddonsListSub$.next(AppData.selectedAddonsList);
    AppData.itemsInPlateSub$.next(AppData.itemsInPlate);
  }

  reInitializeAppData() {
    console.log('re-init called');
    const currentUrlTree = this.router['location']['_platformLocation']['location']['pathname'];
    const routeItem = this.router['location']['_platformLocation']['location']['search']
    // if (AppData.currentAddressObj === null) {
    //   this.router.navigate(['select-location']);
    // } else
    if (currentUrlTree === '/welcome' || currentUrlTree === '/') {
      this.router.navigate([ROUTES_STR.home]);
    } else {
      this.router.navigateByUrl(currentUrlTree + routeItem);
    }
    // if (currentUrlTree === '/select-location' || currentUrlTree === '/') {
    //   if (AppData.currentAddressObj !== null) {
    //     this.router.navigate([ROUTES_STR.home]);
    //   } else {
    //     this.router.navigateByUrl(currentUrlTree + routeItem);
    //   }
    // }

    this.locationService.setAndUpdateLocation();
  }
  getDeviceId() {
    if (this.storageService.getItem('deviceId') === '') {
      AppData.deviceIdSub$.next(this.idsService.generate());
    }
  }

  getCartDataItems(type) {
    if (type === 'CART_ID') {
      this.apiRequestService.get('cartId', '?cartId=' + '').then((res) => {
        const responseData = res['body']['data'];
        this.storageService.setItem("cartId", responseData['cartId']);
        AppData.cartIdSub$.next(responseData['cartId']);
      });
    } else {
      this.apiRequestService.get('cartId', '?cartId=' + AppData.cartId).then((res) => {
        const responseData = res['body']['data'];
        console.log('---------->', responseData);
        if (responseData['outlets']) {
          AppData.yourPlateItemsSub$.next(JSON.parse(JSON.stringify(responseData)));
        }
      });
    }

  }

  async logOut() {
    await this.apiRequestService.post('logout', {}).then(async (res) => {
      AppData.userInfoData = null;
      if (ApplicationStateService.isMobileResolution) {
        this.router.navigate(['welcome']);
      } else {
        await this.tempTokenService.getTempToken();
      }
      await this.initializeAppData();
    }, (async error => {
      AppData.userInfoData = null;
      if (ApplicationStateService.isMobileResolution) {
        this.router.navigate(['welcome']);
      } else {
        await this.tempTokenService.getTempToken();
      }
      await this.initializeAppData();
    }));
  }

 


}
