import { AppData } from 'src/app/shared/services/app-data.service';
import { AddressService } from './service/address.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../services/toast.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { ViewChild, Input, OnInit } from '@angular/core';
import { AddressModel } from './model/address';
import { ADDRESS_BY_CITY, AGM_MAP_CUSTOM_STYLES } from 'src/app/core/constants/app-constants';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { getFormValidation } from '../../utils/common-functions';
import { ErrorMessage } from 'src/app/core/constants/validation-msg-constants';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service'

export abstract class AddressComponent implements OnInit {
  @ViewChild('placesRefAdd', { static: true }) placesRef: GooglePlaceDirective;

  @Input() public modalObj: any;
  addressForm: FormGroup;
  isSubmitted = false;
  addressModel = new AddressModel();
  lat: number;
  lng: number;
  addressOptionsCity = ADDRESS_BY_CITY;
  title = '';
  addressList = [];
  isFormInit = false;
  isOthersTag = false;
  locality = '';
  fullAddress = '';
  googleLocation = '';
  searchValue: string = '';
  showForm: boolean;
  showinputs: true;
  isMobileRes;
  mapEvent;
  isAddAddress = false;
  os = null;

  customStyle = AGM_MAP_CUSTOM_STYLES;
  showAutoCompleteForm = true;

  constructor(
    private addressService: AddressService,
    private fb: FormBuilder,
    private toastService: ToastService,
    public activeModal: NgbActiveModal
  ) {
    this.addressService.init(this.setLocality.bind(this), this.setLatLng.bind(this),
      this.setFullAddress.bind(this), this.setCityStateCountry.bind(this), this.setGoogleLocation.bind(this));

    if (ApplicationStateService.isMobileResolution) {
      this.isMobileRes = true;
    } else {
      this.isMobileRes = false;
    }
  }
  ngOnInit() {
    console.log(this.modalObj);
    console.log(this.showForm);
    this.addressService.isErrInGeoLocation = false;
    if (this.modalObj['type'] === 'ADD_ADDRESS' || this.modalObj['type'] === 'ADD_ON_ADDRESS') {
      this.title = 'Add Address';
      this.isAddAddress = true;
      this.addressService.getCurrentLocation();
      this.initForm();
    } else {
      this.title = 'Edit Address';
      this.showForm = true;
      this.addressModel = Object.assign(this.addressModel, this.modalObj);
      this.initForm();
      this.lat = this.modalObj.longLat.coordinates[1];
      this.lng = this.modalObj.longLat.coordinates[0];
      if (this.addressModel._addressTag.toLowerCase() !== 'home'
        && this.addressModel._addressTag.toLowerCase() !== 'office') {
        this.addressForm.controls['addressTag'].setValue('others', { emit: false });
      }
    }

    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    this.os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      this.os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      this.os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      this.os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      this.os = 'Android';
    } else if (!this.os && /Linux/.test(platform)) {
      this.os = 'Linux';
    }
  }
  setLatLng(lat, lng) {
    this.lat = lat;
    this.lng = lng;
  }

  setLocality(val) {
    this.locality = val;
    this.addressModel._locality = this.locality;
  }

  setGoogleLocation(val) {
    this.googleLocation = val;
  }

  setCityStateCountry(city, state, country, pinCode) {
    this.addressModel['city'] = city;
    this.addressModel['state'] = state;
    this.addressModel['country'] = country;
    this.addressModel['pincode'] = pinCode;
  }

  setFullAddress(val) {
    this.addressForm.controls['fullAddress'].setValue(val, { emit: false });
    this.fullAddress = val;
    console.log(this.fullAddress);

  }

  initForm() {
    this.addressForm = this.fb.group({
      'fullAddress': [this.addressModel['fullAddress'], [Validators.required]],
      'houseNum': [this.addressModel['houseNum'], [Validators.required]],
      'landmark': [this.addressModel['landmark'], [Validators.required]],
      'addressTag': [this.addressModel['addressTag'].toLowerCase(), [Validators.required]],
      'name': [this.addressModel['name']]
    });
    this.addressForm.get('addressTag').valueChanges.subscribe(val => {
      if (val == 'others') {
        this.isOthersTag = true;
        this.addressForm.controls["name"].setValidators(Validators.required);
        this.addressForm.controls["name"].updateValueAndValidity();
      } else {
        this.isOthersTag = false;
        this.addressForm.controls["name"].clearValidators();
        this.addressForm.controls["name"].updateValueAndValidity();
      }
    });
    this.isFormInit = true;
  }


  onCrossOthersTag() {
    this.isOthersTag = false;
    this.addressForm.controls['addressTag'].setValue('', { emit: false });
    this.addressForm.controls['name'].setValue('', { emit: false });
  }

  handleAddressChange(address: Address, proceed: boolean) {
    console.log(address);
    this.addressForm.controls['fullAddress'].setValue(address.formatted_address, { emit: false });
    const obj = JSON.stringify(address.geometry.location);
    this.lat = JSON.parse(obj).lat;
    this.lng = JSON.parse(obj).lng;
    const adrObj = this.addressService.getCityStateCountry(address.address_components);
    const addressList = address.address_components;
    if (addressList.some(item => item.types[0] === 'sublocality_level_1')) {
      const subLocalityObj1 = addressList.find(item => item.types[0] === 'sublocality_level_1');
      this.addressModel._googleLocation = subLocalityObj1['long_name'];
    }
    // if (proceed) {
    this.addressService.getLocBaseOnLatLng(this.lat, this.lng);
    // }
    this.addressModel._locality = this.locality;
  }

  onGetLatLong(event) {
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.addressService.getLocBaseOnLatLng(this.lat, this.lng);
  }

  // Google Map Marker Dragging code
  markerDragEnd(event: any) {
    console.log(event);
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.addressService.getLocBaseOnLatLng(this.lat, this.lng);
  }


  // convenience getter for easy access to form fields
  get f() { return this.addressForm.controls; }

  // for checking valid fields
  getValid(fieldName) {
    return getFormValidation(this.f, fieldName, this.isSubmitted);
  }

  // for add and update address
  onAddAndUpdateAddress() {
    this.isSubmitted = true;
    const formObj = this.addressForm.value;
    formObj['city'] = this.addressModel._city;
    formObj['state'] = this.addressModel._state;
    formObj['country'] = this.addressModel._country;
    formObj['pincode'] = this.addressModel._pincode;
    formObj['googleLocation'] = this.addressModel._googleLocation;
    formObj['locality'] = this.addressModel._locality;
    formObj['longLat'] = {
      coordinates: [this.lng, this.lat]
    }
    if (formObj['addressTag'] === 'home' || formObj['addressTag'] === 'office') {
      formObj['name'] = formObj['addressTag'];
    }
    if (this.addressForm.valid) {
      console.log(formObj);
      if (formObj['addressTag'] !== '') {
        if (this.addressModel._addressId !== '') {
          formObj['addressId'] = this.addressModel._addressId;
          const modalAdrTag = this.modalObj['addressTag'];
          const name = this.modalObj['name'];
          if (modalAdrTag.toLowerCase() === formObj['addressTag']) {
            // if user is updating address tag and name then deleting key from object
            if (name.toLowerCase() === formObj['name'].toLowerCase()) {
              // delete formObj['addressTag'];
              // delete formObj['name'];
            } else {
              // delete formObj['addressTag'];
            }
          }
          console.log(formObj);
          this.onUpdateAddress(formObj);
        } else {
          console.log(formObj);
          this.onAddAddress(formObj);
        }
      } else {
        this.toastService.presentToast(ErrorMessage.PLEASE_SELECT_ADDRESS_TAG_MSG, 'Error', 'error');
      }
    } else {
      return;
    }
  }


  // for calling api for updating address
  onUpdateAddress(formObj) {
    console.log(formObj);

    this.addressService.updateAddress(formObj).then((res) => {
      const responseData = res['body']['data'];
      this.activeModal.close(responseData);
    }, (error => {
      console.log(error);
    }));
  }

  // for calling api for add address
  onAddAddress(formObj) {
    this.addressService.addAddress(formObj).then((res) => {
      const responseData = res['body']['data'];
      if (this.modalObj['type'] === 'ADD_ON_ADDRESS') {
        AppData.currentAddressSub$.next(formObj);
        let addressObj = {};
        addressObj = formObj;
        addressObj['addressId'] = responseData['addressId'];
        this.saveInLocationStorage(addressObj);
      }
      this.activeModal.close(responseData);
    }, (error => {
      console.log(error);
    }));
  }

  saveInLocationStorage(addressObj) {
    const userInfo = AppData.userInfoData;
    const localAddress = userInfo['address'];
    localAddress.push(JSON.parse(JSON.stringify(addressObj)));
    userInfo['address'] = localAddress;
    AppData.userInfoSub$.next(JSON.parse(JSON.stringify(userInfo)));
  }

  mapReady(agmMap) {
    console.log(agmMap);
    this.mapEvent = agmMap;

    agmMap.addListener("dragend", () => {
      console.log('map dragged');
      const lat = agmMap.getCenter().lat();
      const lng = agmMap.getCenter().lng()
      this.addressService.getLocBaseOnLatLng(lat, lng);

    });

    agmMap.addListener("zoom_changed", () => {
      console.log('zoom changed');
      // const lat = agmMap.getCenter().lat();
      // const lng = agmMap.getCenter().lng()
      // this.addressService.getLocBaseOnLatLng(lat, lng);
    });

    // fromEvent(agmMap, 'zoom_changed')
    //   .pipe(map((event: any) => { console.log(event) })
    //     , debounceTime(500)
    //     , distinctUntilChanged()
    //   ).subscribe(() => {
    //     console.log('djdj');
    //   });

  }

  // onCenterChange(event) {
  //   console.log(event);
  // }

  currentLocation(event) {
    this.addressService.getCurrentLocation();
    this.mapEvent.setCenter({ lat: this.lat, lng: this.lng });
    (<HTMLInputElement>document.getElementById('autocomplete')).value = '';
  }

  onConfirm() {
    console.log(this.fullAddress);
    if (this.fullAddress) {
      this.showForm = true;
      this.showAutoCompleteForm = false;
    } else {
      this.toastService.presentToast('Please select Address', '', 'error');
    }
  }

  scrollInputFieldToTop() {
    const doc = document.getElementById('edit-address-view');

    if (doc && (this.os === 'Android' || this.os === 'iOS')) {
      setTimeout(() => {
        doc.scrollTo({
          top: doc.scrollHeight,
          left: 0,
        })
      }, 250)
    }

  }

  onChange() {
    this.showForm = false;
    this.showAutoCompleteForm = true;
    this.addressService.isErrInGeoLocation = false;
  }

}
