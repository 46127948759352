import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMAIL_PATTERN } from 'src/app/core/constants/app-constants';
import { Signup } from '../../model/signup';
import { maxNumToBeAllowed } from 'src/app/shared/utils/common-functions';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  @Input() onMobileChange: any;
  @Output() onSingUpObj = new EventEmitter<any>();
  @Output() onSignInEmit = new EventEmitter<any>();
  signupForm: FormGroup;
  isSubmitted = false;
  signUpModel = new Signup();

  constructor(
    private fb: FormBuilder) { }

  ngOnInit() {
    if (this.onMobileChange) {
      this.signUpModel = Object.assign(this.signUpModel, this.onMobileChange);
    }
    this.initForm();
  }

  initForm() {
    this.signupForm = this.fb.group({
      'firstName': [this.signUpModel._firstName, [Validators.required, Validators.minLength(2), Validators.maxLength(40)]],
      'email': [this.signUpModel._email, [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      'mobile': [this.signUpModel._mobile, [Validators.required, Validators.min(1111111111), Validators.max(9999999999)]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.signupForm.controls; }

  // for checking valid fields
  getValid(fieldName) {
    if (this.f[fieldName].invalid && (this.f[fieldName].dirty || this.isSubmitted)) {
      return true;
    }
    return false;
  }

  // for sign up 
  onSignUp() {
    this.isSubmitted = true;
    if (this.signupForm.valid) {
      console.log(this.signupForm.value);
      const obj = this.signupForm.value;
      obj['type'] = 'SIGN_UP';
      this.onSingUpObj.emit(obj);
    } else {
      return;
    }
  }

  onSignIn() {
    this.onSignInEmit.emit();
  }

  onKeyPress(event) {
    return maxNumToBeAllowed(event, 10);
  }
}

