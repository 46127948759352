import { Injectable } from "@angular/core";
import { ApiRequestService, AppData } from "..";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  deviceDetails = {
    "platform": "web",
    "osVersion": "",
    "brandName": "",
    "modelName": "",
    "buildNumber": "3000003",
    "deviceId": AppData.deviceId
  }
  constructor(private apiRequestService: ApiRequestService,
  ) { }

  async cleverTapEvent(eventName, eventPropertiesObj) {
    const body = {
      deviceDetails: this.deviceDetails,
      eventDetails: eventPropertiesObj
    }
    body.eventDetails.eventName = eventName;
    body.eventDetails.timestamp = new Date();
    console.log('analytics', );

    console.log('analytics data -> ', body);
    await this.apiRequestService.post('analytics', body);
    // clevertap.event.push(eventName, eventPropertiesObj);
  }



  // addItemInCartObj(obj, offerPrice, quantity) {
  //   const body = {
  //     timestamp: new Date().toISOString(),
  //     userId: AppData.userId,
  //     userCity: AppData.currentAddressObj.city,
  //     userLocality: AppData.currentAddressObj.locality,
  //     itemId: obj.itemId,
  //     itemName: obj.name,
  //     item_discount: offerPrice === 0 ? obj.price : offerPrice,
  //     itemPrice: obj.price,
  //     outletId: obj.outletId,
  //     outletName: obj.outletName,
  //     quantity: quantity,
  //     addon: obj.addons.length > 0 ? makeAddonsStr(obj.addons) : '',
  //     variant: obj.variant ? obj.variant.options.optionName : ''
  //   }
  //   console.log(body, this.localityAndCityCleverTabObj());
  //   return Object.assign(body, this.localityAndCityCleverTabObj());

  // }

  localityAndCityCleverTabObj() {
    const body = {
      // locality: AppData.currentAddressObj.locality ? AppData.currentAddressObj.locality : ,
      userId: AppData.userId,
      userCity: AppData.currentAddressObj.city,
      userLocality: AppData.currentAddressObj.locality,


    }
    if (AppData.currentAddressObj.locality) {
      body['userLocality'] = AppData.currentAddressObj.locality;
    } else if (AppData.currentAddressObj.googleLocation) {
      body['userLocality'] = AppData.currentAddressObj.googleLocation;
    } else {
      body['userLocality'] = AppData.currentAddressObj.city;
    }
    return body;
  }

  removeItemInCartCleverTapObj(obj, offerPrice, quantity) {
    const body = {
      outletId: obj.outletId,
      outletName: obj.outletName,
      itemId: obj.itemId,
      itemName: obj.name,
      // item_discount: offerPrice === 0 ? obj.price : offerPrice,
      itemPrice: obj.price,
      // quantity: quantity,
      timestamp: new Date().toISOString(),
      outletLocality: obj.outlets[0].locality
    }

    console.log(body, this.localityAndCityCleverTabObj())
    return Object.assign(body, this.localityAndCityCleverTabObj());
  }

  clearCartAnalyticObj(obj) {
    const body = {
      outletId: obj.outletId,
      timestamp: new Date().toISOString(),
      cartId: AppData.cartId

    }
    return Object.assign(body, this.localityAndCityCleverTabObj());

  }

  paymentSuccessObj(orderId, outletId) {
    const body = {
      orderId: orderId,
      outletId: outletId,
      cartId: AppData.cartId,
      timestamp: new Date().toISOString(),

    }
    console.log(body, this.localityAndCityCleverTabObj());

    return Object.assign(body, this.localityAndCityCleverTabObj());
  }

  paymentFailedObj(orderId, outletId) {
    const body = {
      orderId: orderId,
      outletId: outletId,
      cartId: AppData.cartId,
      timestamp: new Date().toISOString(),

    }
    return Object.assign(body, this.localityAndCityCleverTabObj());
  }

  outletViewedObj(outletObj) {
    const body = {
      outletId: outletObj.outletId,
      outletName: outletObj.outletName,
      outletLocality: outletObj.locality,
      timestamp: new Date().toISOString(),

    }
    return Object.assign(body, this.localityAndCityCleverTabObj());
  }

  appExitedObj(obj) {
    const body = {
      timestamp: new Date().toISOString(),
    }
    return Object.assign(body, this.localityAndCityCleverTabObj());
  }


}