export const CLEVER_TAB_EVENT = {
    APP_STARTED: 'app_started',
    APP_EXITED: 'app_exited',
    USER_LOGIN: 'user_login',
    USER_SIGNUP: 'user_signup',
    OUTLET_VIEWED: 'outlet_viewed',
    ITEM_ADDED_CART: 'item_added_cart',
    ITEM_REMOVED_CART: 'item_removed_cart',
    CART_CLEARED: 'cart_cleared',
    PROFILE_CHANGE: 'profile_change',
    USED_REFERRAL: 'used_referral',
    MENU_SEARCH: 'menu_search',
    EXPLORE_SEARCH: 'explore_search',
    USER_LOGOUT: 'user_logout',
    FOOD_PREFERENCE: 'food_preference',
    INVITED_USER_ATTEMPT: 'invited_user_attempt',
    SHARED_OUTLET: 'shared_outlet',
    PAYMENT_INITIATED: 'payment_initiated',
    PAYMENT_CANCELED: 'payment_canceled',
    PAYMENT_SUCCESS: 'payment_success',
    PAYMENT_FAILED: 'payment_failed',
    UNABLE_TO_CHECKOUT: 'unable_to_checkout',
    COUPON_APPLIED: 'coupon_applied',
    COUPON_REMOVED: 'coupon_removed',
    ORDER_TRACKING: 'order_tracking',
    EVENT_ISSUE: 'event_issue',
    USER_LOCATION: 'user_location',
    SEE_MORE: 'see_more',
    PROMO_CLICK: 'promo_click',
    CHANGED: 'Charged',
    TOP_BANNER_CLICK: 'top_banner_click',
 

    
    SEND_OTP_TO_USER: 'send_otp_to_user',
    USER_CHANGE_LOCATION: 'user_change_location',
    APPLY_COUPON: 'apply_coupon',
    CHECKOUT_CLICKED: 'checkout_clicked',
    CART_CLICKED: 'cart_clicked',
    PAY_CLICKED: 'pay_clicked',
    LETS_EXPLORE: 'lets_explore',
    VEG_TOGGLED: 'veg_toggled',
    EGG_TOGGLED: 'egg_toggled',
    CHANGE_OUTLET: 'change_outlet',
    PROFILE_CLICKED: 'profile_clicked',
    MANAGE_ADDRESSES: 'manage_addresses',
    USER_WALLET: 'user_wallet',
    ORDER_HISTORY: 'order_history',
    MY_REWARDS :'my_rewards',
    CATEGORY_CLICKED: 'category_clicked',
    SUBCATEGORY_CLICKED: 'subcategory_clicked',
    SEARCH_MENUITEM: 'search_menuitem',
    USER_SIGNUP_EVENT: 'user_signup_event',
}

