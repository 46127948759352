import { Router } from '@angular/router';
import { AppData } from 'src/app/shared/services/app-data.service';
import { ApiRequestService } from '../../../../shared/services/api-request.service';
import { Injectable } from '@angular/core';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ROUTES_STR } from 'src/app/core/constants/app-constants';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  closeLoginModal: any; // method variable

  constructor(private apiRequestService: ApiRequestService,
    private router: Router,
    private activeModal: NgbActiveModal) { }

  init(onCloseLoginModal) {
    this.closeLoginModal = onCloseLoginModal;
  }

  sendOtp(reqBody) {
    return this.apiRequestService.post('sendOtp', reqBody);
  }

  reSendOtp(reqBody) {
    return this.apiRequestService.post('reSendOtp', reqBody, '', '?method=resendOtp');
  }

  signUp(reqBody) {
    return this.apiRequestService.post('signUp', reqBody);
  }

  verifyOtp(reqBody) {
    return this.apiRequestService.post('verifyOtp', reqBody);
  }

  addReferralCode(reqBody) {
    return this.apiRequestService.post('referralCode', reqBody, {}, '?userId=' + AppData.userId);
  }
  public setAndUpdateUserLoc(reqObj) {
    return this.apiRequestService.post('updateLocation', reqObj);
  }
  async updateLocation() {
    const currentObj = AppData.currentAddressObj
    const reqBody = {
      locality: currentObj.locality ? currentObj.locality : currentObj.city,
      city: currentObj.city,
      coordinates: currentObj.longLat.coordinates
    }

    await this.setAndUpdateUserLoc(reqBody);
  }

  async saveUserDataInLocal(responseData) {
    AppData.isAuthenticated = true;
    AppData.token$.next(responseData.token);
    AppData.userInfoSub$.next(responseData.user);
    AppData.userIdSub$.next(responseData.user.userId);
    AppData.cartIdSub$.next(responseData.user.cartId);
    // this.closeLoginModal();
    
    if (ApplicationStateService.isMobileResolution) {
      if (AppData.currentAddressObj) {
       await this.updateLocation();
        if (responseData.navigateToCart === 'Y') {
          this.router.navigate([ROUTES_STR.cart]);
        } else {
          this.router.navigate([ROUTES_STR.home]);
        }
      } else {
        this.router.navigate(['select-location']);
      }
    } else {
      this.activeModal.close();
    }
  }
}
