import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { COUNTER, SECONDS, COUNTRY_CODE } from 'src/app/core/constants/app-constants';
import { LoginService } from '../../service/login.service';
import { getFormValidation, maxNumToBeAllowed } from 'src/app/shared/utils/common-functions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppData } from 'src/app/shared';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit, OnDestroy {
  @Input() onLoginAndSignUpObj: any;
  @Output() onChangeMobileNum = new EventEmitter<any>();
  otpForm: FormGroup;
  isSubmitted = false;
  counter = COUNTER;
  interval: any;
  showResend = false;
  seconds = SECONDS;
  loginAndSignUpObj;
  constructor(private fb: FormBuilder,
    private loginService: LoginService,
    private modalService: NgbModal,
  ) {
    this.loginService.init(this.onCloseLoginModal.bind(this))
  }

  ngOnInit() {
    console.log(this.onLoginAndSignUpObj);
    this.loginAndSignUpObj = this.onLoginAndSignUpObj;
    this.seconds = 30;
    this.startTimer();
    this.initForm();
  }

  onCloseLoginModal() {
    this.modalService.dismissAll();
  }

  initForm() {
    this.otpForm = this.fb.group({
      'otp': ['', [Validators.required, Validators.min(1000), Validators.max(9999)]]
    });
  }

  get otpControl() { return this.otpForm.controls; }

  getOTPValid(fieldName) {
    return getFormValidation(this.otpControl, fieldName, this.isSubmitted);
  }

  //create api request object for login and sign up 
  createObjForLoginAndSignUp() {
    const body = {
      mobile: this.loginAndSignUpObj.mobile,
      otp: this.otpForm.controls['otp'].value,
      countryCode: COUNTRY_CODE,
      // cartId: AppData.cartId,
    }
    return body;
  }

  onVerifyOTP() {
    this.isSubmitted = true;
    if (this.otpForm.valid) {
      if (this.loginAndSignUpObj.type === 'LOGIN') {
        this.onLogin();
      } else {
        this.onSignUp();
      }
    } else {
      return;
    }
  }

  // for loin
  async onLogin() {
    this.loginService.verifyOtp(await this.createObjForLoginAndSignUp()).then((res) => {
      const responseData = res['body']['data'];
      console.log(responseData);
      this.loginService.saveUserDataInLocal(responseData);

    });
  }

  // for sign up 
  async onSignUp() {
    const reqBody = await this.createObjForLoginAndSignUp();
    reqBody['userName'] = this.loginAndSignUpObj.userName;
    reqBody['email'] = this.loginAndSignUpObj.email;
    reqBody['gcmToken'] = '';
    this.loginService.signUp(reqBody).then((res) => {
      const responseData = res['body']['data'];
      console.log(responseData);
      this.loginService.saveUserDataInLocal(responseData);
    });
  }

  // create object for resend otp
  createObjForResendOTP() {
    const body = {
      countryCode: COUNTRY_CODE,
      mobile: this.loginAndSignUpObj.mobile,
      type: 'text',
      action: this.loginAndSignUpObj.type === 'LOGIN' ? 'login' : 'signup',
      method: 'resendOtp',
    };
    console.log('resend OTP: ', body);
    return body;
  }

  // for call api for resend otp 
  async onResendOTP() {
    this.loginService.reSendOtp(await this.createObjForResendOTP()).then((res) => {
      this.seconds = 30;
      if (this.counter >= 0) {
        this.showResend = false;
        this.startTimer();
        this.counter--;
      }
    });
  }

  // Timer function for resend the OTP
  startTimer() {
    this.interval = setInterval(() => {
      if (this.seconds > 1) {
        this.showResend = false;
        this.seconds--;
      } else {
        this.showResend = true;
        clearInterval(this.interval);
        if (this.counter === 0) {
          this.showResend = false;
          this.counter = -1;
        }
      }
    }, 1000);
  }

  onChangeMobile() {
    this.onChangeMobileNum.emit(this.loginAndSignUpObj);
  }

  onKeyPress(event) {
    return maxNumToBeAllowed(event, 4);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}