import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AppData } from '../..';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dynamic-layout-view-modal',
  templateUrl: './dynamic-layout-view-modal.component.html',
  styleUrls: ['./dynamic-layout-view-modal.component.scss']
})
export class DynamicLayoutViewModalComponent implements OnInit {
  @Input() modalObj: any;
  displayUrl = '';

  constructor() { }

  ngOnInit() {
    if (this.modalObj.meta.clickUrl.indexOf('?') > 0) {
      this.displayUrl = this.modalObj.meta.clickUrl + '&platform=web';
    } else {
      this.displayUrl = this.modalObj.meta.clickUrl + '?platform=web';
    }
    this.displayUrl = this.displayUrl + `&userId=${AppData.userId}&x-access-token=${AppData.token}&promoId=${this.modalObj.promoId}`;
    console.log(this.displayUrl);
  }
}
