import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/shared';
import { LoginService } from '../../modals/login-signup-modal/service/login.service';
import { SignUpComponent } from '../sign-up.component';
import { Location } from '@angular/common';
import { LoginDesktopComponent } from '../../login/login-desktop/login-desktop.component';
import { getConfig } from 'src/app/core/constants/app-constants';
import { SignUpService } from '../service/sign-up.service';
import { CacheService } from 'src/app/shared/services/cache.service';

@Component({
  selector: 'app-sign-up-desktop',
  templateUrl: './sign-up-desktop.component.html',
  styleUrls: ['./sign-up-desktop.component.scss']
})
export class SignUpDesktopComponent extends SignUpComponent {

  constructor(
    fb: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    location: Location,
    loginService: LoginService,
    toastService: ToastService,
    modalService: NgbModal,
    activeModal: NgbActiveModal,
    signUpService: SignUpService,
    cacheService: CacheService
  ) {
    super(fb,
      router,
      activatedRoute,
      location,
      loginService,
      toastService,
      modalService,
      activeModal,
      signUpService,
      cacheService)
  }

  login() {
    this.activeModal.close();
    const modalRef = this.modalService.open(LoginDesktopComponent, getConfig('LOGIN_DESKTOP_CONFIG'));
    modalRef.result.then((res) => {
      if (res.openSignupModal) {
        const signUpModalRef = this.modalService.open(SignUpDesktopComponent, getConfig('LOGIN_DESKTOP_CONFIG'));
        signUpModalRef.componentInstance.mobile = res.mobile;
      }
    })
  }

}
