import { Component } from '@angular/core';
import { AddressComponent } from '../address.component';
import { AddressService } from 'src/app/shared/components/add-address-modal/service/address.service';
import { FormBuilder } from '@angular/forms';
import { ToastService } from '../../../services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-add-address-on-cart-desktop',
  templateUrl: './add-address-on-cart-desktop.component.html',
  styleUrls: ['./add-address-on-cart-desktop.component.scss']
})
export class AddAddressOnCartDesktopComponent extends AddressComponent {


  constructor(
    addressService: AddressService,
    fb: FormBuilder,
    toastService: ToastService,
    activeModal: NgbActiveModal,
  ) {
    super(addressService, fb, toastService, activeModal);

  }



}
