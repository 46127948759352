import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { MenuItemService } from '../../restaurant-details/service/menu-item.service';
import { AppData } from 'src/app/shared';

@Component({
  selector: "app-menu-categories",
  templateUrl: "./menu-categories.component.html",
  styleUrls: ["./menu-categories.component.scss"],
})
export class MenuCategoriesComponent implements OnInit {
  @Input() modalObj;
  categoryList = [];

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.categoryList = this.modalObj;
  }

  onCategoryFilter(category) {
    this.activeModal.close(category);
  }
}
