import { ReferralCodeModalComponent } from './../../shared/components/referral-code-modal/referral-code-modal.component';
import { ErrorCode } from './../../shared/utils/enums/error-code.enum';
import { EMAIL_PATTERN, COUNTRY_CODE, getConfig, ROUTES_STR, LOGIN_SIGNUP_TEXT } from 'src/app/core/constants/app-constants';
import { maxNumToBeAllowed } from 'src/app/shared/utils/common-functions';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { AfterViewInit, Input, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Signup } from '../modals/login-signup-modal/model/signup';
import { LoginService } from '../modals/login-signup-modal/service/login.service';
import { ToastService } from 'src/app/shared';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { SignUpService } from './service/sign-up.service';
import { CacheService } from 'src/app/shared/services/cache.service';

export abstract class SignUpComponent implements OnInit, AfterViewInit, OnDestroy {
  signupForm: FormGroup;
  referralCodeForm: FormGroup;
  isSubmitted = false;
  signUpModel = new Signup();
  routeObj;
  referralCode = '';
  @Input() mobile;
  modalObj;
  showOtpModal = false;
  titleTxt = "";
  navUrl = "/restaurants/account";
  signupText = LOGIN_SIGNUP_TEXT.main.signup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private loginService: LoginService,
    private toastService: ToastService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private signUpService: SignUpService,
    private cacheService: CacheService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.routeObj = params;
      if(params['navigationFrom']) {
        this.signupText = LOGIN_SIGNUP_TEXT[params['navigationFrom']].signup;
      }
    });
    
    console.log(this.routeObj);

  }

  ngOnInit() {
    this.signUpModel = Object.assign(this.signUpModel, this.routeObj);
    this.initForm();
    const formData = this.cacheService.getSignupFormData();
    if(formData) {
      this.signupForm.patchValue(formData);
    }
  }

  ngAfterViewInit() {
    console.log(this.mobile);
    if (this.mobile) {
      this.signupForm.controls['mobile'].setValue(String(this.mobile));
    }
  }

  initForm() {
    console.log('signup init form');
    this.signupForm = this.fb.group({
      'firstName': [this.signUpModel._firstName, [Validators.required, Validators.minLength(2), Validators.maxLength(40)]],
      'lastName': [""], //, [Validators.required, Validators.minLength(2), Validators.maxLength(20)]
      'email': [this.signUpModel._email, [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      'mobile': [this.signUpModel._mobile, [Validators.required, Validators.min(1111111111), Validators.max(9999999999)]],
      'isReferralCode': [false],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.signupForm.controls; }

  initReferralForm() {
    this.referralCodeForm = this.fb.group({
      'referralCode': ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    });
  }

  // for checking valid fields
  getValid(fieldName) {
    if (this.f[fieldName].invalid && (this.f[fieldName].dirty || this.isSubmitted)) {
      return true;
    }
    return false;
  }

  validateReferralForm(fieldName) {
    if (this.referralCodeForm.controls[fieldName].invalid && (this.referralCodeForm.controls[fieldName].dirty || this.isSubmitted)) {
      return true;
    }
    return false;
  }

  createObjForSendOTP() {
    const body = {
      mobile: String(this.signupForm['controls']['mobile'].value),
      countryCode: COUNTRY_CODE,
      action: 'signup',
    }
    return body;
  }

  onCreateAccount() {
    console.log(this.signupForm);
    
    if(this.signupForm.valid) {
      this.signUpService.saveSignUpDetails(this.signupForm.value);
      this.router.navigate(['/referral']);
    }
  }

  // for sign up 
  async onSignUp() {
    this.isSubmitted = true;
    if (this.signupForm.valid) {
      console.log(this.signupForm.value);
      const reqBody = await this.createObjForSendOTP();
      this.loginService.sendOtp(reqBody).then((res) => {
        const reqBody = this.signupForm.value;

        const responseData = res['body'];
        if (responseData['code'] === ErrorCode.USER_EXISTS) {
          this.toastService.presentToast(responseData.message, '', 'error');
          this.router.navigate(['login']);
        } else {
          console.log(responseData);
          const navigationExtras: NavigationExtras = {
            queryParams: {
              email: reqBody.email,
              mobile: reqBody.mobile,
              type: 'SIGN_UP',
              firstName: reqBody.firstName,
              lastName: reqBody.lastName,
              referralCode: this.referralCode
            }
          };
          if (ApplicationStateService.isMobileResolution) {
            this.router.navigate(['otp'], navigationExtras);
          } else {
            // this.activeModal.close();
            const obj: any = this.signupForm.controls;
            obj['type'] = 'SIGNUP';
            obj['referralCode'] = this.referralCodeForm ? this.referralCodeForm.value.referralCode : ''
            this.modalObj = obj;
            this.showOtpModal = true;
          }
        }

      });
      // this.onSingUpObj.emit(obj);

    } else {
      return;
    }
  }

  onSignIn() {
    this.router.navigate(['login']);
  }

  onBack() {
    this.router.navigate(['login']);
  }

  onKeyPress(event) {
    return maxNumToBeAllowed(event, 10);
  }

  onChangeReferralCode(val) {
    console.log(val);
    if (ApplicationStateService.isMobileResolution) {
      const mObj = {};
      if (val) {
        const modalRef = this.modalService.open(ReferralCodeModalComponent, getConfig('REFERRAL_CODE_MODAL_CONFIG'));
        modalRef.componentInstance.modalObj = mObj;
        modalRef.result.then((res) => {
          console.log(res);
          this.referralCode = res.referralCode;
        }).catch((error) => {
          console.log(error);
          this.signupForm.controls['isReferralCode'].setValue(false, { emit: false });
        });
      } else {
        this.referralCode = '';
      }
    } else {
      this.initReferralForm();
      this.referralCode = this.referralCodeForm.controls['referralCode'].value;
    }

  }

  onChangeMobileNumber(event) {
    console.log(event);
    this.showOtpModal = false;
  }

  ngOnDestroy() {
    this.cacheService.setSignupFormData(this.signupForm.value);
  }
}

