import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class NgbModalDismissAllGuard implements CanActivateChild, CanActivate, CanLoad {
  constructor(private modalService: NgbModal) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
    return true;
  }

  canLoad(route: Route) {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
    return true;
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
    return true;
  }
}

