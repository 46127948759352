import { OfferService } from './../../../../modules/offers/service/offer.service';
import { Component, Input, OnInit } from '@angular/core';
import { OfferComponent } from 'src/app/modules/offers/offer.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-offer-desktop-modal',
  templateUrl: './offer-desktop-modal.component.html',
  styleUrls: ['./offer-desktop-modal.component.scss']
})
export class OfferDesktopModalComponent extends OfferComponent {
  constructor(offerService: OfferService,
    route: ActivatedRoute,
    router: Router,
    activeModal: NgbActiveModal) {
    super(offerService, route, router, activeModal);
  }
}
