import { AppData } from 'src/app/shared/services/app-data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { ADDRESS_TAG_IMG } from 'src/app/core/constants/app-constants';
import { AddressService } from '../service/address.service';

@Component({
  selector: 'app-change-address-modal',
  templateUrl: './change-address-modal.component.html',
  styleUrls: ['./change-address-modal.component.scss']
})
export class ChangeAddressModalComponent implements OnInit {
  changeAddressList = [];

  constructor(private activeModal: NgbActiveModal, private addressService: AddressService) { }

  ngOnInit() {
    this.changeAddressList = AppData.userInfoData.address;
    console.log(this.changeAddressList);
  }

  onSetAddressImg(tag) {
    if (tag.toLowerCase() === 'home' || tag.toLowerCase() === 'office') {
      return ADDRESS_TAG_IMG[tag.toLowerCase()];
    } else {
      return 'assets/icon/cart/deliver-to-other.svg';
    }
  }

  onClose() {
    this.activeModal.dismiss();
  }

  onSelectAddress(obj) {
    console.log(obj);
    AppData.currentAddressSub$.next(obj);
    const adrObj = obj;
    adrObj['type'] = 'CHANGE_ADR';
    this.activeModal.close(adrObj);
  }

  onAddNewAddress() {
    const adrObj = {};
    adrObj['type'] = 'ADD_ADR';
    this.activeModal.close(adrObj);
  }

}
