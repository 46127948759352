import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Route, Router } from '@angular/router';
import { AppData } from '../services/app-data.service';
import { ApplicationStateService } from '../services/application-state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public router: Router) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (!AppData.currentAddressObj) {
      this.router.navigate(['/welcome']);
      return true;
    }

    console.log(this.router.url);

    if (AppData.isAuthenticated) {
      return true;
    }

    if (ApplicationStateService.isMobileResolution) {
      this.router.navigate(['welcome']);
      return false;
    }
    // if(!ApplicationStateService.isMobileResolution) {
    //   this.router.navigate(['welcome']);
    //   return false;
    // }
    return true;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!AppData.currentAddressObj) {
      this.router.navigate(['/welcome']);
      return true;
    }

    console.log(this.router.url);

    if (AppData.isAuthenticated) {
      return true;
    }
    this.router.navigate(['welcome']);
    if(!ApplicationStateService.isMobileResolution) {
      this.router.navigate(['welcome']);
    }
    return false;
  }

  canLoad(route: Route) {
    if (!AppData.currentAddressObj) {
      this.router.navigate(['/welcome']);
      return true;
    }

    console.log(this.router.url);

    if (AppData.isAuthenticated) {
      return true;
    }
    this.router.navigate(['welcome']);
    if(!ApplicationStateService.isMobileResolution) {
      this.router.navigate(['welcome']);
    }
    return false;
  }
}
