import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { getConfig, ROUTES_STR } from 'src/app/core/constants/app-constants';
import { Router } from '@angular/router';
import { RatingModalMobileComponent } from '../rating-modal/mobile/rating-modal-mobile.component';

@Component({
  selector: 'app-rate-order-modal',
  templateUrl: './rate-order-modal.component.html',
  styleUrls: ['./rate-order-modal.component.scss']
})
export class RateOrderModalComponent implements OnInit {

  @Input() outletObj: any;
  @Output() returnData: EventEmitter<any> = new EventEmitter();

  rateBdrIcon = "assets/icon/rate/rate-star-grey-border.svg";
  rateYellowIcon = "assets/icon/rate/rate-star-yellow.svg";
  starsList = [0, 1, 2, 3, 4];

  constructor(
    public modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private router: Router
  ) { }

  ngOnInit() {
  }

  onRatingClick(rat) {
    const rating = rat + 1;

    for (let i = 0; i < this.starsList.length; i++) {
      const ind = String(i);
      if (i <= rat) {
        document.getElementById(ind)['src'] = this.rateYellowIcon;
      } else {
        document.getElementById(ind)['src'] = this.rateBdrIcon;
      }
    }

    let returnDataObj = {};
    this.activeModal.close();

    const modalObj = this.outletObj;
    modalObj['navigateToHome'] = true;
    const modalRef = this.modalService.open(RatingModalMobileComponent, getConfig('RATING_MODAL_CONFIG'));
    modalRef.componentInstance.modalObj = modalObj;
    modalRef.componentInstance.selectedRating = rating;
    modalRef.componentInstance.passRating.subscribe((res) => {
      console.log('closing', res);
      if (res['rated']) {
        returnDataObj['rated'] = true;
        returnDataObj['rating'] = res['foodRating'];
      }
    });

    this.returnData.emit(returnDataObj);
  }

  closeModal() {
    this.modalService.dismissAll();
    if (this.outletObj.navigateToHome) {
      this.router.navigate([ROUTES_STR.home]);
    }
  }

}
