import { NetworkInterceptor } from './shared/interceptor/network.interceptor';
import { environment } from 'src/environments/environment';
import { ResponseInterceptor } from './shared/interceptor/response.interceptor';
import { DirectiveModule } from 'src/app/core/directives/module/directive/directive.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterOptionsComponent } from './modules/modals/filter-options/filter-options.component';
import { AddonVariantComponent } from './modules/modals/addon-variant/addon-variant.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddonModelRepeatComponent } from './modules/modals/addon-model-repeat/addon-model-repeat.component';
// import { LoginComponent } from './modules/modals/login/login.component';
import { GlobalErrorHandler, AppData, TokenInterceptor, ApiRequestService, ToastService } from './shared';
import { StorageService } from './core/services/storage.service';
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AgmDirectionModule } from 'agm-direction';
import { ConfirmationModalComponent } from './modules/modals/confirmation-modal/confirmation-modal.component';
import { DeliveryLocationModalComponent } from './shared/components/add-address-modal/delivery-location-modal/delivery-location-modal.component';
import { AddEditAddressModalComponent } from './shared/components/add-address-modal/add-edit-address-modal/add-edit-address-modal.component';
import { CouponTermsAndConditionsComponent } from './modules/modals/coupon-terms-and-conditions/coupon-terms-and-conditions.component';
import { BankOfferDetailsComponent } from './modules/modals/bank-offer-details/bank-offer-details.component';
import { ProfileRankingRulesComponent } from './modules/account/component/profile-ranking-rules/profile-ranking-rules.component';
import { OutletsComponent } from './modules/modals/outlets/outlets.component';
import { MenuCategoriesComponent } from './modules/modals/menu-categories/menu-categories.component';
import { ClearCartComponent } from './modules/modals/clear-cart/clear-cart.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { LoignSignupModalModule } from './modules/modals/login-signup-modal/loign-signup-modal.module';
import { OrderTrackModalComponent } from './modules/account/component/order-track-modal/mobile/order-track-modal.component';
import { OfferDetailsComponent } from './modules/modals/offer-details/offer-details.component';
import { ChangeAddressModalComponent } from './shared/components/add-address-modal/change-address-modal/change-address-modal.component';
import { ConfirmOrderComponent } from './modules/modals/confirm-order/confirm-order.component';
import { TaxAndChargesModalComponent } from './shared/components/tax-and-charges-modal/tax-and-charges-modal.component';
import { ThankyouForRatingModalComponent } from './modules/account/component/thankyou-for-rating-modal/thankyou-for-rating-modal.component';
import { RateOrderModalComponent } from './modules/account/component/rate-order-modal/rate-order-modal.component';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { CouponConfirmationModalComponent } from './shared/components/coupon-confirmation-modal/coupon-confirmation-modal.component';
import { ReferralCodeModalComponent } from './shared/components/referral-code-modal/referral-code-modal.component';
import { DynamicLayoutViewModalComponent } from './shared/components/dynamic-layout-view-modal/dynamic-layout-view-modal.component';
import { PaymentsModalComponent } from './shared/components/payments-modal/payments-modal.component';
import { ScratchCardModalComponent } from './shared/components/scratch-card-modal/scratch-card-modal.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import { PaymentModalComponent } from './shared/components/payment-modal/payment-modal.component';
import { CustomDirectiveModule } from './shared/utils/directives/custom-directive.module';
import { AddAddressOnCartDesktopComponent } from './shared/components/add-address-modal/add-address-on-cart-desktop/add-address-on-cart-desktop.component';
import { OfferDesktopModalComponent } from './shared/components/desktop/offer-desktop-modal/offer-desktop-modal.component';
import { LoginDesktopComponent } from './modules/login/login-desktop/login-desktop.component';
import { SignUpDesktopComponent } from './modules/sign-up/sign-up-desktop/sign-up-desktop.component';
import { CouponCardModule } from './shared/components/coupon-card/coupon-card.module';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { DefaultTooltipOptions } from './core/constants/app-constants';
import { AnalyticsService } from './shared/services/analytics.service';
import { OrderDetailsDesktopComponent } from './modules/account/component/order-details-modal/order-details-desktop/order-details-desktop.component';
import { OrderDetailsMobileComponent } from './modules/account/component/order-details-modal/order-details-mobile/order-details-mobile.component';
import { OtpDesktopModule } from './modules/otp/otp-desktop/otp-desktop.module';
import { RatingModalMobileComponent } from './modules/account/component/rating-modal/mobile/rating-modal-mobile.component';
import { RatingModalDesktopComponent } from './modules/account/component/rating-modal/desktop/rating-modal-desktop.component';
import { DelveryTypeModalComponent } from './shared/components/delvery-type-modal/delvery-type-modal.component';
import { OutletGroupComponent } from './shared/components/outlet-group/outlet-group.component';
import { TopHeaderMobileComponent } from './core/core-components/mobile-core-components/top-header-mobile/top-header-mobile.component';
import { ScratchCardMobileInfoModule } from './shared/components/scratch-card-mobile-info/scratch-card-mobile-info.module';
import { TopHeaderMobileModule } from './core/core-components/mobile-core-components/top-header-mobile/top-header-mobile.module';


const COMPONENTS = [
  FilterOptionsComponent,
  AddonVariantComponent,
  AddonModelRepeatComponent,
  ConfirmationModalComponent,
  DeliveryLocationModalComponent,
  AddEditAddressModalComponent,
  CouponTermsAndConditionsComponent,
  BankOfferDetailsComponent,
  ProfileRankingRulesComponent,
  OrderDetailsMobileComponent,
  RatingModalMobileComponent,
  RatingModalDesktopComponent,
  OrderTrackModalComponent,
  OfferDetailsComponent,
  OutletsComponent,
  MenuCategoriesComponent,
  ClearCartComponent,
  ChangeAddressModalComponent,
  ConfirmOrderComponent,
  TaxAndChargesModalComponent,
  ThankyouForRatingModalComponent,
  OutletGroupComponent,

  RateOrderModalComponent,
  CouponConfirmationModalComponent,
  DynamicLayoutViewModalComponent,
  ReferralCodeModalComponent,
  PaymentsModalComponent,
  ScratchCardModalComponent,
  PaymentModalComponent,
  LoginDesktopComponent,
  SignUpDesktopComponent,
  AddAddressOnCartDesktopComponent,
  OfferDesktopModalComponent,
  OrderDetailsDesktopComponent,
  OrderDetailsMobileComponent,
  DelveryTypeModalComponent,
  

];

@NgModule({
  declarations: [
    AppComponent,
    COMPONENTS,
    OutletsComponent,
    MenuCategoriesComponent,
    ClearCartComponent,
    LoaderComponent,
    DelveryTypeModalComponent,
    OutletGroupComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    DirectiveModule,
    GooglePlaceModule,
    AgmDirectionModule,
    CustomDirectiveModule,
    TooltipModule.forRoot(DefaultTooltipOptions as TooltipOptions),
    AgmCoreModule.forRoot({
      apiKey: environment.mapApiKey
    }),
    LoignSignupModalModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFireMessagingModule,
    LottieAnimationViewModule.forRoot(),
    CouponCardModule,
    OtpDesktopModule,
    ScratchCardMobileInfoModule,
    TopHeaderMobileModule
  ],
  entryComponents: [
    COMPONENTS
  ],

  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    AppData,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true
    },
    ApiRequestService,
    ToastService,
    StorageService,
    NgbActiveModal,
    AnalyticsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
