import { TaxAndChargesModalComponent } from './../../../../shared/components/tax-and-charges-modal/tax-and-charges-modal.component';
import { OrderService } from './../../service/order.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CHARGES, getConfig, ROUTES_STR } from 'src/app/core/constants/app-constants';
import { OrderTrackModalComponent } from '../order-track-modal/mobile/order-track-modal.component';
import { Router, NavigationExtras } from '@angular/router';
import { PaymentsModalComponent } from 'src/app/shared/components/payments-modal/payments-modal.component';


export abstract class OrderDetailsModalComponent implements OnInit {
  @Input() modalObj: any;
  orderObj;
  outletObj;
  itemTotal = 0;
  isOrderView = false;
  billingObj;
  taxAndChargesAmt = 0;
  showPaymentMethods = true;
  paymentThrough = 'Paid via';
  orderDate;
  gst = 0;
  constructor(
    public activeModal: NgbActiveModal,
    private orderService: OrderService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit() {
    console.log(this.modalObj);
    const orderId = this.modalObj;
    this.getOrderDetails(orderId);
    setTimeout(() => {
      document.getElementById('scrollTopUp').scrollIntoView();
    }, 250);
  }


  getOrderDetails(orderId) {
    this.orderService.getOrderDetails(orderId).then((res) => {
      const responseData = res['body']['data'];
      this.orderObj = { ...responseData };
      this.outletObj = this.orderObj.outlets[0];
      this.billingObj = this.orderObj.billing;
      console.log(this.orderObj);
      this.gst = this.billingObj['taxes'][0]['value'];
      this.orderObj.outlets[0]['items'].forEach(obj => {
        this.itemTotal += obj['price'] * obj['quantity'];
      });
      const filteredList = this.orderObj.payments.filter(list => list.paid > 0);
      if (filteredList.length === 0) {
        this.showPaymentMethods = false;
      }
      if (filteredList.length === 1) {
        this.paymentThrough = (filteredList[0].method).toUpperCase();
      }
      if (filteredList.length === 2) {
        this.paymentThrough = `${(filteredList[0].method).toUpperCase()} and ${(filteredList[1].method).toUpperCase()}`
      }
      this.isOrderView = true;
      this.orderDate = this.orderObj.payments[0].date;
      console.log(this.outletObj)
    });
  }

  isOrderComplete(status) {
    return this.orderService.orderIsComplete(status);
  }

  isOrderReject(status) {
    return this.orderService.orderReject(status);
  }

  isOrderOnProcessing(status) {
    return this.orderService.orderOnProcessing(status);
  }

  onOpenTaxAndChargesModal() {
    const taxAndChargesObj = {
      billing: this.billingObj
    }
    const modalRef = this.modalService.open(TaxAndChargesModalComponent, getConfig('TAX_AND_CHARGES_CONFIG'));
    modalRef.componentInstance.modalObj = taxAndChargesObj;
    console.log(modalRef);
    modalRef.result.then(async (res) => {
      console.log(res);
    }).catch((error) => {
      console.log(error);
    });

  }

  taxAndChargesTotal() {
    this.taxAndChargesAmt = 0;
    let chargesAmt = 0;
    if (this.billingObj) {
      this.billingObj['charges'].forEach(obj => {
        chargesAmt += Number(obj.value);
      });
      return this.taxAndChargesAmt = Number(this.billingObj['taxes'][0]['value']) + chargesAmt;
    }
  }


  onReorderClick() { }

  onTrackOrderClick() {
    const modalObj = {};
    modalObj['orderId'] = this.orderObj.orderId;

    const modalRef = this.modalService.open(OrderTrackModalComponent, getConfig('FROM_RIGHT_MODAL'));
    modalRef.componentInstance.modalObj = modalObj;
  }

  getChargesName(charge) {
    return CHARGES[charge];
  }

  isTaxCharges(changes) {
    // console.log(changes)
    if (Number(changes.value) === 0 && changes.name === 'packingCharges') {
      return false;
    } else if (changes.name === 'deliveryCharge') {
      return true;
    } else if (Number(changes.value) === 0 && changes.name === 'restaurantCharges') {
      return false;
    }
    return true;
  }

  openHelpPage() {
    this.activeModal.close();
    const obj = {
      outletName: this.outletObj.outletName,
      orderNum: this.orderObj.orderNum,
      navigateToHistory: true
    }
    const navigationExtras: NavigationExtras = {
      queryParams: obj
    }
    this.router.navigate(["/help-faqs/help-and-support"], navigationExtras);
  }

  async openPaymentsModal() {
    const paymentMethods = await this.orderObj.payments.filter(list => list.paid > 0);
    const modalRef = this.modalService.open(PaymentsModalComponent, getConfig('TAX_AND_CHARGES_CONFIG'));
    modalRef.componentInstance.paymentMethods = paymentMethods;
    console.log(modalRef);
    modalRef.result.then(async (res) => {
      console.log(res);
    }).catch((error) => {
      console.log(error);
    });
  }

  onClose() {
    this.activeModal.close();
  }

  reOrderbtn() {
    // const navigationExtra:NavigationExtras = {
    //   state:{outletId: this.outletObj.outletId},
    //   queryParams:{navigateTo: ROUTES_STR.home },
    // }
    this.router.navigate(["/restaurants/rest-details"], { queryParams: { outletId: this.outletObj.outletId, navigateTo: ROUTES_STR.home } });
    this.activeModal.close();
  }
}
