import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from '../../../service/order.service';
import { RatingComponent } from '../rating-modal.component';

@Component({
  selector: 'app-rating-modal-desktop',
  templateUrl: './rating-modal-desktop.component.html',
  styleUrls: ['./rating-modal-desktop.component.scss']
})
export class RatingModalDesktopComponent extends RatingComponent {

  constructor(
    orderService: OrderService,
    modalService: NgbModal,
    router: Router,
    activeModal: NgbActiveModal,
  ) {
    super(
      orderService,
      modalService,
      router,
      activeModal
    )
  }

}
