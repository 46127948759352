import { ApiRequestService } from './api-request.service';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AppData } from '..';
import { BehaviorSubject } from 'rxjs';
import { PushNotificationsService } from './push-notification.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private apiRequestService: ApiRequestService,
    private pushNotificationService: PushNotificationsService
  ) {
    this.requestPermission();
    this.receiveMessage();
  }

  requestPermission() {
    this.angularFireMessaging.tokenChanges.subscribe(() => {
      this.angularFireMessaging.getToken.subscribe(async (refreshedToken) => {
        console.log('Token refreshed.', refreshedToken);
        AppData.fcmToken = refreshedToken;
        AppData.fcmTokenSub$.next(refreshedToken);
        // if (AppData.userInfoData !== undefined && AppData.userInfoData !== null) {
        //   await this.sendFCMTokenToServer(refreshedToken);
        // }
      });
    });

    this.angularFireMessaging.requestToken.subscribe(async (token) => {
      console.log('FCM TOken', token);
      AppData.fcmToken = token;
      AppData.fcmTokenSub$.next(token);
      if (AppData.userInfoData !== undefined && AppData.userInfoData !== null) {
        await this.sendFCMTokenToServer(token);
      }
    }, (err) => {
      console.error('Unable to get permission to notify.', err);
    });
  }


  //hook method when new notification received in foreground

  receiveMessage() {
    console.log('hook registered');
    this.angularFireMessaging.messages.subscribe((payload) => {
      const notificationObj = payload;
      // this.notifyMe(notificationObj);
      console.log("new message received. ", payload);
      this.pushNotificationService
        .generateNotification(notificationObj['notification']['title'], notificationObj['notification']['body'], notificationObj['data']);
      this.currentMessage.next(payload);
    });
  }

  sendFCMTokenToServer(refreshedToken) {
    const body = {
      'newToken': refreshedToken,
      'userId': AppData.userInfoData['userId']
    }
    this.apiRequestService.patch('sendFCMToken', '', body);
  }

  notifyMe(notificationObj) {
    navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
      console.log(registration);

      registration.showNotification("Helo shahid");
    });
    //   // Let's check if the browser supports notifications
    //   if (!("Notification" in window)) {
    //     alert("This browser does not support desktop notification");
    //   }

    //   // Let's check whether notification permissions have already been granted
    //   else if (Notification.permission === "granted") {
    //     // If it's okay let's create a notification
    //     console.log('------------------>7777777');
    //     this.pushNotificationService.generateNotification(notificationObj['notification']['title'],
    //       notificationObj['notification']['body']);
    //   }

    //   // Otherwise, we need to ask the user for permission
    //   else if (Notification.permission !== "denied") {
    //     Notification.requestPermission().then(function (permission) {
    //       // If the user accepts, let's create a notification
    //       if (permission === "granted") {
    //         this.pushNotificationService.generateNotification(notificationObj['notification']['title'],
    //           notificationObj['notification']['body']);
    //           console.log('------------------>8888888888');

    //       }
    //     });
    //   }

    //   // At last, if the user has denied notifications, and you 
    //   // want to be respectful there is no need to bother them any more.
  }
}
