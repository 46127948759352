import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ACCOUNT_ITEMS, getConfig, NAVIGATE_THROUGHT_NOTIFICATION, ROUTES_STR } from 'src/app/core/constants/app-constants';
import { OrderTrackModalComponent } from 'src/app/modules/account/component/order-track-modal/mobile/order-track-modal.component';
import { ApplicationStateService } from './application-state.service';
@Injectable({
	providedIn: 'root'
})
export class PushNotificationsService {
	public permission: Permission;
	orderModel: any;
	constructor(
		private router: Router,
		private modalService: NgbModal
	) {
		console.log('push Notification service');
		this.permission = this.isSupported() ? 'default' : 'denied';
		console.log(this.permission);
	}
	public isSupported(): boolean {
		return 'Notification' in window;
	}
	requestPermission(): void {
		const self = this;
		if ('Notification' in window) {
			Notification.requestPermission(function (status) {
				console.log('push Notification service', status);

				return self.permission = status;
			});
		}
	}
	create(title: string, options?: PushNotification, data?: any): any {
		const self = this;
		return new Observable(function (obs) {
			if (!('Notification' in window)) {
				console.log('Notifications are not available in this environment');
				obs.complete();
			}
			if (self.permission !== 'granted') {
				console.log('The user hasn\'t granted you permission to send push notifications');
				obs.complete();
			}
			const _notify = new Notification(title, options);
			_notify.onshow = function (e) {
				return obs.next({
					notification: _notify,
					event: e
				});
			};
			_notify.onclick = function (e) {
				console.log('inside click', data);
				if (!ApplicationStateService.isMobileResolution && data.destination) {
					let options = {};
					if (data.destination === 'order_tracking') {
						const modalRef = self.modalService.open(OrderTrackModalComponent, getConfig('ORDER_TRACK_MODAL'));
						modalRef.componentInstance.modalObj = data;

					} else if (data.destination === 'promo_outlet') {
						options['promoId'] = data.promoId;
						options['title'] = data.name;

					} else if (data.destination === 'promo_dish') {
						options['promoId'] = data.promoId;
						options['title'] = data.name;

					} else if (data.destination === 'dish' || data.destination === 'outlet') {
						options['outletId'] = data.outletId;

					} else if (data.destination === 'offer') {
						options['name'] = 'routeType';
						options['OFFERS'] = 'OFFERS';

					} else if (data.destination === 'scratch_card' || data.destination === 'reward') {
						options['from'] = ROUTES_STR.home;
					}

					const navigationExtras: NavigationExtras = {
						queryParams: options
					}

					if (data.destination !== 'order_tracking' && data.destination !== 'url' && data.destination !== 'update') {
						self.modalService.dismissAll();
						if (Object.keys(options).length > 0) {
							self.router.navigate([NAVIGATE_THROUGHT_NOTIFICATION[data.destination]], navigationExtras);
						} else {
							if (NAVIGATE_THROUGHT_NOTIFICATION[data.destination] === ROUTES_STR.account) {
								let accountOptions = {};
								if (data.destination === 'reward') {
									const selectedAccountItemObj = ACCOUNT_ITEMS.find(itemObj => itemObj.type === 'MY_REWARDS');
									accountOptions['name'] = selectedAccountItemObj.name;
									accountOptions['type'] = selectedAccountItemObj.type;
								} else if (data.destination === 'wallet') {
									const selectedAccountItemObj = ACCOUNT_ITEMS.find(itemObj => itemObj.type === 'MY_WALLET');
									accountOptions['name'] = selectedAccountItemObj.name;
									accountOptions['type'] = selectedAccountItemObj.type;
								}

								self.router.navigate([NAVIGATE_THROUGHT_NOTIFICATION[data.destination]], { queryParams: accountOptions });

							} else {
								self.router.navigate([NAVIGATE_THROUGHT_NOTIFICATION[data.destination]]);
							}
						}
					}

					if (data.destination === 'url') {
						// this.iab.create(url, '_system');
					}


					// if (destination === "scratch_card") {
					// 	self.modalService.dismissAll();
					// 	const navigationExtras: NavigationExtras = {
					// 		queryParams: { from: ROUTES_STR.home }
					// 	}
					// 	self.router.navigate(['/earned-scratch-cards'], navigationExtras);
					// }
				}
				return obs.next({
					notification: _notify,
					event: e
				});
			};
			_notify.onerror = function (e) {
				return obs.error({
					notification: _notify,
					event: e
				});
			};
			_notify.onclose = function () {
				return obs.complete();
			};
		});
	}


	generateNotification(title: string, body: string, destination?: any): void {
		console.log('notification generated');
		const self = this;
		const options = {
			body: body,
			icon: 'assets/icon/favicon-new.png'
		};
		const notify = self.create(title, options, destination).subscribe();
	}
}
export declare type Permission = 'denied' | 'granted' | 'default';
export interface PushNotification {
	body?: string;
	icon?: string;
	tag?: string;
	data?: any;
	renotify?: boolean;
	silent?: boolean;
	sound?: string;
	noscreen?: boolean;
	sticky?: boolean;
	dir?: 'auto' | 'ltr' | 'rtl';
	lang?: string;
	vibrate?: number[];
}
