import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ErrorMessage } from 'src/app/core/constants/validation-msg-constants';
import { AppData, ToastService } from 'src/app/shared';
import { ADDRESS_BY_CITY, COMMON_IMG, ROUTES_STR } from 'src/app/core/constants/app-constants';
import { AddressService } from 'src/app/shared/components/add-address-modal/service/address.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delivery-location-modal',
  templateUrl: './delivery-location-modal.component.html',
  styleUrls: ['./delivery-location-modal.component.scss']
})
export class DeliveryLocationModalComponent implements OnInit {
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  fullAddress: string;
  lat: number;
  lng: number;
  isLocationSearch = true;
  addressOptionsCity = ADDRESS_BY_CITY;

  addressList = [];
  locality = '';
  city = '';
  state = '';
  country = '';
  pincode = '';
  googleLocation = '';
  closeImg = '';
  constructor(
    private addressService: AddressService,
    public activeModal: NgbActiveModal,
    private toastService: ToastService,
    private router: Router,
  ) {
    this.addressService.init(this.setLocality.bind(this), this.setLatLng.bind(this),
      this.setFullAddress.bind(this), this.setCityStateCountry.bind(this), this.setGoogleLocation.bind(this));
    if (AppData.token) {
      this.getAllAddress();
    }
    if (ApplicationStateService.isMobileResolution) {
      this.closeImg = COMMON_IMG.black;
    } else {
      this.closeImg = COMMON_IMG.cross;
    }
  }

  ngOnInit() { }

  setLatLng(lat, lng) {
    this.lat = lat;
    this.lng = lng;
  }
  setLocality(val) {
    this.locality = val;
  }

  setGoogleLocation(val) {
    this.googleLocation = val;
  }

  setCityStateCountry(city, state, country, pinCode) {
    this.city = city;
    this.state = state;
    this.country = country;
    this.pincode = pinCode;
  }

  setFullAddress(val) {
    this.fullAddress = val;
  }

  // get your location
  getAllAddress() {
    this.addressService.getAddressList().then((res) => {
      const responseData = res['body']['data'];
      console.log(responseData);
      this.addressList = responseData;
    });
  }


  //
  async handleAddressChange(address: Address) {
    // console.log((JSON.stringify(address)))
    this.fullAddress = address.formatted_address;
    const obj = JSON.stringify(address.geometry.location);
    this.lat = JSON.parse(obj).lat;
    this.lng = JSON.parse(obj).lng;
    console.log(this.lat, this.lng)
    await this.addressService.getCityStateCountry(address.address_components);
    await this.addressService.getLocality(address.address_components);
    AppData.currentAddressSub$.next(await this.createLocationObj());
    this.activeModal.close(await this.createLocationObj());
    this.router.navigate([ROUTES_STR.home])
  }

  onGetLatLong(event) {
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.getLocBaseOnLatLng(this.lat, this.lng);
  }

  // Google Map Marker Dragging code
  markerDragEnd(event: any) {
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.getLocBaseOnLatLng(this.lat, this.lng);
  }

  //for get current location
  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.getLocBaseOnLatLng(this.lat, this.lng);
        // AppData.currentAddressSub$.next('');
      });
    }
  }

  // get location based on lat and lng
  getLocBaseOnLatLng(lat, lng) {
    this.addressService.currentLocation(lat, lng).then(async (res) => {
      const responseData = res['body']['results'];
      console.log(JSON.stringify(responseData));
      await this.setFullAddress(responseData[0].formatted_address);
      await this.addressService.getLocality(responseData[0].address_components);
      await this.addressService.getCityStateCountry(responseData[0].address_components);
      AppData.currentAddressSub$.next(await this.createLocationObj());
      this.activeModal.close(await this.createLocationObj());
      this.router.navigate([ROUTES_STR.home]);
    });
  }

  // for create obj for set current location
  createLocationObj() {
    const body = {
      'addressTag': this.locality,
      'fullAddress': this.fullAddress,
      'city': this.city,
      'country': this.country,
      'state': this.state,
      'pincode': this.pincode,
      'locality': this.locality,
      'longLat': {
        'coordinates': [this.lng, this.lat]
      }
    }
    return body;
  }

  // updateLocationObj() {
  //   const body = {
  //     locality: this.locality,
  //     city: this.city,
  //     coordinates: [this.lng, this.lat]
  //   }
  //   return body;
  // }
  //for get location permission
  onGetCurrentLoc() {
    if (navigator['permissions'] && navigator['permissions'].query) {
      navigator['permissions'].query({ name: 'geolocation' }).then(async (result) => {
        if (result.state === 'granted') {
          console.log(this);
          this.getCurrentLocation();
        } else if (result.state === 'prompt') {
          console.log('prompt');
          this.getCurrentLocation();
        } else if (result.state === 'denied') {
          console.log('denied');
          this.toastService.presentToast(ErrorMessage.LOCATION_PERMISSION_ALERT_MSG, 'Error', 'error');
        }
      });
    }
  }

  async onLocationListItemClick(obj) {
    AppData.currentAddressSub$.next(obj);
    this.activeModal.close(obj);
    this.router.navigate([ROUTES_STR.home]);
  }
}
