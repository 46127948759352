import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorMessage } from 'src/app/core/constants/validation-msg-constants';
import { ToastService } from '../..';
import { copyTextToClipboard } from '../../utils/common-functions';

@Component({
    selector: 'app-scratch-card-mobile-info.',
    templateUrl: './scratch-card-mobile-info.component.html',
    styleUrls: ['./scratch-card-mobile-info.component.scss']
})
export class ScratchCardMobileInfoComponent implements OnInit {
    navigatedData;
    scratchedCard;

    constructor(activatedRoute: Router,
        private toast: ToastService,
        private router: Router) { 
       this.navigatedData =  activatedRoute.getCurrentNavigation().extras.state;
        console.log(this.navigatedData.data.scratchCard);
        this.scratchedCard = this.navigatedData.data.scratchCard;
    }

    ngOnInit(): void { }

    copyCode() {
        console.log('copied');
        copyTextToClipboard(this.scratchedCard.action.couponCode).then(() => {
          this.toast.presentToast(ErrorMessage.CODE_COPIED_MSG, '', 'success');
        }).catch(() => {
          this.toast.presentToast(ErrorMessage.FAILED_TO_COPY_MSG, '', 'success');
        });
    
    }

    redeemNow(){
    this.router.navigate(['earned-scratch-cards']);
    }
    
    onBack(){
        this.router.navigate(['earned-scratch-cards']);
    }
}
