import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from '../../../service/order.service';
import { OrderDetailsModalComponent } from '../order-details-modal.component';

@Component({
  selector: 'app-order-details-desktop',
  templateUrl: './order-details-desktop.component.html',
  styleUrls: ['./order-details-desktop.component.scss']
})
export class OrderDetailsDesktopComponent extends OrderDetailsModalComponent {

  constructor(
    activeModal: NgbActiveModal,
    orderService: OrderService,
    modalService: NgbModal,
    router: Router
  ) {
    super(activeModal,
      orderService,
      modalService,
      router,)
   }

}
