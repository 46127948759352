import { ApiRequestService } from './../../../shared/services/api-request.service';
import { Injectable } from '@angular/core';
import { AppData } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class AccountService {


  rakingRulesList = [
    {
      classRaking: 'Gold Class',
      icon: 'assets/icon/account/gold-class.svg',
      des: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor'
    },
    {
      classRaking: 'Silver Class',
      icon: 'assets/icon/account/silver-class.svg',
      des: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor'
    },
    {
      classRaking: 'Bronze Class',
      icon: 'assets/icon/account/bronze-class.svg',
      des: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor'
    }
  ];
  rewardEarningList = [
    { earnPoints: '300' }, { earnPoints: '400' }, { earnPoints: '240' }, { earnPoints: '300' }, { earnPoints: '300' }, { earnPoints: '300' }
  ];
  terms = [
    'The maximum discount to avail is Rs 200',
    'Offer valid twice per user',
    'Offer valid on a minimum order value of Rs 600',
    'Offer valid on all CITI Bank Credit and Debit Cards',
    'Other T&Cs may apply',
    'Offer valid till Dec 29.'
  ];
  offerList = [
    {
      couponCode: 'CITIFOODI',
      discount: '20%',
      couponHeader: 'Get 20% discount using Citi Bank Cards',
      description: 'Use code CITIFOODI & get 20% discount up to Rs 200 on order Rs 600 & above. Offer valid on weekdays.',
      terms: this.terms,
    },
    {
      couponCode: 'CITIFOODI',
      discount: '20%',
      couponHeader: 'Get 20% discount using Citi Bank Cards',
      description: 'Use code CITIFOODI & get 20% discount up to Rs 200 on order Rs 600 & above. Offer valid on weekdays.',
      terms: this.terms,
    },
    {
      couponCode: 'CITIFOODI',
      discount: '20%',
      couponHeader: 'Get 20% discount using Citi Bank Cards',
      description: 'Use code CITIFOODI & get 20% discount up to Rs 200 on order Rs 600 & above. Offer valid on weekdays.',
      terms: this.terms,
    },
    {
      couponCode: 'CITIFOODI',
      discount: '20%',
      couponHeader: 'Get 20% discount using Citi Bank Cards',
      description: 'Use code CITIFOODI & get 20% discount up to Rs 200 on order Rs 600 & above. Offer valid on weekdays.',
      terms: this.terms,
    }
  ];

  constructor(private apiRequestService: ApiRequestService) { }


  public getAddressList() {
    return this.apiRequestService.get('getAddress');
  }

  public getFoodPreferences() {
    return this.apiRequestService.get('getFoodPreferences');
  }

  public foodPreferencesTags(reqBody) {
    return this.apiRequestService.post('foodPreferencesTags', reqBody);
  }

  public updateFoodTag(reqBody, userId) {
    return this.apiRequestService.post('updateFoodTags', reqBody, '', '?id=' + userId + '&action=update');
  }

  public getFavoritesFoodList(userId) {
    return this.apiRequestService.get('getFavoritesFood', '?id=' + userId + '&list=restFavorites&skip=' + 0);
  }

  public updateProfile(body) {
    return this.apiRequestService.post('updateFoodTags', body, '', `?action=update`);
  }

  public getSelectedFoodPreferences() {
    return this.apiRequestService.get('getSelectedFoodPreferences')
  }

  public getWalletPoints(mobileNum) {
    return this.apiRequestService.get('getWalletPoints', `?mobile=${mobileNum}`);
  }

  public getTransactionHistory(skip, limit, mobileNum) {
    return this.apiRequestService.get('getTransactionHistory', `?skip=${skip}&limit=${limit}&mobile=${mobileNum}`)
  }

  public verifyUPI(upi) {
    return this.apiRequestService.get('verifyUPI', '?vpa=' + upi);
  }

  public withdrawMoney(reqBody) {
    return this.apiRequestService.post('withdrawMoney', reqBody);
  }

  getScratchInfo() {
    return this.apiRequestService.get('getScratchInfo');
  }

  getEarnedScratchCards(skip, limit) {
    return this.apiRequestService.get('getEarnedScratchCards', `?skip=${skip}&limit=${limit}`);
  }

  redeemScratchCard(scratchId) {
    return this.apiRequestService.put('redeemScratchCard', `?scratchId=${scratchId}&mobile=${AppData.userInfoData.mobile}`, '');
  }

  getScratchSummary() {
    return this.apiRequestService.get('getScratchSummary');
  }

  public logoutApp(reqBody) {
    return this.apiRequestService.post('logout', reqBody);
  }
}
