import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OfferService } from './service/offer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppData } from 'src/app/shared';
import { Input, OnDestroy, OnInit } from '@angular/core';
import { ROUTES_STR } from 'src/app/core/constants/app-constants';
import { Subscription } from 'rxjs';
export abstract class OfferComponent implements OnInit, OnDestroy {
  @Input() dOfferObj: any;
  titleTxt = '';
  navUrl = '';
  viewType = '';
  offerList;
  enterCode: '';
  outletId = '';
  cartId = '';
  codeInvalidText = '';
  logOutSub = new Subscription();

  constructor(
    private offerService: OfferService,
    private route: ActivatedRoute,
    private router: Router,
    public activeModal: NgbActiveModal) {
    this.route.queryParams.subscribe(param => {
      this.viewType = param['routeType'];
      this.outletId = param['outletId'];
      this.cartId = param['cartId'];
      if (param['routeType'] === 'CART') {
        this.titleTxt = 'Apply Coupon';
        this.navUrl = ROUTES_STR.cart;
      } else {
        this.titleTxt = 'Offers';
        this.navUrl = ROUTES_STR.home;
        this.outletId = '';
        this.cartId = '';
      }
    });

    // if (this.viewType !== 'OFFER_MODAL_ON_CART') {
    //   this.getAllCoupons();
    // }
    this.logOutSub = AppData.userInfoSub$.subscribe((userInfo) => {
      console.log(userInfo);
      if (userInfo && userInfo['isTemporary']) {
        this.router.navigate([ROUTES_STR.home]);
      }
    })
  }

  ngOnInit() {
    console.log(this.dOfferObj);
    if (this.dOfferObj) {
      this.cartId = this.dOfferObj.cartId;
      this.outletId = this.dOfferObj.outletId;
      this.viewType = this.dOfferObj.routeType;
    }
    this.getAllCoupons();
  }



  getAllCoupons() {
    this.offerService.getAllCoupon(this.cartId).then((res) => {
      const responseData = res['body']['data'];
      console.log(responseData);
      this.offerList = responseData;
      console.log(this.offerList);

    });
  }

  onApplyCoupon(code) {
    console.log(code);
    this.applyCouponCode(code);
  }

  onEnterCouponCode() {
    console.log(this.enterCode);
    this.applyCouponCode(this.enterCode.toUpperCase());
  }

  applyCouponCode(code) {
    const resBody = {
      outletId: this.outletId,
      cartId: AppData.cartId,
      couponCode: code
    }
    this.offerService.applyCoupon(resBody).then((res) => {
      if (this.viewType === 'OFFER_MODAL_ON_CART') {
        this.activeModal.close({ isCouponApplied: true });
      } else {
        this.router.navigate([ROUTES_STR.cart], { queryParams: { type: 'APPLY_COUPON' } });
      }
    }).catch((err) => {
      this.codeInvalidText = err['error']['data']['message'];
    });
  }

  onKeyUpCouponInput() {
    this.codeInvalidText = '';
  }

  ngOnDestroy() {
    this.logOutSub.unsubscribe();
  }
}
