import { environment } from './../../../environments/environment.prod';
import { StorageService } from './../../core/services/storage.service';
import { ApiRequestService } from './api-request.service';
import { Injectable } from '@angular/core';
import { AppData } from './app-data.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  lat: number;
  lng: number;
  constructor(private apiRequestService: ApiRequestService, private storageService: StorageService) {

    if (this.storageService.getItem("currentLocation") === null) {
      // this.getCurrentLocation();
    }
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        console.log(position.coords.latitude, position.coords.longitude);
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.currentLocation(this.lat, this.lng);
      });
    }
  }
  // for create obj for set current location
  createLocationObj(responseData) {
    const body = {
      'addressTag': this.setAddressFieldData(responseData),
      'fullAddress': '',
      'longLat': {
        'coordinates': [this.lng, this.lat]
      }
    }
    return body;
  }

  currentLocation(lat, lng) {
    this.apiRequestService
      .get('getCurrentAddress', '?latlng=' + lat + ',' + lng + '&key=' + environment.mapApiKey, '')
      .then(async (res) => {
        const responseData = res['body']['results'];
        console.log(responseData);
        const addressObj = await this.createLocationObj(responseData);
        console.log(addressObj);
        AppData.currentAddressSub$.next(addressObj);
      });
  }

  setAddressFieldData(responseData) {
    let locality = '';
    const addressList = responseData[0].address_components;
    const politicalObj = addressList.find(item => item.types[0] === 'political');
    if (politicalObj) {
      locality = politicalObj['long_name'];
    } else {
      const subLocalityObj = addressList.find(item => item.types[0] === 'sublocality_level_1');
      if (subLocalityObj !== undefined) {
        locality = subLocalityObj['long_name'];
      } else {
        const subLocalityObj = addressList.find(item => item.types[0] === 'sublocality_level_2');
        if (subLocalityObj !== undefined) {
          locality = subLocalityObj['long_name'];
        } else {
          const localityObj = addressList.find(item => item.types[0] === 'locality');
          if (localityObj !== undefined) {
            locality = localityObj['long_name'];
          }
        }
      }
    }
    return locality;
  }

  setAndUpdateLocation() {
    const savedAddress = AppData.currentAddressObj;
    if (savedAddress) {
      const reqBody = {
        locality: savedAddress.locality ? savedAddress.locality : savedAddress.city,
        city: savedAddress.city,
        coordinates: savedAddress.longLat.coordinates
      }

      this.apiRequestService.post('updateLocation', reqBody);
    }
  }
}
