import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SignUpComponent } from './component/sign-up/sign-up.component';
import { OtpComponent } from './component/otp/otp.component';
import { LoginSignupModalComponent } from './login-signup-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './component/login/login.component';
import { DirectiveModule } from 'src/app/core/directives/module/directive/directive.module';



@NgModule({
  declarations: [
    LoginSignupModalComponent,
    LoginComponent,
    OtpComponent,
    SignUpComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DirectiveModule
  ],
  exports: [
    LoginSignupModalComponent
  ],
  entryComponents: [LoginSignupModalComponent]
})
export class LoignSignupModalModule { }
