import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScratchCardMobileInfoComponent } from './scratch-card-mobile-info.component';
import { RouterModule, Routes } from '@angular/router';

const route: Routes = [
    {
      path: '',
      component: ScratchCardMobileInfoComponent
    }
  ];

@NgModule({
    declarations: [ScratchCardMobileInfoComponent],
    imports: [ CommonModule,
     RouterModule.forChild(route) ],
    exports: [ScratchCardMobileInfoComponent],
    providers: [],
})
export class ScratchCardMobileInfoModule {}