import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { deliveryTypeMessage } from "src/app/core/constants/app-constants";

@Component({
  selector: "app-delvery-type-modal",
  templateUrl: "./delvery-type-modal.component.html",
  styleUrls: ["./delvery-type-modal.component.scss"],
})
export class DelveryTypeModalComponent implements OnInit {
  @Input() modalObj;
  deliveryType: [];
  activeDeliveryType:string;
  deliveryTypeMessage = deliveryTypeMessage;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    console.log(this.modalObj);
    this.activeDeliveryType = this.modalObj;
  }
  onSelectDeliveryType() {
    console.log(this.deliveryType);
    this.activeModal.close(this.deliveryType);
  }
  onChangeDeliveryType(obj) {
    this.deliveryType = obj;
  }
}
