import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-signup-modal',
  templateUrl: './login-signup-modal.component.html',
  styleUrls: ['./login-signup-modal.component.scss']
})
export class LoginSignupModalComponent implements OnInit {
  isLoginView = true;
  isOTPView = false;
  isSignUpView = false;
  changeMobile: any;
  loginAndSignUpObj: any;

  constructor(private modalService: NgbModal) { }
  ngOnInit() { }

  //for getting login obj
  onLoginObj(event) {
    console.log(event);
    this.isLoginView = false;
    //If user is new display sign up view
    if (event.userType === 'NEW') {
      this.isSignUpView = true;
      this.changeMobile = event;
    } else {
      //If user is new display otp view
      this.loginAndSignUpObj = event;
      this.isOTPView = true;
    }
  }

  //for change mobile number based on login and sign up view
  changeMobileNum(event) {
    console.log(event);
    if (event.type === 'LOGIN') {
      this.isLoginView = true;
    } else {
      this.isSignUpView = true;
    }
    this.changeMobile = event;
    this.isOTPView = false;
  }

  //for display sign up view
  emitSignUp() {
    this.isLoginView = false;
    this.isSignUpView = true;
  }

  //for display sign in view
  emitSignIn() {
    this.isLoginView = true;
    this.isSignUpView = false;
  }

  // for getting sign up obj
  onSingUpObj(event) {
    this.loginAndSignUpObj = event;
    this.isOTPView = true;
    this.isSignUpView = false
  }

  onCloseLoginModal() {
    this.modalService.dismissAll();
  }

}

