import { LoginService } from '../../service/login.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { getFormValidation, maxNumToBeAllowed } from 'src/app/shared/utils/common-functions';
import { COUNTRY_CODE } from 'src/app/core/constants/app-constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input() onMobileChange: any;
  @Output() onLoginObj = new EventEmitter<any>();
  @Output() onSignUpEmit = new EventEmitter<any>();
  loginForm: FormGroup;
  isSubmitted = false;

  constructor(private fb: FormBuilder, private loginService: LoginService) { }

  ngOnInit() {
    console.log("------->Change Mobile", this.onMobileChange);
    this.initFrom();
    if (this.onMobileChange) {
      this.loginForm.controls['mobile'].setValue(this.onMobileChange.mobile);
    }
  }

  initFrom() {
    this.loginForm = this.fb.group({
      'mobile': ['', [Validators.required, Validators.min(1111111111), Validators.max(9999999999)]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  // for checking valid fields
  getValid(fieldName) {
    return getFormValidation(this.f, fieldName, this.isSubmitted);
  }

  createObjForSendOTP() {
    const body = {
      mobile: this.loginForm['controls']['mobile'].value,
      countryCode: COUNTRY_CODE,
      action: 'login',
      type: 'LOGIN'
    }
    return body;
  }

  // for continue to send otp
  async onContinue() {
    this.isSubmitted = true;
    if (this.loginForm.valid) {
      const reqBody = await this.createObjForSendOTP();
      this.loginService.sendOtp(reqBody).then((res) => {
        const responseData = res['body'];
        console.log(responseData);
        reqBody['userType'] = responseData['code'] === 1003 ? 'NEW' : 'OLD';
        this.onLoginObj.emit(reqBody);
      });
    } else {
      return;
    }
  }

  onSignUp() {
    this.onSignUpEmit.emit();
  }

  onKeyPress(event) {
    return maxNumToBeAllowed(event, 10);
  }
}
