import { ApiRequestService } from '../../../shared/services/api-request.service';
import { Injectable } from "@angular/core";
import { MenuItem } from "../../../shared/models/menu-item";
import { CartCalService } from '../../cart/service/cart-cal.service';
import { AppData } from 'src/app/shared';
import { isOfferPresentWithinDate } from 'src/app/shared/utils/common-functions';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class MenuItemService {
  menuItemModel = new MenuItem();
  isAddonVarientModalOpened = new BehaviorSubject(false);

  constructor(
    private apiRequestService: ApiRequestService,
    private cartCalService: CartCalService) { }

  getMenuItem(slug, userId) {
    return this.apiRequestService.get('getMenu', '?slug=' + slug);
  }

  onDisplayQuantity(menuItems) {
    const plateItems = AppData.yourPlateItems;
    let count = 0;
    if (plateItems && plateItems['outlets'].length > 0) {
      const itemsList = plateItems['outlets'][0]['items'];
      if (itemsList !== null && itemsList.length > 0) {
        if (itemsList[0].outletId === menuItems.outletId) {
          itemsList.forEach((item) => {
            if (item.itemId === menuItems.itemId || item.itemId === menuItems.dishId) {
              count += item.quantity;
            }
          });
        }
        return count;
      }
      return count;
    }
    return count;
  }
onAddOnsVariantDisplayQuantinty(menuItems){
  const plateItems = AppData.yourPlateItems;
    let count = 1;
    if (plateItems && plateItems['outlets'].length > 0) {
      const itemsList = plateItems['outlets'][0]['items'];
      if (itemsList !== null && itemsList.length > 0) {
        if (itemsList[0].outletId === menuItems.outletId) {
          itemsList.forEach((item) => {
            if (item.itemId === menuItems.itemId || item.itemId === menuItems.dishId) {
              count += item.quantity;
            }
          });
        }
        return count;
      }
      return count;
    }
    return count;
}
  filterItemsBasedOnCategory(displayMenuList, type) {
    const menuTempList = JSON.parse(JSON.stringify(displayMenuList));
    const filterItems = JSON.parse(JSON.stringify(this.filterMenuItems(menuTempList, type)));
    return filterItems;
  }

  filterMenuItems(itemsList, type) {
    console.log('---All Item List', itemsList);
    const filterList = [];

    let categoryIndex;
    let subCatIndex;

    itemsList.forEach((item) => {
      const vegItemsList = [];

      const menuItemsObj = { layoutType: item.layoutType };
      if (item.name) {
        menuItemsObj['name'] = item.name;
      }

      item.list.forEach((menu, index) => {
        if (menu.type === 'category') {
          categoryIndex = index;
          subCatIndex = null;
        }

        if (menu.type === 'subCategory') {
          subCatIndex = index;
        }

        if (menu.type === 'item' && menu[type]) {
          if (item.list[categoryIndex]) {
            if (!(vegItemsList.includes(item.list[categoryIndex]))) { vegItemsList.push(item.list[categoryIndex]); }
          }
          if (subCatIndex) {
            if (!(vegItemsList.includes(item.list[subCatIndex]))) { vegItemsList.push(item.list[subCatIndex]); }
          }

          vegItemsList.push(menu);
        }
      });

      menuItemsObj['list'] = vegItemsList;
      filterList.push(JSON.parse(JSON.stringify(menuItemsObj)));
    });

    console.log(filterList);
    return filterList;
  }

  filterCategoryList(vegItemsList) {
    const vegCategoryList = [];
    const vegCategoryListObj = {};
    let count = 0;

    vegItemsList.forEach(vegList => {
      if (vegList.layoutType !== 12) {
        if (vegList.list.length > 0) {
          vegCategoryList.push({ key: vegList.name, value: vegList.list.length });
        }
      } else {
        vegList.list.forEach((list, index) => {

          if (list.type === 'category') {
            vegCategoryListObj['key'] = list.name;
            count = 0;
            vegCategoryListObj['value'] = count;
          }

          if (list.type === 'item') {
            count += 1;
          }

          if (vegList.list[index + 1]) {
            if (vegList.list[index + 1].type === 'category') {
              vegCategoryListObj['value'] = count;
              vegCategoryList.push(JSON.parse(JSON.stringify(vegCategoryListObj)));
            }
          } else {
            vegCategoryListObj['value'] = count;
            vegCategoryList.push(JSON.parse(JSON.stringify(vegCategoryListObj)))
          }
        });
      }
    });

    console.log('veg category list-->', vegCategoryList);
    return vegCategoryList;
  }

  searchInfo(menuList, fieldName, searchStr) {
    let filterList = [];
    filterList = menuList.filter((inputObj) => {
      if (inputObj.type === "item") {
        return inputObj[fieldName].toLowerCase().indexOf(searchStr) >= 0;
      }
    });
    return filterList;
  }

  addFavourite(body) {
    return this.apiRequestService.post('addFavourite', body);
  }

  getOfferPrice(offerList, price) {
    for (var i = 0; i < offerList.length; i++) {
      if (isOfferPresentWithinDate(offerList[i])) {
        return this.cartCalService.calculateDiscount(offerList[i], price);
      }
    }
  }

  filterSearchItemsDesktopView(itemsList, searchInput) {
    console.log('---All Item List', itemsList);
    const filterList = [];

    let categoryIndex;
    let subCatIndex;

    itemsList.forEach((item) => {
      const vegItemsList = [];

      const menuItemsObj = { layoutType: item.layoutType };
      if (item.name) {
        menuItemsObj['name'] = item.name;
      }

      item.list.forEach((menu, index) => {
        if (menu.type === 'category') {
          categoryIndex = index;
          subCatIndex = null;
        }

        if (menu.type === 'subCategory') {
          subCatIndex = index;
        }

        if (menu.type === 'item') {
          if (menu.name.search(new RegExp(searchInput, 'i')) > -1) {
            if (item.list[categoryIndex]) {
              vegItemsList.push(item.list[categoryIndex]);
              categoryIndex = null;
            }
            if (subCatIndex) {
              vegItemsList.push(item.list[subCatIndex]);
              subCatIndex = null;
            }

            vegItemsList.push(menu);
          }
        }
      });

      menuItemsObj['list'] = vegItemsList;
      filterList.push(JSON.parse(JSON.stringify(menuItemsObj)));
    });

    console.log(filterList);
    return filterList;
  }

  filterSearchItemCategories(menuList) {
    const filterCatList = [];
    menuList.forEach((mainMenuList) => {
      if (mainMenuList.layoutType !== 12) {
        if (mainMenuList.list.length > 0) {
          filterCatList.push({ key: mainMenuList.name });
        }
      } else {
        mainMenuList.list.forEach((list) => {
          if (list.type === 'category') {
            filterCatList.push({ key: list.name });
          }
        })
      }
    });

    console.log(filterCatList);
    return filterCatList;
  }
}
