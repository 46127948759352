import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScratchCard, SCRATCH_TYPE } from 'scratchcard-js'
import { getLottieConfig, REDEEM_TYPE, SCRATCH_CARDS } from 'src/app/core/constants/app-constants';
import { ErrorMessage } from 'src/app/core/constants/validation-msg-constants';
import { AccountService } from 'src/app/modules/account/service/account.service';
import { AppData } from '../../services/app-data.service';
import { ApplicationStateService } from '../../services/application-state.service';
import { ToastService } from '../../services/toast.service';
import { copyTextToClipboard, onInvite } from '../../utils/common-functions';

@Component({
  selector: 'app-scratch-card-modal',
  templateUrl: './scratch-card-modal.component.html',
  styleUrls: ['./scratch-card-modal.component.scss']
})
export class ScratchCardModalComponent implements OnInit {

  @Input() modalObj;
  @Output() currentCardObj: EventEmitter<any> = new EventEmitter();
  isMobileRes = false;
  redeemType = REDEEM_TYPE;

  lottieConfigCopy = [
    getLottieConfig('CARD_EARN_CONFIG'),
    getLottieConfig('GIFT_REWARDS_CONFIG'),
    getLottieConfig('SUCCESS_CONFIG')
  ];
  lottieConfig;

  constructor(
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private toast: ToastService,
    private router: Router
  ) {
    this.lottieConfig = JSON.parse(JSON.stringify(this.lottieConfigCopy));
    this.lottieConfig[0].showConfig = true;
    if (ApplicationStateService.isMobileResolution) {
      this.isMobileRes = true;
    } else {
      this.isMobileRes = false;
    }
  }

  ngOnInit() {
    if (this.modalObj.enableCard) {
      setTimeout(() => {
        this.lottieConfig = JSON.parse(JSON.stringify(this.lottieConfigCopy));
        this.lottieConfig[1].showConfig = true;
      }, 3500);

      setTimeout(() => {
        this.lottieConfig = JSON.parse(JSON.stringify(this.lottieConfigCopy));
        this.lottieConfig[2].showConfig = true;
      }, 5000)
    }

    if (this.modalObj.scratchMessage === '') {
      this.modalObj.scratchMessage = 'Better Luck Next Time';
    }

    if (!this.modalObj.scratched && !this.modalObj.enableCard) {
      document.getElementById('wrapper').classList.add('d-block');
      setTimeout(() => {
        this.createNewScratchCard();
      }, 200);
    }

    if (this.modalObj.scratched) {
      document.getElementById('scratched-card').classList.add('d-block');
    }

    console.log(this.modalObj)
  }

  createNewScratchCard() {
    // const scContainer = document.getElementById('sc-container')
    const htmlBackground = `
      <div class="cardamountcss text-center background">
        <img height="100px" width="100px" src="${this.modalObj.image ? this.modalObj.image : 'assets/scratch-cards/icons-on-card/cookie.png'}" />
        <p class="won-amnt mt-1">${this.modalObj.scratchMessage}</p>
      </div>
    `

    const sc = new ScratchCard('#sc-container', {
      scratchType: SCRATCH_TYPE.CIRCLE,
      containerWidth: 220,
      containerHeight: 260,
      imageForwardSrc: SCRATCH_CARDS[this.modalObj.randomCard],
      //imageBackgroundSrc: './assets/images/scratchcard-background.svg',
      htmlBackground: htmlBackground,
      clearZoneRadius: 30,
      percentToFinish: 4,
      callback: () => {
        this.accountService.redeemScratchCard(this.modalObj.scratchId).then(() => {
          this.modalObj.scratched = true;
          this.currentCardObj.emit(this.modalObj);
          if (this.modalObj.amount > 0 || this.modalObj.redeemType === this.redeemType.THIRD_PARTY) {
            setTimeout(() => {
              document.getElementById('bottom-btn').classList.add('d-block');
            }, 500);
            document.getElementById('sc-container').style.border='1px solid black';
            document.getElementById('sc-container').style.borderRadius='9px'
          } else {
            setTimeout(() => {
              this.activeModal.close();
            }, 500);
          }
        });
      }
    })

    sc.init().then(() => {
      sc.canvas.addEventListener('scratch.move', () => {
        let percent = sc.getPercent();
        // console.log(sc);
        // console.log(percent);
      });
    }).catch((err) => {
      console.log(err.message);
    });
  }

  onBottomButtonClick() {
    console.log('clicked');
    if (this.modalObj.action.clickEvent === 'url') {
      window.open(this.modalObj.action.url);
    } else if (this.modalObj.action.clickEvent === 'invite') {
      const textToShow = `
Hey look, I won credits worth ₹${this.modalObj.amount} through Yumzy!\n
It’s India’s youngest Food Delivery App with top Restaurants & quick deliveries.\n
Check out Yumzy through my\n
Personal Invite Link: ${AppData.userInfoData.referralDeepLink} or\n
use my Personal Code: ${AppData.userInfoData.refferalCode} to get assured cashback & exciting coupons.\n
`;
      // const url = AppData.userInfoData.referralDeepLink;

      console.log(AppData.userInfoData);
      console.log(textToShow);
      onInvite(textToShow);
    }
  }

  copyCode() {
    console.log('copied');
    copyTextToClipboard(this.modalObj.action.couponCode).then(() => {
      this.toast.presentToast(ErrorMessage.CODE_COPIED_MSG, '', 'success');
    }).catch(() => {
      this.toast.presentToast(ErrorMessage.FAILED_TO_COPY_MSG, '', 'success');
    });

    event.stopPropagation();
  }

  checkClassToCloseModal(classList) {
    const arrOfClass = classList.value.split(" ");
    return arrOfClass.some(obj => obj === 'close-modal');
  }

  onClose() {
    this.activeModal.close();
  }

  @HostListener('document:click', ['$event']) clickout(event) {
    if(ApplicationStateService.isMobileResolution){
        if (this.checkClassToCloseModal(event.target.classList)) {
            console.log('MODAL CLOSED');
            this.activeModal.close();
    }
  }
  }

  onViewInfoNavigate(scratchCard){
    console.log(scratchCard);
    this.router.navigate(['scratch-info'], {state: {data: {scratchCard}}});
    this.activeModal.close();
  }

}
