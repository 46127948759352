import { SafeHtmlPipe } from './../../../../shared/utils/safe-html.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragAndScrollDirective } from '../../drag-and-scroll.directive';
import { NumberDirective } from '../../numbers-only.directive';
import { RestrictFirstSpaceDirective } from '../../restrict-first-space.directive';
import { TextTrimDirective } from '../../text-trim.directive';
import { ImgFallbackDirective } from 'src/app/shared/utils/img-fallback.directive';
import { SafePipe } from 'src/app/shared/utils/safe.pipe';

const COMPONENTS = [
  DragAndScrollDirective,
  NumberDirective,
  RestrictFirstSpaceDirective,
  TextTrimDirective,
  ImgFallbackDirective,
  SafePipe,
  SafeHtmlPipe
]

@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule
  ],
  exports: [COMPONENTS]
})
export class DirectiveModule { }
