export class Signup {
    private firstName: string;
    private lastName: string;
    private email: string;
    private mobile: string;
    private countryCode: string;

    constructor() {
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.mobile = '';
        this.countryCode = '+91';
    }

    public get _lastName(): string {
        return this.lastName;
    }

    public set _lastName(lastName: string) {
        this.lastName = lastName;
    }
    public get _firstName(): string {
        return this.firstName;
    }

    public set _firstName(firstName: string) {
        this.firstName = firstName;
    }
    public get _email(): string {
        return this.email;
    }

    public set _email(email: string) {
        this.email = email;
    }

    public get _mobile(): string {
        return this.mobile;
    }

    public set _mobile(mobile: string) {
        this.mobile = mobile;
    }

    public get _countryCode(): string {
        return this.countryCode;
    }

    public set _countryCode(countryCode: string) {
        this.countryCode = countryCode;
    }
}
