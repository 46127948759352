import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thankyou-for-rating-modal',
  templateUrl: './thankyou-for-rating-modal.component.html',
  styleUrls: ['./thankyou-for-rating-modal.component.scss']
})
export class ThankyouForRatingModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
