import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStateService {
  static isMobileResolution: boolean;

  constructor() {
    console.log('app state loaded');
    if (window.innerWidth < 768) {
      ApplicationStateService.isMobileResolution = true;
    } else {
      ApplicationStateService.isMobileResolution = false;
    }
  }
}
