import { IdsService } from './shared/services/ids.service';
import { appExitedObj, appStartedCleverTabObj } from 'src/app/shared/utils/clever-tab-functions';

import { AuthService } from './shared/services/auth.service';
import { AppData } from 'src/app/shared/services/app-data.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, } from '@angular/router';
import { StorageService } from './core/services/storage.service';
import { slideInAnimation } from './core/route-animations/route-animation';
import { NotificationService } from './shared/services/notification.service';
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ROUTES_STR, THEME_COLOR } from './core/constants/app-constants';
import { AnalyticsService } from './shared/services/analytics.service';
import { CLEVER_TAB_EVENT } from './core/constants/clever-tab-event-constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit {
  title = 'yumzy-web';
  private history = [];
  count = 0;
  colorTheme = {
    // '--primary-clr': '#40A944',
    // '--secondary-clr' : 'red',
    // '--primary-cta-gradient1': '#40A944',
    // '--primary-cta-gradient2': '#40A944fa',
    // '--category-clr': '#40A9441A',
    // '--white-smoke': '#40A9441A',
    // '--location-clr': '#40A944',
  }
  constructor(
    private storageService: StorageService,
    private appData: AppData,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
    private modalService: NgbModal,
    private analyticsService: AnalyticsService,
    private ar: ActivatedRoute
  ) {

    THEME_COLOR.categorySelected = this.colorTheme['--primary-clr'];
    Object.keys(this.colorTheme).forEach((colorVar) => {
      document.documentElement.style.setProperty(colorVar, this.colorTheme[colorVar]);
    });
  }

  ngOnInit() {

    setTimeout(async () => {
      let callAnalyticEvent = true;

      if (this.router.url.split('?')[0] === ROUTES_STR.cart) {
        this.ar.queryParams.subscribe((params) => {
          if (Object.keys(params).length > 0) {
            callAnalyticEvent = false;
          }
        })
      }

      if (AppData.userId && AppData.currentAddressObj && callAnalyticEvent) {
        this.analyticsService.cleverTapEvent(CLEVER_TAB_EVENT.APP_STARTED, appStartedCleverTabObj(CLEVER_TAB_EVENT.APP_STARTED));
      }
    }, 1000)
  }

  scrollTop(event, outlet) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000)
  }


  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.modalService.dismissAll();
    if (this.router.url === ROUTES_STR.home && !(window.location.hash)) {
      var r = confirm("Are you sure do you want to leave this site?");
      if (r == true) {
        window.open('https://www.google.com/', '_self');
      }
    }
  }


  @HostListener("window:onbeforeunload", ["$event"])
  unloadHandler(event) {
    this.analyticsService.cleverTapEvent(CLEVER_TAB_EVENT.APP_STARTED, appExitedObj());
    // alert('loadded')
    console.log(CLEVER_TAB_EVENT.APP_STARTED, appExitedObj())
  }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeunloadHandler(event) {
  //   return "\o/";
  // }


}
