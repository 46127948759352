import { Component, OnInit, Input } from '@angular/core';
import { AppData } from 'src/app/shared';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-coupon-terms-and-conditions',
  templateUrl: './coupon-terms-and-conditions.component.html',
  styleUrls: ['./coupon-terms-and-conditions.component.scss']
})
export class CouponTermsAndConditionsComponent implements OnInit {
  @Input() termsConditions = [];
  constructor(
    public modalService: NgbModal,
    public activeModal: NgbActiveModal

  ) { }

  ngOnInit() {
  }
termsClose(){
  this.activeModal.close()
}
}
