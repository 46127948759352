export enum CleverTapEventProperty {
    // Common 
    PLATFORM = 'platform',
    STATUS_SUCCESS = 'success',
    STATUS_FAILURE = 'failure',

    //Login Page    
    PHONE = 'phone',


    //Sign Up page
    MOBILE = 'mobile',
    EMAIL = 'email',
    NAME = 'name',

    //Select Location
    LOCATION = 'location',

    //Home Page
 
    // Explore Page
    SEARCH_KEYWORD = 'search_keyword',
    NUMBER_OF_SEARCH_RESULTS = 'number_of_search_results',
    LIST_OF_OUTLETS_COUNT = 'list_of_outlets_count',
    LIST_OF_ITEMS_COUNT = 'list_of_items_count',
}
