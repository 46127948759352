import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/shared';
import { OrderService } from '../../../service/order.service';
import { OrderTrackComponent } from '../order-track.component';

declare function init(event, outletLocation, userLocation): any;
declare var google: any;

@Component({
  selector: 'app-order-track-modal',
  templateUrl: './order-track-modal.component.html',
  styleUrls: ['./order-track-modal.component.scss']
})
export class OrderTrackModalComponent extends OrderTrackComponent {
  constructor(
    orderService: OrderService,
    activeModal: NgbActiveModal,
    toast: ToastService,
    router: Router,
    modalService: NgbModal,
    ar: ActivatedRoute
  ) {
    super(orderService, activeModal, toast, router, modalService, ar);
  }

}
