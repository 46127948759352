import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-filter-options',
  templateUrl: './filter-options.component.html',
  styleUrls: ['./filter-options.component.scss']
})
export class FilterOptionsComponent implements OnInit {
  @Output() passSelectedOptions: EventEmitter<any> = new EventEmitter();
  @Input() public filterOptions;
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  close() {
    this.modalService.dismissAll();
  }
  getCuisine(cuisine) {
    console.log(cuisine);
    // console.log(this.filterOptions.cuisines);
    this.filterOptions.cuisines.find(ele => {
      if (ele.name === cuisine.name) {
        ele.active = !ele.active;
      }
    })
  }

  clearAll() {

  }
  
  apply() {
    this.close()
  }
}
