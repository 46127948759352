import { FormGroup } from '@angular/forms';
import { AppData } from '../services/app-data.service';


export function getFormValidation(formControl, fieldName, isSubmitted?) {
  if (formControl[fieldName].invalid && (formControl[fieldName].dirty || isSubmitted)) {
    return true;
  }
  return false;
}

// for get single group input filed validation
export function getInputFieldValidation(formName, fieldName, isSubmitted?) {
  if (formName.controls[fieldName].invalid && (formName.controls[fieldName].dirty || isSubmitted)) {
    return true;
  }
  return false;
}

// for get nested group input filed validation
export function getNestedGrpFieldValidation(formName, grpFieldName, fieldName, isSubmitted?) {
  if (formName.get(grpFieldName).get(fieldName).invalid && (formName.get(grpFieldName).get(fieldName).dirty || isSubmitted)) {
    return true;
  }
  return false;
}

// filter list
export function filterDataList(tempList, fieldName, searchStr) {
  console.log(tempList);
  return tempList.filter((inputObj) => {
    return inputObj[fieldName].search(new RegExp(searchStr, 'i')) > -1;
  });
}


// custom validator to check that two fields match
export function mustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

export function processTimeString(timeString) {
  let startDateHourString = timeString.substring(0, 2);
  const startDateMinString = timeString.substring(2, 4);
  let startDateAmPmString = 'AM';
  if (Number(startDateHourString) === 12) {
    startDateAmPmString = 'PM';
  } else if (Number(startDateHourString) > 12) {
    startDateHourString = Number(startDateHourString) - 12;
    startDateAmPmString = 'PM';
  } else if (Number(startDateHourString) > 24) {
    startDateHourString = Number(startDateHourString) - 24;
    startDateAmPmString = 'AM';
  }
  return (startDateHourString + ':' + startDateMinString + ' ' + startDateAmPmString);
}

//

export function onScrollHeaderFixed() {
  const distanceY = window.pageYOffset || document.documentElement.scrollTop;
  const shrinkOn = 50;
  const headerEl = document.getElementById('header');
  if (distanceY - shrinkOn >= 100) {
    headerEl.classList.add('fixed');
  } else {
    headerEl.classList.remove('fixed');
  }
}

export function formatOfferDetails(offer) {
  let text = offer.value.toString();
  if (offer.discountType === 'net') {
    text = `Rs. ${text} OFF`;
  } else {
    text += '% OFF';
  }
  if (offer.minOrderAmount && offer.minOrderAmount > 0) {
    text += ` on orders above ₹${offer.minOrderAmount}`;
  }
  if (offer.maxDiscount && offer.maxDiscount > 0) {
    text += ` upto ₹${offer.maxDiscount}`;
  } else {
    text = `Flat ${text}`;
  }
  // text += '.';// Applies automatically in cart.
  return text;
}

export function getOutletOfferBasedOnTime(offerList) {
  if (offerList.length > 0) {
    for (let i = 0; i < offerList.length; i++) {
      if (isOfferPresentWithinDate(offerList[i])) {
        return formatOfferDetails(offerList[i]);
      }
    }
  }
  return '';
}


export function makeCuisinesStr(cuisines, maxLimit) {
  const cuisineStr = cuisines.slice(0, maxLimit).join(', ');
  return cuisines.length > maxLimit ? cuisineStr + ',...' : cuisineStr;
}

export function makeCuisinesMoreThen3Str(cuisines) {
  const cuisineStr = cuisines.slice(0, 3).join(', ');
  return cuisines.length > 3 ? cuisineStr + ',...' : cuisineStr;
}


export function capitalizeFirstString(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function makeAddonsStr(addonsList) {
  let addonsText = ' ';
  addonsList.forEach(obj => {
    obj.options.map(opt => addonsText += opt.optionName + ', ');
  });
  const addonsStr = addonsText.substring(0, addonsText.length - 2);
  return addonsStr;
}

export function isOfferPresentWithinDate(offerList) {
  const today = new Date();
  const todayTime = String(today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()) + String(today.getMinutes());

  if (today.toISOString() >= offerList.startDate && today.toISOString() <= offerList.endDate) {
    if (todayTime >= offerList.startTime && todayTime <= offerList.endTime) {
      return true;
    }
  }
  return false;
}

export async function copyTextToClipboard(text) {
  try {
    await navigator.clipboard.writeText(`${text}`);
  } catch (err) {
    return err;
  }
}

export function maxNumToBeAllowed(event, maxLen) {
  if (event.target.value.length >= maxLen) {
    return false;
  }
  return true;
}

export function dynamicFontSize(textLength) {
  const baseSize = 9;
  if (textLength >= baseSize) {
    textLength = baseSize - 2;
  }
  const fontSize = baseSize - textLength
  return `${fontSize}vw`;
}

export function onInvite(textToShow, url = "") {
  console.log(textToShow);
  console.log(url);

  let newVariable = (window.navigator as any);
  if (newVariable.share) {
    newVariable.share({
      text: textToShow,
      url: url,
    })
      .then(() => {
        console.log('Successful share');
      })
      .catch((error) => console.log('Error sharing', error));
  } else {
    alert('share not supported');
  }
}

export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr)
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function setWithExpiry(key, value, ttl) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item));
}
