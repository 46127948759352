import { AppData } from './../../../../shared/services/app-data.service';
import { Router } from '@angular/router';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ROUTES_STR } from 'src/app/core/constants/app-constants';

@Component({
  selector: 'app-top-header-mobile',
  templateUrl: './top-header-mobile.component.html',
  styleUrls: ['./top-header-mobile.component.scss']
})
export class TopHeaderMobileComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() url: string;
  noBorder = false;
  constructor(private router: Router) { }

  ngOnInit() {
    this.removeBorder();
  }

  ngOnChanges() {
    this.removeBorder();
  }

  removeBorder() {
    const temp = this.title.trim();
    // if header has empty title border-bottom should not be visible
    if(temp === "" || temp.length <= 1) {
      this.noBorder = true;
    }
  }

  onBack() {
    if (this.url === ROUTES_STR.home) {
      AppData.bottomNavMobileIndSub$.next(0);
    }
    this.router.navigate([this.url]);
  }

}
