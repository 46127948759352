import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-clear-cart",
  templateUrl: "./clear-cart.component.html",
  styleUrls: ["./clear-cart.component.scss"],
})
export class ClearCartComponent implements OnInit {
  @Input() public outletNames;
  @Input() modalObj: any;
  oldRestName: string;
  newRestName: string
  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    if (this.outletNames) {
      this.oldRestName = this.outletNames.outletInfo.oldOutletName;
      this.newRestName = this.outletNames.outletInfo.newOutletName;
    }
  }

  cancel() {
    this.activeModal.dismiss();
  }

  clearPlate() {
    this.activeModal.close();
  }
}
