import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, ViewEncapsulation } from '@angular/core';
import { ToastService } from 'src/app/shared';
import { FormBuilder, } from '@angular/forms';
import { AddressService } from 'src/app/shared/components/add-address-modal/service/address.service';
import { AddressComponent } from '../address.component';

@Component({
  selector: 'app-add-edit-address-modal',
  templateUrl: './add-edit-address-modal.component.html',
  styleUrls: ['./add-edit-address-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddEditAddressModalComponent extends AddressComponent {

  constructor(
    addressService: AddressService,
    fb: FormBuilder,
    toastService: ToastService,
    activeModal: NgbActiveModal
  ) {
    super(addressService, fb, toastService, activeModal);
  }

}