import { AccountService } from '../../service/account.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-profile-ranking-rules',
  templateUrl: './profile-ranking-rules.component.html',
  styleUrls: ['./profile-ranking-rules.component.scss']
})
export class ProfileRankingRulesComponent implements OnInit {
  profileRakingRules = [];
  constructor(public activeModal: NgbActiveModal, private accountService: AccountService) {
    this.profileRakingRules = accountService.rakingRulesList;
  }

  ngOnInit() {
  }

}
