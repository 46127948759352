import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { IdsService } from 'src/app/shared/services/ids.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { LoginService } from '../../modals/login-signup-modal/service/login.service';
import { OtpComponent } from '../otp.component';

@Component({
  selector: 'app-otp-desktop',
  templateUrl: './otp-desktop.component.html',
  styleUrls: ['./otp-desktop.component.scss']
})
export class OtpDesktopComponent extends OtpComponent {

  constructor(fb: FormBuilder,
    loginService: LoginService,
    modalService: NgbModal,
    activatedRoute: ActivatedRoute,
    router: Router,
    notificationService: NotificationService,
    idsService: IdsService,
    activeModal: NgbActiveModal,
    locationService: LocationService,
    analyticsService: AnalyticsService) {
    super(fb,
      loginService,
      modalService,
      activatedRoute,
      router,
      notificationService,
      idsService,
      activeModal,
      locationService,
      analyticsService)
  }
}
