import { Injectable } from '@angular/core';
import { ApiRequestService } from '../../../services/api-request.service';
import { environment } from 'src/environments/environment';
import { AppData } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  onSetLocality; //method variable
  onSetLatLng;//method variable
  // onGetLocBaseOnLatLng;//method variable
  onSetFullAddress;//method variable
  onsetCityStateCountry; //method variable
  onSetGoogleLocation;
  isErrInGeoLocation = false;

  constructor(private apiRequestService: ApiRequestService) { }

  init(setLocality, setLatLng, setFullAddress, setCityStateCountry, setGoogleLocation) {
    this.onSetLocality = setLocality;
    this.onSetLatLng = setLatLng;
    // this.onGetLocBaseOnLatLng = getLocBaseOnLatLng;
    this.onSetFullAddress = setFullAddress;
    this.onsetCityStateCountry = setCityStateCountry;
    this.onSetGoogleLocation = setGoogleLocation;
  }

  public currentLocation(lat, lng) {
    return this.apiRequestService.get('getCurrentAddress', '?latlng=' + lat + ',' + lng + '&key=' + environment.mapApiKey, '');
  }

  public addAddress(reqObj) {
    return this.apiRequestService.post('address', reqObj);
  }

  public updateAddress(reqObj) {
    return this.apiRequestService.put('address', '', reqObj);
  }

  public getAddressList() {
    return this.apiRequestService.get('getAddress');
  }

  public deleteAddress(addressId) {
    return this.apiRequestService.delete('address', '?id=' + addressId);
  }

  public setAndUpdateUserLoc(reqObj) {
    return this.apiRequestService.post('updateLocation', reqObj);
  }

  getCurrentLocation() {
    if (navigator.geolocation) {

      const geoLocationSuccessCallBack = (position) => {
        console.log(position.coords.latitude, position.coords.longitude);
        this.onSetLatLng(position.coords.latitude, position.coords.longitude)
        this.getLocBaseOnLatLng(position.coords.latitude, position.coords.longitude);
      }

      const geoLocationErrCallBack = () => {
        this.isErrInGeoLocation = true;
      }

      navigator.geolocation.getCurrentPosition(geoLocationSuccessCallBack, geoLocationErrCallBack)
    }
  }

  getLocBaseOnLatLng(lat, lng) {
    this.isErrInGeoLocation = false;
    this.currentLocation(lat, lng).then((res) => {
      const responseData = res['body']['results'];
      console.log(responseData);
      this.onSetFullAddress(responseData[0].formatted_address);
      this.getLocality(responseData[0].address_components);
      this.getCityStateCountry(responseData[0].address_components);
    })
      .catch(() => {
        if (AppData.currentAddressObj) {
          const addressObj = AppData.currentAddressObj;
          this.onSetFullAddress(addressObj.fullAddress);
          this.onSetLocality(addressObj.locality);
          this.onsetCityStateCountry(addressObj.city, addressObj.state, addressObj.country, addressObj.pinCode);
        }
      });
  }

  getLocality(responseData) {
    const addressList = responseData;

    if (addressList.some(item => item.types[0] === 'political')) {
      const politicalObj = addressList.find(item => item.types[0] === 'political');
      this.onSetLocality(politicalObj['long_name']);
    } else if (addressList.some(item => item.types[0] === 'sublocality_level_1')) {
      const subLocalityObj1 = addressList.find(item => item.types[0] === 'sublocality_level_1');
      this.onSetLocality(subLocalityObj1['long_name']);
    } else if (addressList.some(item => item.types[0] === 'sublocality_level_2')) {
      const subLocalityObj2 = addressList.find(item => item.types[0] === 'sublocality_level_2');
      this.onSetLocality(subLocalityObj2['long_name']);
    } else if (addressList.some(item => item.types[0] === 'locality')) {
      const localityObj = addressList.find(item => item.types[0] === 'locality');
      this.onSetLocality(localityObj['long_name']);
    } else if (addressList.some(item => item.types[0] === 'administrative_area_level_2')) {
      const administrativeObj2 = addressList.find(item => item.types[0] === 'administrative_area_level_2');
      this.onSetLocality(administrativeObj2['long_name']);
    } else if (addressList.some(item => item.types[0] === 'administrative_area_level_1')) {
      const administrativeObj1 = addressList.find(item => item.types[0] === 'administrative_area_level_1');
      this.onSetLocality(administrativeObj1['long_name']);
    }


    if (addressList.some(item => item.types[0] === 'sublocality_level_1')) {
      const subLocalityObj1 = addressList.find(item => item.types[0] === 'sublocality_level_1');
      this.onSetGoogleLocation(subLocalityObj1['long_name']);
    }
  }

  getCityStateCountry(address) {
    const addressList = address;
    const countryObj = addressList.find(itm => itm.types[0] === 'country');
    const country = countryObj !== undefined ? countryObj['long_name'] : '';

    const pinCodeObj = addressList.find(itm => itm.types[0] === 'postal_code');
    const pinCode = pinCodeObj !== undefined ? pinCodeObj['long_name'] : '';

    const stateObj = addressList.find(itm => itm.types[0] === 'administrative_area_level_1');
    const state = stateObj !== undefined ? stateObj['long_name'] : '';

    const cityObj = addressList.find(itm => itm.types[0] === 'locality' && itm.types[1] === 'political');
    let city = '';
    if (cityObj) {
      city = cityObj['long_name'];
    } else {
      const cityObj1 = addressList.find(itm => itm.types[0] === 'administrative_area_level_2' && itm.types[1] === 'political');
      city = cityObj1 !== undefined ? cityObj1['long_name'] : '';
    }

    console.log(city, state, country, pinCode);
    this.onsetCityStateCountry(city, state, country, pinCode);
  }
}
