import { Injectable } from '@angular/core';
import { ApiRequestService } from 'src/app/shared';
import { ORDER_STATUS_LIST } from 'src/app/core/constants/app-constants';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private apiRequestService: ApiRequestService) { }

  public getOrderHistoryList(pageSkip) {
    return this.apiRequestService.get('getOrderHistoryList', '?skip=' + pageSkip);
  }

  public getOrderDetails(orderId) {
    return this.apiRequestService.get('getOrderDetails', '?orderId=' + orderId);
  }

  // get order status
  orderReject(status) {
    if (ORDER_STATUS_LIST.REJECTED_LIST.includes(status)) {
      return true;
    }
  }

  orderOnProcessing(status) {
    if (ORDER_STATUS_LIST.PROCESSING_LIST.includes(status)) {
      return true;
    }
  }

  orderIsComplete(status) {
    if (ORDER_STATUS_LIST.DELIVERED_LIST.includes(status)) {
      return true;
    }
  }

  // for getting order date and time
  onGetOrderDateTime(orderDate) {
    const currentDate = new Date()
    const creationDate = new Date(orderDate);
    const timestamp = creationDate.getTime();
    const toFormat = new Date(timestamp)
    if (toFormat.getDate() == currentDate.getDate() && toFormat.getMonth() ==
      currentDate.getMonth() && toFormat.getFullYear() == currentDate.getFullYear()) {
      return 'Today ' + creationDate.toLocaleString('en-US');;
    } else if (toFormat.getDate() == (currentDate.getDate() - 1) && toFormat.getMonth() ==
      currentDate.getMonth() && toFormat.getFullYear() == currentDate.getFullYear()) {
      return 'Yesterday ' + creationDate.toLocaleString('en-US');;
    } else {
      const options = { year: 'numeric', month: 'short', day: '2-digit', hour: 'numeric', minute: 'numeric'};
      return creationDate.toLocaleDateString("en-US", options)
    }
  }

  public getOrderStatus(orderId) {
    return this.apiRequestService.get('getOrderStatus', `?orderId=${orderId}`);
  }

  public rateOrder(body) {
    return this.apiRequestService.patch('rateOrder', '', body);
  }
}
