import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-referral-code-modal',
  templateUrl: './referral-code-modal.component.html',
  styleUrls: ['./referral-code-modal.component.scss']
})
export class ReferralCodeModalComponent implements OnInit {
  referralCodeForm: FormGroup;
  isSubmitted = false;
  @Input() modalObj: any;

  constructor(public activeModal: NgbActiveModal,
    private fb: FormBuilder,) { }

  ngOnInit() {
    console.log(this.modalObj);
    this.initForm();
  }
  initForm() {
    this.referralCodeForm = this.fb.group({
      'referralCode': ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.referralCodeForm.controls; }

  // for checking valid fields
  getValid(fieldName) {
    if (this.f[fieldName].invalid && (this.f[fieldName].dirty || this.isSubmitted)) {
      return true;
    }
    return false;
  }

  onSubmitCode() {
    this.isSubmitted = true;
    if (this.referralCodeForm.valid) {
      console.log(this.referralCodeForm.value);
      this.activeModal.close(this.referralCodeForm.value);
    } else {
      return;
    }
  }

}
