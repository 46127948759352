import { AppData } from "./app-data.service";
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';

export type URL_KEYS =
  //login and sign up
  'sendOtp' | 'verifyOtp' | 'reSendOtp' | 'signUp' | 'cartId' | 'sendFCMToken' | 'referralCode' | 'exploreFirst' |

  'analytics' |
  //Address
  'getCurrentAddress' | 'address' | 'getAddress' | 'updateLocation' |

  //Account
  'getFoodPreferences' | 'foodPreferencesTags' | 'updateFoodTags' | 'getFavoritesFood' | 'getOrderHistoryList' |
  'getOrderDetails' | 'getOrderStatus' | 'help' | 'helpHeadingId' | 'addFavourite' | 'getSelectedFoodPreferences' |
  'rateOrder' | 'getWalletPoints' | 'getTransactionHistory' | 'logout' | 'verifyUPI' | 'withdrawMoney' |
  'getScratchInfo' | 'getEarnedScratchCards' | 'redeemScratchCard' | 'getScratchSummary' |

  // get outlet
  'outletsNearMe' |

  // outletGroup

  'outletGroup' |

  // cart
  'getCart' | 'addItem' | 'updateAddonVariant' |
  'changeItemCount' | 'preOrderCheckout' | 'placeOrder' |
  'postCheckout' | 'getAllCoupon' | 'applyCoupon' | 'removeCoupon' | 'clearPlate' | 'useWallet' | 'unUseWallet' | 'noSelfPickup' | 'selfPickup' |

  //Home
  'allHomeFood' | 'promotionRestaurants' | 'promotionDishs' | 'appStart' | 'disableRatingFromBtmBar' |

  'getMenu' | 'searchFoodItems' | 'getExpandSearchResults'
  ;

const UrlMapping = {
  //login and sign up
  sendOtp: { url: AppData.baseUrl + '/account/sendOtp', showMsg: false, showLoading: true },
  reSendOtp: { url: AppData.baseUrl + '/account/sendOtp', showMsg: true, showLoading: true },
  verifyOtp: { url: AppData.baseUrl + '/account/verifyOtp', showMsg: true, showLoading: true },
  signUp: { url: AppData.baseUrl + '/account/signup', showMsg: true, showLoading: true },
  cartId: { url: AppData.baseUrl + '/cart', showMsg: false, showLoading: false },
  referralCode: { url: AppData.baseUrl + '/coupon/addReferral', showMsg: false, showLoading: false },
  exploreFirst: { url: AppData.baseUrl + '/account/explore', showMsg: false, showLoading: true },

  sendFCMToken: { url: AppData.baseUrl + '/account/fcm', showMsg: false, showLoading: false },
  analytics: { url: AppData.analyticsUrl + '/event/event', showMsg: false, showLoading: false },

  //Address
  address: { url: AppData.baseUrl + '/account/address', showMsg: true, showLoading: true },
  getAddress: { url: AppData.baseUrl + '/account/address', showMsg: false, showLoading: false },
  getCurrentAddress: { url: AppData.googleMapUrl + '/maps/api/geocode/json', showMsg: false, showLoading: false },
  updateLocation: { url: AppData.baseUrl + '/account/location', showMsg: false, showLoading: false },

  //Account
  getFoodPreferences: { url: AppData.baseUrl + '/food/foodPreferencesList', showMsg: false, showLoading: true },
  foodPreferencesTags: { url: AppData.baseUrl + '/food/foodPreferencesTags', showMsg: false, showLoading: true },
  updateFoodTags: { url: AppData.baseUrl + '/account/profile', showMsg: true, showLoading: true },
  getFavoritesFood: { url: AppData.baseUrl + '/account/favourites', showMsg: false, showLoading: true },
  getOrderHistoryList: { url: AppData.baseUrl + '/order/orderHistory', showMsg: false, showLoading: false },
  getOrderDetails: { url: AppData.baseUrl + '/order/orderDetails', showMsg: false, showLoading: true },
  getOrderStatus: { url: AppData.baseUrl + '/order/orderStatus', showMsg: false, showLoading: false },
  getWalletPoints: { url: AppData.baseUrl + '/account/wallet/account/wallet', showMsg: false, showLoading: false },
  getTransactionHistory: { url: AppData.baseUrl + '/account/wallet/account/ledger', showMsg: false, showLoading: false },
  logout: { url: AppData.baseUrl + '/account/logout', showMsg: false, showLoading: true },
  verifyUPI: { url: AppData.baseUrl + '/account/validate-vpa', showMsg: false, showLoading: true },
  withdrawMoney: { url: AppData.baseUrl + '/account/wallet/account/withdraw/initiate', showMsg: false, showLoading: true },
  getScratchInfo: { url: AppData.baseUrl + '/account/scratchCard/scratchInfo', showMsg: false, showLoading: true },
  getEarnedScratchCards: { url: AppData.baseUrl + '/account/scratchCard/viewCards', showMsg: false, showLoading: false },
  redeemScratchCard: { url: AppData.baseUrl + '/account/scratchCard/redeem', showMsg: false, showLoading: false },
  getScratchSummary: { url: AppData.baseUrl + '/account/scratchCard/scratchSummary', showMsg: false, showLoading: true },


  outletsNearMe: { url: AppData.baseUrl + '/rest/outletsNearMe', showMsg: false, showLoading: true },
  getCart: { url: AppData.baseUrl + '/cart', showMsg: false, showLoading: true },
  addItem: { url: AppData.baseUrl + '/cart', showMsg: false, showLoading: false },
  updateAddonVariant: { url: AppData.baseUrl + '/cart/item', showMsg: false, showLoading: true },
  changeItemCount: { url: AppData.baseUrl + '/cart/item/', showMsg: false, showLoading: false },
  addFavourite: { url: AppData.baseUrl + '/account/favourites', showMsg: false, showLoading: false },
  getSelectedFoodPreferences: { url: AppData.baseUrl + '/account/likedPrefTags', showMsg: false, showLoading: true },
  rateOrder: { url: AppData.baseUrl + '/order/rateOrder', showMsg: false, showLoading: true },

  // help
  help: { url: AppData.baseUrl + '/app/help', showMsg: false, showLoading: true },
  helpHeadingId: { url: AppData.baseUrl + '/app/help', showMsg: false, showLoading: true },

  // pre order checkout
  preOrderCheckout: { url: AppData.baseUrl + '/order/preCheckout', showMsg: false, showLoading: ApplicationStateService.isMobileResolution ? true : false },
  placeOrder: { url: AppData.baseUrl + '/order/newOrder', showMsg: false, showLoading: true },
  postCheckout: { url: AppData.baseUrl + '/order/postCheckout', showMsg: false, showLoading: true },
  getAllCoupon: { url: AppData.baseUrl + '/coupon/couponList', showMsg: false, showLoading: true },
  applyCoupon: { url: AppData.baseUrl + '/cart/coupon', showMsg: false, showLoading: true },
  removeCoupon: { url: AppData.baseUrl + '/cart/coupon', showMsg: false, showLoading: true },
  clearPlate: { url: AppData.baseUrl + '/cart/clear', showMsg: false, showLoading: true },
  useWallet: { url: AppData.baseUrl + '/cart/use-wallet', showMsg: false, showLoading: true },
  unUseWallet: { url: AppData.baseUrl + '/cart/unuse-wallet', showMsg: false, showLoading: true },
  noSelfPickup: { url: AppData.baseUrl + '/cart/noSelfPickup', showMsg: false, showLoading: true },
  selfPickup: { url: AppData.baseUrl + '/cart/selfPickup', showMsg: false, showLoading: true },

  //Home
  allHomeFood: { url: AppData.baseUrl + '/home/food', showMsg: false, showLoading: false },
  promotionRestaurants: { url: AppData.baseUrl + '/promotion/restaurants', showMsg: false, showLoading: false },
  promotionDishs: { url: AppData.baseUrl + '/promotion/dishes', showMsg: false, showLoading: false },
  appStart: { url: AppData.baseUrl + '/app/appStart', showMsg: false, showLoading: false },
  disableRatingFromBtmBar: { url: AppData.baseUrl + '/order/rateOrder', showMsg: false, showLoading: false },

  //Menu
  getMenu: { url: AppData.baseUrl + '/food/menu', showMsg: false, showLoading: true },
  searchFoodItems: { url: AppData.baseUrl + '/food/search', showMsg: false, showLoading: true },
  getExpandSearchResults: { url: AppData.baseUrl + '/food/expandSearch', showMsg: false, showLoading: true },

  // outletGroup
  outletGroup:{ url: AppData.baseUrl + '/food/outletGroup', showMsg: false, showLoading: true },

};

export class RequestUrl {
  static get(key: URL_KEYS): any {
    return UrlMapping[key];
  }
}
