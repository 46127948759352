import { Directive, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[appDragAndScroll]'
})

export class DragAndScrollDirective implements OnInit {
  sliderValue;
  @Input()
  set appDragAndScroll(value) {
    this.sliderValue = value;
    console.log(value)
  }

  constructor() {
  }

  ngOnInit() {
    const slider = this.sliderValue;
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e['pageX'] - slider['offsetLeft'];
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e['pageX'] - slider['offsetLeft'];
      const walk = (x - startX) * 3;
      slider.scrollLeft = scrollLeft - walk;
    });
  }
}